.floating-player{
    position: fixed;
    bottom: -1px;
    right: -1px;
    width: 40%;
    padding: 20px;
    background-color: white;
    border: 1px solid #676c73;
    border-top-left-radius: 17px;
}

#meter_installation_event {
    margin-bottom: 14px;
    background: #000000;
    color: #fff;
    padding: 8px 15px;
    border-radius: 10px;
    font-size: 16px;
    text-align: center;
}

.order-table-row {
	margin:0px;
	// border-bottom: solid 2px black;
	.table-buttons {
		text-align: right;
	}
}

#first_direct_debit_payments-table {
	ul {
		padding:0px;
		list-style: none;
	}
}

div.energy-upsell-product-group-products-content {
	.product-table-item-row {
		margin: 5px 0;
	}
}

div.order-information-summary {
	.row {
		margin:0;
	}
	border: solid 1px;
	border-radius: 15px;
    margin: 0 25px 10px 25px;
	padding: 10px 0px;
	.basket-product-table-title {
		font-weight: bold;
		text-decoration: underline;
	}
}
div.product-type-documents-section {
	.document-list-text {
		padding-top:20px;
	}
	span {
		text-decoration: underline;
		color:#478ad0;
		font-size: 10px;
		width: 60%;
		text-align: center;
		margin: auto;
		white-space: pre-wrap; /* CSS3 */    
		white-space: -moz-pre-wrap; /* Mozilla, since 1999 */
		white-space: -pre-wrap; /* Opera 4-6 */    
		white-space: -o-pre-wrap; /* Opera 7 */    
		word-wrap: break-word; /* Internet Explorer 5.5+ */
	}
	.file-icon-container {
		width:80%;
		padding:10px;
		margin:auto;
		text-align:center;
		.large-icons {
			font-size:3em;
		}
	}
	.file-edit-container {
		font-size: 1em;
	}
}
div.order-documents-section {
	border: solid 1px;
	border-radius: 15px;
    margin: 0 25px 10px 25px;
	padding: 10px 0px;
	.row {
		margin:0px;
	}
	.document-list-text {
		padding-top:20px;
	}
	span {
		text-decoration: underline;
		color:#478ad0;
		font-size: 10px;
		width: 60%;
		text-align: center;
		margin: auto;
		white-space: pre-wrap; /* CSS3 */    
		white-space: -moz-pre-wrap; /* Mozilla, since 1999 */
		white-space: -pre-wrap; /* Opera 4-6 */    
		white-space: -o-pre-wrap; /* Opera 7 */    
		word-wrap: break-word; /* Internet Explorer 5.5+ */
	}
	.file-icon-container {
		width:80%;
		padding:10px;
		margin:auto;
		text-align:center;
		.large-icons {
			font-size:3em;
		}
	}
	.file-edit-container {
		font-size: 1em;
	}
}

#salesOrderDocumentEditModal {
	.modal-content {
		padding:20px;
		h4 {
			margin-bottom: 5px;
		}
		.row {
			margin:0;
		}
		.wide-input {
			width:90%;
		}

	}
}
	

#salesOrderDocumentUploadModal {
	#sales-order-documents-dropzone {
		padding:20px;
		margin:20px;
		border: solid 1px black;
		border-radius: 15px;
		min-height: 250px;
		text-align: center;
		overflow: hidden;
		.instruction-to-drop {
			width: 50%;
			margin: auto;
			text-align: center;
			padding-top: 10%;
			font-size: 1.5em;
			color: grey;
		}
		.file-icon-container {
			width:80%;
			padding:10px;
			margin:auto;
			text-align:center;
			.large-icons {
				font-size:5em;
			}
		}
		.dz-preview {
			width:30%;
			float:left;
			.dz-filename {
			    word-wrap: break-word;
				width: 60%;
				text-align: center;
				margin: auto;
			}
		}
	}
}

.manage-dd-modal{


	.payment-value {
		display: inline-block;
		font-weight: bold;
	}

	.script-field{
		width:65%;
	}

	.toggle-stored-dd-section-btn{
		position: absolute;
		top: -5px;
		right: 20px;
	}

	.submit-manage-dd-button-holder{
		text-align: center;
		padding: 20px;

		.submit-manage-dd-button{
			width: 80%;
			font-size: 16px;
			background-color: #4778b9;
			padding: 10px;

			span.button-action-text{
				line-height: 2em;
				font-size: 20px;
			}

			span.fa-stack{
				display: none;
			}

			&:hover{
				background-color: #3A485C;
				color: white;
			}

			i{
				transition: opacity .5s, transform .5s;
				color: white;
				opacity: 0;
	
				&.confirmation-icon{
					transform: rotate(-180deg);
					opacity: 0;
				}
				&.error-icon{
					transform: rotate(-180deg);
					opacity: 0;
					color: darkred;
				}
			}
	

			&[data-state="loading"]{
				span.button-action-text{
					display:none;
				}

				span.fa-stack{
					display: inline-block;
				}

				i.loading-icon{
					opacity: 1;
				}
			}
			&[data-state="confirmed"]{
				span.button-action-text{
					display:none;
				}
				span.fa-stack{
					display: inline-block;
				}
				i{
					transform: rotate(180deg);
					opacity: 0;
	
					&.confirmation-icon{
						transform: rotate(0deg);
						opacity: 1;
					}
				}
			}
			&[data-state="errored"]{
				span.button-action-text{
					display:none;
				}
				span.fa-stack{
					display: inline-block;
				}
				i{
					transform: rotate(180deg);
					opacity: 0;
	
					&.error-icon{
						transform: rotate(0deg);
						opacity: 1;
					}
				}
			}

		}
	}

	.bank-details-show-new{
		.error-notice{
			position: absolute;
			top: 30px;
			left: 50%;
			transform: translateX(-50%);
			width: 90%;
			text-align: center;
		}

		.row{
			position: relative;
		}
	}

}

.confirmation-preview-modal{
	.modal-dialog{
		width:80%;

		.email-preview{
			max-height: 750px;
			overflow: scroll;
			border: 5px solid #dcdcdc;
			overflow-x: hidden;
			padding: 10px;
			border-right-width: 2px;
		}
	}
}

.order-flags-section{
	margin:10px;

	.badge.badge-pill{
		padding: 7px 10px;
		border-radius: 20px;
		font-weight: normal;
		font-size: 14px;
		margin: 10px 20px;
		background-color: #4774b7;
	}

}

.payment-details-tabs {
	padding:0px 20px;
	margin-top:20px;
	.payment-details-tab {
		padding: 10px;
		background-color: #3A485C;
		color: white;
		border-top-left-radius: 15px;
		border-top-right-radius: 15px;
	}
	.payment-details-tab.active {
		background-color: green;
		color:white;
	}
}

.payment-details-content{
	padding:0px 20px;
}
