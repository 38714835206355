.remote-working-container {
  /*background-image: url("/images/UD/login-bg.jpg");*/
  font-family: 'Roboto', sans-serif;
  background: #ebebeb;
  min-height: 100vh;
  height:auto;
}

.simple-title {
  font-size:30px;
  font-weight:bold;
}

div.remote-working-container {
  div.row.guide-row {
    margin: 0px;
  }
}

.guide-option {
  h3 {
    background-color: #c4f5e8;
    border: 1px solid #dafffc;
    font-size:20px;
    color:black;
    border-radius:3px;
    padding:5px;
    cursor:pointer;
  }
}

.overview-page-header {
  background-color: #212121;
  height: auto;
  line-height: 55px;
  color: white;
}

.body-mask {
  background-color:#e4e4e4;
}

.simple-header {
  font-size:24px;
}

.faq-list {
  li {
    border-bottom:1px solid #a8a8a8;
  }
}

div.how-to-guide-container {
  div.card {
    background-color: #f5f5f5;
    border:1px solid #cbcbcb;
    border-radius:4px;
    -webkit-box-shadow: 0px 0px 3px 0px rgba(0,0,0,0.2);
    -moz-box-shadow: 0px 0px 3px 0px rgba(0,0,0,0.2);
    box-shadow: 0px 0px 3px 0px rgba(0,0,0,0.2);

    .card-title-text {
      padding:10px;
      font-weight:bold;
    }

    .card-body {
      margin-top:10px;
    }

    .highlight-word {
      font-weight:bold;
      color: #0077c1;
    }

    .card-title {
      margin-bottom:0px;
      margin-top: 0px;
      background-color:#e6e6e6;
      border-bottom:1px solid #cbcbcb;
    }

    .card-text {
      padding:10px;
    }
  }

  div.panel {
    background-color:unset;
  }
}