#suppliers, #tariffs, #user-container {
	@include overflow($height : 600px, $y : auto, $x : hidden)
}

#suppliers {
	.suppliers-wrapper {
		padding: 0px;
		min-height: 80px;
		width: 100%;
		margin: 0px;
	}
	.supplier-container {
		.trigger-icon-small {
			@include trigger-icon;
		}	
		background: $white;
		list-style-type: none;
		width: 100%;
		height: 40px;
		border-bottom: 1px solid $light-grey;

		&.superseded {
			background-color: #8b000040;
			color: darkred;
		}
	}
	.trigger-icon {
		&:hover {
			cursor: auto;
		}
		@include trigger-icon-main($hoverbackground : $navbar-bg, $background : $navbar-bg);
		
	}
	.col-md-10 {
		margin: 0px;
	}
	position: relative;
	cursor: pointer;
	border-bottom: 10px solid $light-grey;
    z-index: 0;
    background: #f5f5f5;
}

#suppliers-wrapper {
	.action-group-heading {
		@include module-headings($background: $navbar-bg, $padding: 5px, $radius: 8px, $color: #fff, $margin-bottom: null);
	}
}

.supplier-container {
	.group-information {
		.group-name {
			height: 100%;
			@include padding(1.9% 0.7% 0.7% 2.3%);
		}
		width: 80%;
		float: left;
		height: 100%;
		border-left: 1px solid #ddd;
	}
	
}

#tariffs {
	.tariffs-wrapper {
		padding: 0px;
		min-height: 80px;
		width: 100%;
		margin: 0px;
	}
	.tariff-container {	
		background: $white;
		list-style-type: none;
		width: 100%;
		padding: 0px;
		height: 40px;
		border-bottom: 1px solid #3a485c;
		display: grid;
		grid-template-columns: 50px 1fr 140px;
		
	}
	.group-information {
		width: 100%;
		height: 100%;
		border-right: 1px solid #ddd;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;

		.group-name {
			height: 50%;
			font-size: 12px;
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
			@include padding(0.7% 0.7% 0.7% 2%);
		}
		.group-stats {
			height: 50%;
			display: block;
			font-size: 11px;
			@include padding(0.4% 0.7% 0.7% 2%);
			border-top: 1px solid #ddd;
			background: #e3eaf45c;
		}
	}
	.trigger-icon {
		&:hover {
			cursor: auto;
		}
		@include trigger-icon-main($hoverbackground : $navbar-bg, $background : $navbar-bg);
		
	}
	.col-md-10 {
		margin: 0px;
	}
	position: relative;
	cursor: pointer;
	border-bottom: 10px solid $light-grey;
    z-index: 0;
    background: #f5f5f5;
}

.supplier-container {
	.group-information {
		.group-name {
			height: 100%;
			@include padding(1.9% 0.7% 0.7% 2.3%);
		}
		width: 83%;
		float: left;
		height: 100%;
		border-left: 1px solid #ddd;
	}
	
}

.tariff-icon-small {
	@include trigger-icon($background: $navbar-bg);	
}

.supplier-logo {
	img {
		width: 90%;
	}
	padding: 7px;
    height: 100%;
	width: 15%;
    float: left;
}

#tariffs ul li, #suppliers ul li {
	&:hover {
		background: $light-blue;
	}
	@include transition(background-color 0.5s ease);
}

.tariff-count {
	p {
		font-size: 11px;
		text-align: right;
		margin-bottom: 0px;
		margin-top: 5px;
	}
	width: 30%;
	float: right;
}

div.tariff-expired-toggle{
	
    margin-right: 5px;
    float: right;
    padding: 10px;

	
	span.tariff-expired-label{
		margin-right: 5px;
	}
	
	label.label-success{
		margin: 4px;
	}
	
	
}

.payment-method-container {
	border-bottom: 1px solid #dddddd;
}

.default-tariffs-payment-methods-wrapper{
	list-style:none;
	padding-left:0px;
}

.tariffs-wrapper{
	list-style: none;
	padding-left: 0px;

	.tariff-container{
		padding:0;
		position: relative;
		border-bottom: 1px solid #e2e2e2;
		&:first-of-type{
			border-top: 1px solid #e2e2e2;
		}

		.tariff-icon-small{
			min-height: 40px;
			float: none;
			display: inline-flex;
			background-color: #e2e2e2;
			img{
				width:90%;
			}
		}
		.group-information{
			display: inline-block;
			height: 100%;
			width: 69%;
			.group-name{
				display: inline;
			}
		}

		.delete-trigger, .add-trigger {
			width:10%;
			text-align: center;
			float: right;
			padding: $standard-padding;
			display: inline-block;
		}
	}

}
.select2-result-repository {
	padding-top: 4px; padding-bottom: 3px;
}
.select2-result-repository__avatar {
	width: 60px; margin-right: 10px; float: left;
}
.select2-result-repository__avatar img {
	border-radius: 2px; width: 100%; height: auto;
}
.select2-result-repository__meta {
	margin-left: 70px;
}
.select2-result-repository__title {
	color: black; line-height: 1.1; font-weight: bold; margin-bottom: 4px; -ms-word-wrap: break-word;
}
.select2-result-repository__forks {
	margin-right: 1em;
}
.select2-result-repository__stargazers {
	margin-right: 1em;
}
.select2-result-repository__label {
	color: rgb(170, 170, 170); font-size: 11px; display: inline-block; margin-right:10px;
}
.select2-result-repository__description {
	color: rgb(119, 119, 119); font-size: 13px; margin-top: 4px;
}

.select2-container--default .select2-results__option--highlighted[aria-selected]{
	.select2-result-repository__title{
		color: white;
	}
	.select2-result-repository__label{
		color:white
	}
	.select2-result-repository__description{
		color:white
	}
}

.select2-result-repository.expired{
	background-color: #f1f1f1;
	margin: -5px -10px;
	padding: 4px 10px;
	.select2-result-repository__title{
		color: #b3b3b3;
	}
	.select2-result-repository__label{
		color:#b3b3b3
	}
	.select2-result-repository__description{
		color:#b3b3b3
	}
}

.dark-blue-header{
	background-color: #3a485c;
}

.supersede-supplier-section{
	padding: 5px;
	.superseding-explaination{
		padding: 11px;
		color: #3a485c; 
	}
}
