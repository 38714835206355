#action-group-active {
	.group-information {
		.group-name {
			height: 100%;
			font-size: 14px;
			@include padding(2.1% 0);
		}
/*		.group-stats {
			height: 50%;
			font-size: 11px;
			@include padding(0.3% 0.7% 0.7% 11%);
			border-top: 1px solid #ddd;
			background: $light-blue;
		}*/
		width: 83%;
		height: 100%;
		border-right: 1px solid #ddd;
	}
	.group-stats-nav, .group-active {
		i {
			&:hover {
				color: $main-brand-colour;
			}
			color: $action-group-green;
		}
		cursor: pointer;
		float: right;
		padding: $standard-padding;
		display: inline-block;
	}
}

.action-group-ul {
	.edit-rule {
		position: absolute;
		top: 0;
		right: 98px;
	}
	.group-stats-nav {
		position: absolute;
		top: 0;
		right: 59px;
		border-right: 1px solid #ddd;
	} 
	.group-active {
		position: absolute;
		top: 0;
		margin-top: 5px;
		right: -4px;
	}
}

.material-switch > input[type="checkbox"] {
    display: none;   
}

.material-switch > label {
    cursor: pointer;
    height: 0px;
    position: relative; 
    width: 40px;  
}

.material-switch > label::before {
    background: black;
    box-shadow: inset 0px 0px 10px rgba(0, 0, 0, 0.5);
    border-radius: 8px;
    content: '';
    height: 13px;
    margin-top: -6px;
    position: absolute;
    opacity: 0.3;
    transition: all 0.4s ease-in-out;
    width: 37px;
}
.material-switch > label::after {
    background: white;
    border-radius: 16px;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.3);
    content: '';
    height: 20px;
    left: -4px;
    margin-top: -6px;
    position: absolute;
    top: -4px;
    transition: all 0.3s ease-in-out;
    width: 20px;
}
.material-switch > input[type="checkbox"]:checked + label::before {
    background: inherit;
    opacity: 0.5;
}
.material-switch > input[type="checkbox"]:checked + label::after {
    background: inherit;
    left: 20px;
}

.group-statistics {
	border-bottom: 1px solid #ddd;
}

.action-stats-table {
	thead {
		tr {
			th {
				&:last-of-type {
					border-top-right-radius: 0px;
				}
				&:first-of-type {
					border-top-left-radius: 0px;
					border-left: none;
				}
				padding: 5px;
				border-left: 1px solid #fff;
			}
		}
		background: #a8ca5c70;
		color: #333;
	}
	tbody {
		tr {
			td {
				&:last-of-type {
					border-top-right-radius: 0px;
				}
				&:first-of-type {
					border-top-left-radius: 0px;
					border-left: none;
				}
				padding: 5px;
				border-left: 1px solid #ddd;
				padding: 5px;
			}
			border-bottom: 1px solid #ddd;
			font-size: 11px;
		}
	}
	table-layout: inherit;
	margin: 0px;
}

.action-trigger-container-row {
	a {
		cursor: pointer;
	}
	background-color: #a8ca5c70;
}

.view-triggers {
	&:hover {
		color: $action-group-green;
	}
	padding: 5px;
	color: $main-brand-colour;
	font-size: 17px;
    text-align: center;
}

.view-active-triggers {
	color: $action-group-green!important;
}

#action-group-details {
	.sales-heading {
		margin-bottom: 0px;
	}
}

.rule-stats-table {
	thead {
		tr {
			th {
				&:last-of-type {
					border-top-right-radius: 0px;
				}
				&:first-of-type {
					border-top-left-radius: 0px;
					border-left: none;
				}
				padding: 5px;
				border-left: 1px solid #fff;
			}
		}
		background: #a87c5d670;
		color: #333;
	}
	tbody {
		tr {
			td {
				&:last-of-type {
					border-top-right-radius: 0px;
				}
				&:first-of-type {
					border-top-left-radius: 0px;
					border-left: none;
				}
				padding: 5px;
				border-left: 1px solid #ddd;
				padding: 5px;
			}
			border-bottom: 1px solid #ddd;
			font-size: 11px;
			height: 44px;
		}
	}
	table-layout: inherit;
	margin: 0px;
}

.started-at {
	background: #dae8ba70;
}

.finished-at {
	background: #f80b0b1c;
}