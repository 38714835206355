@keyframes ripple {
	from {
	    opacity: 1;
	    width:100px;
   		height:100px;
	}
	
	to {
	  	opacity: 0;
	  	width:150px;
   		height:150px;
	}
}

@keyframes ringing-fade {
	0%   { opacity:1; }
	50%  { opacity:0.2; }
	100% { opacity:1; }
}


@keyframes ringing-animation  {
	0%, 100% {
		transform: rotate(0deg);
	}
	20%, 60% {
		transform: rotate(10deg);
	}
	40%, 80% {
		transform: rotate(-10deg);
	}
}


.softphone{


	.slide-side-bar{
		height: 100%; /* 100% Full-height */
		width: 0; /* 0 width - change this with JavaScript */
		position: fixed; /* Stay in place */
		z-index: 1; /* Stay on top */
		top: 0; /* Stay at the top */
		left: 0;
		background-color: #111; /* Black*/
		overflow-x: hidden; /* Disable horizontal scroll */
		padding-top: 60px; /* Place content 60px from the top */
		transition: 0.5s; /* 0.5 second transition effect to slide in the sidenav */
		-ms-overflow-style: none;  /* IE and Edge */
		scrollbar-width: none;  /* Firefox */
		&::-webkit-scrollbar {
			display: none;
		}

		a{
			padding: 8px 8px 8px 32px;
			text-decoration: none;
			font-size: 25px;
			color: #818181;
			display: block;
			transition: 0.3s;

			&:hover{

				color: #f1f1f1;

			}
		}

		.side-menu{
			width: 100%;
			position: absolute;
			left: 0%;
			transition-duration: 0.5s;

			&.sub-open{
				transform: translateX(-100%);
			}
		}

		.close-button{
			position: absolute;
			top: 0;
			right: 25px;
			font-size: 36px;
			margin-left: 50px;
		}

		.back-button{
			position: absolute;
			top: 0;
			left: 0px;
			padding-left: 15px;
			padding-right: 10px;
			font-size: 36px;
		}

		.side-bar-sub-level{

			width: 100%;
			position: absolute;
			left: 100%;
			transition-duration: 0.5s;

			&.open{
				transform: translateX(-100%);
			}

			.sub-level-heading{
				margin-top: 0px;
				font-size: 25px;
				color:white;
			}
		}
		
	}

	.contacts{
		padding: 10px;

		.contact-row{
			display: inline-block;
			width: 100%;
			font-size: 16px;
			line-height: 50px;
			border-radius: 5px;
			background-color: darkgray;
			margin-bottom: 5px;
			padding: 5px;
			position: relative;
			cursor: pointer;

			.contact-image{
				float: left;
				height: 50px;
				width: 50px;
				border-radius: 50%;
				margin-right: 10px;
				// border: 2px solid #353535;
				box-shadow: 1px 2px 3px 2px rgba(0,0,0,0.3);
			}

			.contact-status{
				width: 20px;
				height: 20px;
				border-radius: 50%;
				position: absolute;
				top: 37px;
				left: 37px;
				z-index: 2;

				.on-a-call-icon{
					display: none;
					color: white;
					font-size: 12px;
					position: absolute;
					top: 50%;
					left: 50%;
					transform: translate(-50%, -50%);
				}
			}
		

			.contact-caret{
				position: absolute;
				top: 5px;
				right: 10px;
				z-index: 2;
				font-size: 36px;
				color: white;
				transition-duration: 0.2s;
				&.opened{
					transform: rotate(88deg);
				}
			}

			.contact-actions{

				.contact-action{
					height: 50px;
					margin-top: 10px;
					width: 50px;
					border-radius: 50%;
					margin-right: 10px;
					text-align: center;
					color: white;
					font-size: 26px;
					box-shadow: 1px 2px 3px 2px rgba(0,0,0,0.3);
					display: inline-block;
					&.call{
						background-color:  rgb(57, 181, 54);;
					}

					&.listen{
						background-color: lightseagreen;
						display: none;
					}
					
					&.barge{
						background-color: indianred;
						display: none;
					}
					
					&.favourite{
						background-color: goldenrod;
					}
					&.end-call{
						display: none;
						background-color: darkred;
					}
					&.connect-transfer{
						display: none;
						background-color: teal;
					}
				}

			}

			&.offline{
				.contact-image{
					opacity: 0.5;
				}
				.contact-status{
					background-color: firebrick;
					box-shadow: 0px 0px 6px 0px darkred;
				}
				.contact-caret{
					color:grey;
				}
			}

			&.available{
				.contact-status{
					background-color: limegreen;
					box-shadow: 0px 0px 6px 0px forestgreen;
				}
			}

			&.on-call{
				.contact-status{
					background-color: orange;
					box-shadow: 0px 0px 6px 0px darkorange;
					.on-a-call-icon{
						display: block;
					}
				}
				.contact-actions{

					.contact-action{

						&.listen{
							display:inline-block;
						}
						&.barge{
							display:inline-block;
						}
					}
				}
			}
		}

		.contact-search-section{
			position: relative;
			font-size: 18px;
			margin-bottom: 10px;

			label{
				position: absolute;
				left: 17px;
				top: 10px;
			}
			input{
				padding-left: 43px;
				padding-right: 43px;
				border-radius: 5px;
				font-size: 18px;
				height:46px;
			}

			a.clear-input{
				text-decoration: none;
				position: absolute;
				right: 18px;
				top: 6px;
				color: lightgrey;
				padding: 0;
				display:none;
			}
		}

	}

	.settings{
		padding:10px;

		.settings-container{
			.settings-section-header{
				color:white;
				font-size: 20px;
				margin-bottom: 5px;
			}

			.info-section{
				margin-bottom: 20px;;
			}

			
			.settings-profile-image{
				text-align: center;
				position: relative;
				.contact-image{
					border-radius:50%;
					max-height:100px;
				}
				.edit-profile-image{
					position: absolute;
					width: 25px;
					height: 25px;
					background-color: grey;
					border-radius: 50%;
					bottom: 0px;
					right: 33%;
					color: white;
					transition-duration: 0.2s;
					cursor: pointer;

					&:hover{
						background-color: darkgrey;
					}

					i{
						position: absolute;
						left: 53%;
						top: 50%;
						transform: translate(-50%, -50%);
						&.fa-spin{
							margin-top: 2px;
							position: unset;
							vertical-align: middle;
						}
					}
				}
			}


			.settings-profile-name{
				text-align: center;
				padding: 10px;
				color: #d3d3d3;
				font-size: 16px;
			}

			.settings-profile-info-value{
				text-align: center;
				color: lightgrey;
				font-size: 14px;
				white-space: nowrap;
				overflow: hidden;
				text-overflow: ellipsis;
				max-width: 80%;
				margin: auto;
			}

			label.settings-page-label{
				color: darkgrey;
				font-size: 18px;
				width: 100%;
				padding-right: 25px;
				margin-top:5px;

				i{
					float: right;
					padding: 1px;
				}
			}

			.refresh-contacts-button{
				background-color: grey;
				padding: 10px 15px;
				margin: auto;
				color: white;
				transition-duration: 0.2s;
				font-size: 14px;

				&:hover{
					background-color: darkgrey;
				}

				i.success-tick::before{
					content: "";
					color:greenyellow;
				}
				i.failed-cross::before{
					content: "";
					color:darkred;
				}
			}
		}
	}

	.topnav{
		padding: 20px;
		position: fixed;
		width: 100%;

		.animated-nav-icon{
			width: 30px;
			height: 20px;
			position: relative;
			margin: 0px;
			-webkit-transform: rotate(0deg);
			-moz-transform: rotate(0deg);
			-o-transform: rotate(0deg);
			transform: rotate(0deg);
			-webkit-transition: .5s ease-in-out;
			-moz-transition: .5s ease-in-out;
			-o-transition: .5s ease-in-out;
			transition: .5s ease-in-out;
			cursor: pointer;


			span{
				display: block;
				position: absolute;
				height: 3px;
				width: 100%;
				border-radius: 9px;
				opacity: 1;
				left: 0;
				-webkit-transform: rotate(0deg);
				-moz-transform: rotate(0deg);
				-o-transform: rotate(0deg);
				transform: rotate(0deg);
				-webkit-transition: .25s ease-in-out;
				-moz-transition: .25s ease-in-out;
				-o-transition: .25s ease-in-out;
				transition: .25s ease-in-out;
				background-color: darkgrey;

				&:nth-child(1){
					top: 0px;
				}
				&:nth-child(2), &:nth-child(3){
					top: 10px;
				}
				&:nth-child(4){
					top: 20px;
				}
			}

			&.open{
				span{
					&:nth-child(1) {
						top: 11px;
						width: 0%;
						left: 50%;
					}
					&:nth-child(2) {
						-webkit-transform: rotate(45deg);
						-moz-transform: rotate(45deg);
						-o-transform: rotate(45deg);
						transform: rotate(45deg);
					}
					&:nth-child(3) {
						-webkit-transform: rotate(-45deg);
						-moz-transform: rotate(-45deg);
						-o-transform: rotate(-45deg);
						transform: rotate(-45deg);
					}
					&:nth-child(4) {
						top: 11px;
						width: 0%;
						left: 50%;
					}
				}
			}
		}

		.active-toggle{
			padding: 5px 10px;
		}

		div.call-status-indicator{
			text-align: center;
			font-size: 24px;
			display: inline-block;
			margin: auto;
			position: absolute;
			left: 50%;
			transform: translateX(-50%);
			color: darkgrey;
			display: none;

			&.ringing{
				animation: ringing-fade 1.5s infinite;
				display: block;
			}
		}
	}

	.softphone-input{
		width: 100%;
		margin-top: 50px;
		margin-bottom: 20px;
		font-size: 48px;
		text-align: center;
		border: none;
		outline: none;
		padding: 0px 40px;

		&.connected{
			color: #5cb85c;
		}
	}

	.softphone-keypad{
		display: grid;
		grid-template-columns: 1fr 1fr 1fr;
		grid-template-rows: 1fr 1fr 1fr 1fr 1fr;
		gap: 10px 1px;
		grid-template-areas: ". . ." ". . ." ". . ." ". . ." ". softphone_call_item softphone_options_item";
		max-width: 400px;
		margin:auto;

		.keypad-item{
		    border-radius: 50%;
			position: relative;
			border-radius: 50%;
			width: 100px;
			height: 100px;
			background: #ffffff;
			border: 3px solid darkgrey;
			text-align: center;
			margin: auto;
			font-size: 48px;
			color: darkgrey;
			cursor: pointer;
			transition: 0.3s;
			-webkit-user-select: none; /* Chrome/Safari */        
			-moz-user-select: none; /* Firefox */
			-ms-user-select: none; /* IE10+ */

			/* Rules below not implemented in browsers yet */
			-o-user-select: none;
			user-select: none;
			&:hover{
				background-color: darkgrey;
				color: white;
			}
			span{
				position: absolute;
				top: 50%;
				left: 50%;
				transform: translate(-50%, -50%);
			}

			&.call{
				grid-area: softphone_call_item;
				background-color: #5cb85c;
				border-color: #5cb85c;
				color: white;
				&:hover{
					box-shadow: 0px 0px 10px green;
				}

				&.end_call{
					transform: rotate(137deg);
					background-color: red;
					border-color: red;
					&:hover{
						box-shadow: 0px 0px 10px red;
					}
				}
				&:after {
					display:none;
				}
			}

			&.options{
				grid-area: softphone_options_item;
				position: relative;

				.call-options-menu{
					display: none;
					position: absolute;
					bottom: 50px;
					right:-10px;
					background-color: #f1f1f1;
					min-width: 160px;
					box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
					z-index: 1;
					border-radius: 20px;
    				overflow: hidden;

					div{
						color: darkgrey;
						padding: 12px 16px;
						text-decoration: none;
						display: block;
						font-size:24px;

						&:hover{
							background-color: darkgrey;
							color:white;
						}
					}
				}

				&:hover{
					.call-options-menu{
						display:block;
					}
				}
			}

			&:after {
				content: "";
				background: lightgrey;
				display: block;
				position: absolute;
				top: 50%;
				left: 50%;
				transform: translate(-50%, -50%);
				opacity: 0;
				border-radius:50%;
				width:100px;
				height:100px;
				transition: all 0.5s
			}
			  
			&:active:after {
				background: lightgrey ;
				width:0px;
				height:0px;
				opacity: 1;
				transition: 0s;
				z-index : 1 ;

			}

		}
		.simulate-click{
			&:after {
				z-index: -1;
				background: darkgrey;
				animation-name: ripple;
				animation-duration: 0.5s;
				animation-delay: 0s;
				animation-iteration-count: 1;
				// animation-timing-function: cubic-bezier(.65,0,.34,1);
			}
		}
	}


	div.call-notification-holder{
		position: absolute;
		width: 80%;
		padding: 5px;
		left: 10%;
		background-color: #272727;
		border-radius: 29px;
		height: 200px;
		box-shadow: 0 0 1.5rem #a0a0a0;
		transition-duration: 0.5s;
		top: -300px;

		&.active{
			top:10px;
		}

		div.calling-number{
			padding: 20px;
			width: 70%;
			font-size: 28px;
			margin-top: 10px;
			color: white;
			padding-top: 5px;
			padding-bottom: 5px;
			display: inline-block;
			top: 0px;

			span.sub-title{
				display: block;
				font-size: 18px;
			}
		}

		div.calling-icon{
			width: 19%;
			display: inline-block;
			position: relative;
			border-radius: 50%;
			width: 75px;
			margin-top: 20px;
			float: left;
			height: 75px;
			background: #ffffff;
			text-align: center;
			margin: auto;
			margin-top: 10px;
			margin-left: 10px;
			font-size: 30px;
			color: #272727;

			i{
				position: absolute;
				top: 50%;
				left: 50%;
				transform: translate(-50%, -50%);
			}
		}

		div.reject-call{
			display: inline-block;
			position: relative;
			border-radius: 50%;
			width: 75px;
			margin-top: 20px;
			float: left;
			height: 75px;
			background: red;
			text-align: center;
			margin: auto;
			color: white;
			transform: rotate(137deg);
			margin-top: 10px;
			margin-left: 30px;
			font-size: 30px;
			cursor: pointer;
			i{
				position: absolute;
				top: 50%;
				left: 50%;
				transform: translate(-50%, -50%);
			}
		}

		div.accept-call{
			display: inline-block;
			position: relative;
			border-radius: 50%;
			width: 75px;
			margin-top: 20px;
			float: right;
			height: 75px;
			background: #5cb85c;
			text-align: center;
			margin: auto;
			color: white;
			margin-top: 10px;
			font-size: 30px;
			margin-right: 30px;
			cursor: pointer;
			i {
				position: absolute;
				top: 50%;
				left: 50%;
				transform: translate(-50%, -50%);
			}
		}

	}


	.transfer-modal-container{
		position: fixed;
		top: 100vh;
		transition-duration: 0.3s;
		width: 100%;
		background-color: #111;
		height: 100vh;
		overflow-y: auto;
		z-index: 2;
		-ms-overflow-style: none;  /* IE and Edge */
		scrollbar-width: none;  /* Firefox */
		&::-webkit-scrollbar {
			display: none;
		}
		&.open{
			top: 0px;
		}

		.transfer-modal-header-section{
			background-color: darkgrey;
			padding: 20px;
			.header{
				display: inline-block;
				color: white;
				font-size: 24px;
			}
			.close-icon{
				display: inline-block;
				float: right;
				margin: -20px;
				padding: 20px;
				font-size: 24px;
				background-color: #6d6d6d;
				color: white;
				min-width: 70px;
				text-align: center;
				cursor: pointer;
			}
		}

		.transfer-input-container{
			padding: 20px;
			position: relative;
			z-index: 2;

			input.transfer-to-input{
				width: 80%;
				height: 60px;
				border-top-left-radius: 5px;
				border-bottom-left-radius: 5px;
				border: none;
				font-size: 24px;
				padding-left: 15px;
				outline: none;
			}
			.confirm-transfer-button{
				width: 19%;
				margin-left: -6px;
				height: 60px;
				border-top-right-radius: 5px;
				border-bottom-right-radius: 5px;
				border: none;
				background-color: #5cb85c;
				color: white;
				font-size: 24px;
			}
			.clear-input{
				position: absolute;
				right: 25%;
				top: 33px;
				font-size: 24px;
				color: lightgrey;
				display: none;
			}
		}
		
		.external-transfer-calls{
			margin-top: -20px;
			padding-left: 20px;
			padding-right: 20px;

			.external-transfer-call{
				background-color: white;
				padding: 14px;
				width: 98.6%;
				height: 60px;
				border-radius: 5px;
				border-bottom-left-radius: 5px;
				border: none;
				font-size: 24px;
				padding-left: 15px;
				outline: none;
				margin-top: 5px;
				color: #005200;
				transition-duration: 0.5s;
				z-index: -1;
				transform: translateY(-100%);
				.external-number{
					display: inline-block;
				}

				&.ringing{
					.external-number{
						animation: ringing-fade 1.5s infinite;
						content : 'Ringing';
					}
				}

				&.ended{
					display:none;
				}
				
				.call-action{
					margin-top: 10px;
					float: right;
					margin: -10px;
					padding: 8px;
					margin-left: 0px;
					cursor: pointer;
					height: 50px;
					width: 50px;
					border-radius: 50%;
					margin-right: 10px;
					text-align: center;
					color: white;
					font-size: 26px;
					box-shadow: 1px 2px 3px 2px rgba(0, 0, 0, 0.3);
					display: inline-block;
					&.end{
						background-color: darkred;
					}
					&.complete{
						background-color: teal;
					}
				}
			}
		}

		.transfer-contacts-container{
			.contact-row{
				width: 45%;
				margin-left: 2.5%;

				&.ringing{
					.contact-caret{
						animation : ringing-animation 0.5s infinite;
						i{
							transform: rotate(137deg);
							&::before{
								content : "";
	
							}
						}
					}
				}

				&.connected{
					.contact-caret{
						i{
							transform: rotate(0deg);
							&::before{
								content : "";
	
							}
						}
					}
					.contact-actions{
						display: block;
						.contact-action{

							&.call{
								display:none;
							}
							&.favourite{
								display:none;
							}
							&.end-call{
								display:inline-block;
							}
							&.connect-transfer{
								display:inline-block;
							}
							&.listen{
								display: none;
							}
							
							&.barge{
								display: none;
							}

						}
					}
				}
			
				.contact-name{
					white-space: nowrap;
					overflow: hidden;
					text-overflow: ellipsis;
					width: 68%;
				}
			}
		}
	}

}