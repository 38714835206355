#remoteconfigs-container {

    .team-drop {
        cursor: initial;
    }

    .remoteconfigs-create {
        float: right;
        margin-right: 5px;
        margin-top: 3px;
        cursor: pointer;
    }
}