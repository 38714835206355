/* ----------------------------- LOGIN PAGE ------------------------  */

.login-panel {
    width: 40%;
    padding: 2%;
	@include padding(2%);
    height: 360px;
    @extend %push-auto;
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0px 5px 25px #4774b7;
}


#app-layout {
    @include full-screen-image (url("/images/UD/login-bg.jpg"));
    //@include full-screen-image (url("/images/Halloween/login-bg-haunted.jpg"));
    // @include full-screen-image (url("/images/Christmas/login-bg-xmas.jpg"));
	margin-top: 10%;
}

.login-form {
    display: block;
    width: 90%;
    @extend %push-auto;
    height: 34px;
    @include padding(6px 12px);
    font-size: 14px;
    line-height: 1.428571429;
    color: #555555;
    background-color: #fff;
    background-image: none;
    border-radius: 4px;
}

.login-field {
    height: 40px;
    @include padding(1px);
    margin-top: 10px;
    width: 300px;
    padding-left: 5px;
    font-size: 15px;
    border: 1px solid #ccc;
    border-radius: 6px;
}

.login-button {
	width: 100%;
	margin-left: 0px;
	background-color: $main-brand-colour;
	color: $white-text;
}

.button-container {
	width: 91.7%;
	@extend %push-auto;
	margin-top: 80px;
}

.checkbox {
	height: 20px;
	margin-top: 30px; 
	float: left;
	margin-bottom: 20px;
}

.forgot-remember {
	margin-left: 11.5%;
}

.reset-pw {
	float: right;
}

.sign-in-title {
	text-align: center;
	color: $main-brand-colour;
	font-family: helvetica;
}