.release-log-stat-container {
  background-color: #f7f7f7;
  border: 1px solid #e2e2e2;
  border-bottom: 2px solid #4774b7;
  color: black;

  div.row {
    margin-left:0;
    margin-right:0;
  }
}

.release-log-container {
  div.row {
    margin-left:0;
    margin-right:0;
  }
}

.release-type-icon {
  width: 100px;
  display: inline-block;
  text-align: center;
  font-weight: bold;
}

.release-log-data {
  width:80%;
  display: inline-block;
}

.feature-icon {
  background-color: #94ffd6;
}

.bugfix-icon {
  background-color: #ffedd7;
}

.no-margin {
  margin:0px;
}

.release-log-container {
  background-color: #f3f3f3;
  box-shadow: 0px 1px 2px 2px rgba(216, 216, 216, 0.3);
  border: 1px solid #e0e0e0;
  margin-bottom: 5px;
}

.release-log-date {
  float: right;
  margin-right: 8px;
  margin-top: 8px;
  font-weight:bold
}

.release-logs-container {
  height: 89vh;
  overflow-y: scroll;
  border: 1px solid #e2e2e2;
}

.release-logs-container::-webkit-scrollbar {
  width: 0.5em;
}

.release-logs-container::-webkit-scrollbar-thumb {
  background-color: #4774b7;
  outline: 1px solid slategrey;
}

.release-logs-container::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

.search-release-logs {
  border: 0px;
  padding: 10px;
}

.release-log-modal-input {
  width:100%;
  max-width:100%;
  max-height:400px;
  min-height:25px;
}

.health-reports-container {
  border-bottom: 1px solid grey;
}

.health-reports-container.support {
  background-color: #f7fbff;
}

.health-reports-container.qa {
  background-color: #fff7fe;
}

.health-check-stat-container {
  background-color: #f7f7f7;
  border: 1px solid #e2e2e2;
  border-bottom: 2px solid #4774b7;
  color: black;

  div.row {
    margin-left:0;
    margin-right:0;
  }
}

.health-check-binding-container {
  input {
    width: 100%;
  }
  select {
    width: 100%;
  }
}

.qa-directory-container {
  background-color: #f7f7f7;
  border: 1px solid #e2e2e2;

  .qa-directory-item-group {
    min-height: 40px;
    background-color: rgba(207, 207, 207, 0.12);
    margin-left: 4px;
    padding-top: 10px;
    padding-left: 10px;
    //margin-top: 10px;
    border-top: 1px solid #dadada;
    border-left: 1px solid #dadada;
    cursor: pointer;

    .qa-directory-item {
      padding: 10px;
      margin-left: 4px;
      background-color: rgba(216, 216, 216, 0.15);
      border: 1px solid #cfcfcf;
      //border-bottom: 1px solid #b8b8b8;
      border-top: 0;
      cursor: pointer;
    }

    .qa-directory-item:hover {
      background-color: rgba(59, 71, 92, 0.07);
    }
  }

  .qa-directory-item-group:hover {
    background-color: rgba(187, 187, 187, 0.15);
  }

  .collapsed-group {
    display:none;
  }

  .collapsed-item {
    display:none;
  }

  .report-icon {
    float:right;
    padding:5px;
    border-radius:50%;
    line-height:5px;
    margin-right:2px;
    border: 1px solid #d7d7d7;
  }

  .report-icon-success {
    background-color: #bdffbd;
  }

  .report-icon-failed {
    background-color: #ffbcbc;
  }

  .report-icon-incomplete {
    background-color: #ffe3be;
  }
}

.qa-report-table {
  .qa-report-header-row {
    background-color: #1376BC;
    padding: 10px;
    height: 30px;
    color: white;
    font-weight: bold;
    font-size: 15px;
    text-align: center;
  }

  .qa-report-field-name-cell {
    background-color: #E5EBF6;
  }

  .qa-report-field-value-cell {
    border: 1px solid grey;
    padding: 5px;
  }

  border:1px solid grey;
  margin-bottom:10px;

  td {
    border:1px solid #a7a7a7;
  }

  thead {
    td {
      border-right: 1px solid #cbcbcb;
    }

    td:last-of-type {
      border:none;
    }
  }

  input {
    border: none;
    height:30px;
  }
}

.qa-report-container {
  border:1px solid #c2c2c2;
  padding:10px;
}

.free-form-field-container {
  margin-bottom: 10px;

  .free-form-field-name {
    display: inline-block;
    background-color: #1376BC;
    padding: 10px;
    height: 30px;
    color: white;
    font-weight: bold;
    font-size: 15px;
    text-align: center;
    width: 100%;
    border-right: 1px solid #b7b7b7;
  }

  .free-form-field-input {
    display: inline-block;
    width: 100%;
  }
}

.qa-report-switch {
  text-align: center;
  margin: 0 auto;
  /* width: 49%; */
  width: 49%;
  height: 30px;
  display: inline-block;
}

.qa-report-switch-off {
  opacity: 0.2;
}

.qa-report-switch-pass {
  background-color: #d5ffce;
}

.qa-report-switch-fail {
  background-color: #ffd2bc;
}

.free-form-field-input {
  padding:5px;
  border: 1px solid #979797;
  border-top: none;

  input {
    border: none;
    height:30px;
  }
}