

div.script-meter-number-search{

	div.address-results-container{

		ul.address-results{

			list-style: none;
			padding:5px;
			
			li.address-result-item{
				text-align: center;
				background-color: #828282;
				border-radius: 5px;
				padding: 5px;
				color: white;
				cursor: pointer;
				margin-bottom:5px;

				&.active{
					background-color: #4774b7;
				}
			}

		}


	}


}