.dailler-admin-container{

	.dialler-admin-items-holder{

		.dialler-admin-item-holder{

			.dialler-admin-item{
				box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
				border: 2px solid #3a485c;
				border-radius: 15px;
				overflow: hidden;

				.dialler-admin-header{
					padding: 10px;
					background-color: #3a485c;
					color: white;
					font-weight: bold;
					display: grid;
					grid-template-rows: 1fr;
					gap: 1px 5%;
					font-size: 14px;
					grid-template-columns: 1fr;
					grid-template-areas: ".";
					&.call-header{
						grid-template-columns: 1fr 1fr 1fr;
						grid-template-areas: ". . .";
					}

					div{
						text-align: center;
					}
				}

				.dialler-admin-info-section{
					display: grid;
					grid-template-columns: 2fr 2fr 1fr;
					grid-template-rows: 1fr;
					gap: 1px 1px;
					padding: 10px;
					border-bottom: 2px solid #3a485c;
					background: none;
					
					&.conference-info-section{
						grid-template-columns: 4fr 1fr;
					}

					div{
						text-align: center;
					}
				}

				.call-actions, .conference-actions{
					padding:10px;

					.call-action, .conference-action{
						height: 50px;
						margin-top: 10px;
						width: 50px;
						border-radius: 50%;
						margin-right: 10px;
						text-align: center;
						color: white;
						font-size: 26px;
						box-shadow: 1px 2px 3px 2px rgba(0, 0, 0, 0.3);
						display: inline-block;
						padding-top: 5px;
						cursor: pointer;
						transition-duration: 0.5s;

						&:hover{
							transform: translateY(-5px);
							box-shadow: 0px 5px 5px 3px rgba(0, 0, 0, 0.2);
						}
						&.end-call, &.end-conference{
							background-color: darkred;
							i{
								transform: rotate(135deg);
							}
						}
						&.listen{
							background-color: teal;
						}
						&.barge{
							background-color: indianred;
						}

						&.join {
							background-color: #5cb85c;
						}

						i{
							vertical-align: middle;
						}
					}
				}

				.conference-calls{
					display: grid;
					grid-template-columns: 1fr 1fr 1fr;
					grid-template-rows: 1fr;
					gap: 10px;
					padding: 10px;
				

					.conference-call {
						box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
						border: 2px solid #3a485c;
						border-radius: 15px;
						overflow: hidden;

						.conference-call-caller-id{
							padding: 10px;
							background-color: #3a485c;
							color: white;
							font-weight: bold;
						}

						.conference-call-timer{
							text-align: center;
						}

						.conference-call-actions{
							padding:10px;

							.conference-call-action{
								height: 50px;
								margin-top: 10px;
								width: 50px;
								border-radius: 50%;
								margin-right: 10px;
								text-align: center;
								color: white;
								font-size: 26px;
								box-shadow: 1px 2px 3px 2px rgba(0, 0, 0, 0.3);
								display: inline-block;
								padding-top: 5px;
								cursor: pointer;
								transition-duration: 0.5s;

								&:hover{
									transform: translateY(-5px);
									box-shadow: 0px 5px 5px 3px rgba(0, 0, 0, 0.2);
								}
								&.remove-conference-participant{
									background-color: darkred;
									i{
										transform: rotate(135deg);
									}
								}
							}
						}
					}
				}

				.dialler-admin-footer{
					display: grid;
					grid-template-columns: 1fr 1fr;
					grid-template-rows: 1fr;
					gap: 1px 1px;
					padding: 5px 10px;
					background-color: rgba(58, 72, 92, 0.21);
					border-top: 2px solid #3a485c;

					div.price{
						text-align: right;
					}
				}
			}
		}
	}

	.refresh-button{
		background-color: #3a485c;
		margin-top: 20px;
	}

	.dialler-admin-header{
		display: inline-block;
	}

	.dialler-admin-loader{
		padding: 20px;
		text-align: center;
		background-color: rgba(128, 128, 128, 0.46);
		border-radius: 10px;
	}
}

.stale-item{
	border: 1px solid darkred;
    padding: 10px;
	margin-bottom: 5px;

	.stale-item-name{
		border-bottom: 1px solid darkred;
		font-size: 16px;
		font-weight: bold;
		padding: 5px;
	}
	.stale-item-details{
		width: 50%;
		display: inline-block;
		padding: 5px;
		padding-top: 10px;
	}
	.stale-item-actions{
		width: 49%;
		display: inline-block;
		padding: 5px;
	}
}

.advanced-option-section{
	.advanced-option{
		padding: 10px;
    	border-bottom: 1px solid grey;

		.option-label{
			font-weight: bold;
			font-size: 16px;
		}

		.option-description{
			font-size: 13px;
		}

		.option-button-container{
			text-align: center;

			button{
				float: none;
				width: 80%;
			}
		}

		.info-box{
			min-height: 50px;
			box-shadow: none;	
			border: 1px solid #c1c1c1;

			.info-box-icon{
				font-size: 45px;
				line-height: 52px;
				height: 50px;
			}
			
		}

		.option-date-range{
			padding:10px;
		}
	}
}