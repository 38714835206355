/* COMMON */
/* HOME */
/* REGION */
/* CURRENT SUPPLY */
/* ESTIMATORS */
/* RESULTS */
/* SIGNUP */
/* CONFIRMATION */
/* THANK YOU */
/* BUSINESS */
/* not used */
/******* COLOUR KEY *******/

.maptitle { border-bottom: 3px solid #91c129; }

/* region map */
#RegionSelection { padding-bottom: 4.6em; padding-top: 30px; background-color: white; height: 400px; margin-bottom: 50px; position: relative; width: 700px; }
/*clearfix; overflow: hidden fux0rs with small-width screens*/
#RegionSelection:after { content: "."; display: block; clear: both; color: #ffffff; }
#RegionSelection div#RegionLabelsLeft input, #RegionSelection div#RegionLabelsRight input { display: inline; margin: 0; padding: 0; margin-right: 5px; width: auto; border: none; vertical-align: text-top; }
#RegionSelection form { list-style: none; width: 326px; height: 369px; background-image: url('/images/UD/map/MCOUK_MAP5.gif'); background-repeat: no-repeat; background-position: 0; padding: 0px; position: static; }
#RegionSelection RegionLabel { display: block; padding: 0; }
#RegionSelection RegionLabel input { background-color: transparent; }
#RegionSelection RegionLabel { font-weight: normal; width: 15em; }
#RegionSelection p { width: 28em; margin-bottom: 0; margin-top: 0.25em; position: absolute; right: 35px; top: 40px; }
#regionSelect legend { padding-left: 280px; padding-top: 50px; width: 450px !important; }
#regionSelect legend span { display: block; background: none; border: none; font-size: 1em; font-weight: normal; padding: 0; padding-right: 100px; color: #191919; margin-left: -5px; }
#RegionLabelsLeft, #RegionLabelsRight { margin-top: 20px; }
#RegionLabelsRight { position: absolute; right: -50px; width: 200px; }
#RegionLabelsLeft { left: 335px; position: absolute; width: 200px; }
RegionLabel { padding-left: 8px; height: 2.9em; }
/*expanding-box bug*/
RegionLabel span { display: block; }
RegionLabel span:focus, label.RegionLabel span:hover, label.RegionLabel span:active { color: inherit; }
#regionForm #buttons { position: absolute; top: 425px; width: 650px; }
span.RegionTip { font-size: 0.8em; margin-left: 18px; }
span.regionError, #RegionSelection span.error, #RegionSelection span.field-validation-error { color: #ff0000; font-weight: bold; width: 40em; display: block; margin-left: 240px; margin-top: 23em; }
div.MapLabel, div.MapLabel span { position: absolute; }
div.MapLabel { background: url('/images/UD/map/MCOUK_MAP5.gif') -9999em -9999em no-repeat; left: 0; top: 0; text-decoration: none; width: 100%; height: 100%; }
div.MapLabel span { left: -9999em; /*konqueror heeft problemen met "left" maar Window-Eyes met "top" arg*/ top: -9999em; color: #000; background-color: #fbeb92; border: 1px solid #000; padding: 2px 5px; text-align: center; }
#RegionLabelsLeft label.selected, .selected { font-weight: bold; }
RegionLabel:hover + div.MapLabel span, div.MapLabel:hover span, div.MapLabel:focus span { left: 25%; top: 25%; }
/*IE6 heeft eigen declaration nodig*/
div.MapLabel:hover span { left: 25%; top: 25%; }
/*trigger*/
* html div.MapLabel:hover { visibility: visible; }
/* Region map layout */
/* Shift small regions to the front */
#London { z-index: 140; }
#EastMidlands { z-index: 100; }
div.MapLabel#ScottishHydro { width: 129px; height: 145px; top: -30px; left: -454px; }
div.MapLabel#ScottishPower { width: 81px; height: 73px; top: 65px; left: -413px; }
div.MapLabel#Norweb { width: 57px; height: 92px; top: 114px; left: -153px; }
div.MapLabel#Northern { width: 71px; height: 91px; top: 88px; left: -131px; }
div.MapLabel#Yorkshire { width: 61px; height: 34px; top: 166px; left: -325px; }
div.MapLabel#NIreland { width: 75px; height: 60px; top: 109px; left: -270px; }
div.MapLabel#Manweb { width: 66px; height: 56px; top: 184px; left: -183px; }
div.MapLabel#Swalec { width: 73px; height: 44px; top: 231px; left: -414px; }
div.MapLabel#Sweb { width: 99px; height: 67px; top: 265px; left: -435px; }
div.MapLabel#Midlands { width: 51px; height: 65px; top: 202px; left: -144px; }
div.MapLabel#EastMidlands { width: 60px; height: 62px; top: 190px; left: -107px; }
div.MapLabel#Southern { width: 70px; height: 60px; top: 248px; left: -349px; }
div.MapLabel#Seeboard { width: 59px; height: 34px; top: 266px; left: -289px; }
div.MapLabel#London { width: 20px; height: 13px; top: 260px; left: -66px; }
div.MapLabel#Eastern { width: 77px; height: 66px; top: 203px; left: -86px; }
* html #ScottishHydro, * html #ScottishHydro:hover { height: 145px; }
* html #ScottishPower, * html #ScottishPower:hover { height: 73px; }
* html #Norweb, * html #Norweb:hover { height: 92px; }
* html #Northern, * html #Northern:hover { height: 91px; }
* html #Yorkshire, * html #Yorkshire:hover { height: 34px; }
* html #NIreland, * html #NIreland:hover { height: 34px; }
* html #Manweb, * html #Manweb:hover { height: 56px; }
* html #Swalec, * html #Swalec:hover { height: 44px; }
* html #Sweb, * html #Sweb:hover { height: 67px; }
* html #Midlands, * html #Midlands:hover { height: 65px; }
* html #EastMidlands, * html #EastMidlands:hover { height: 62px; }
* html #Southern, * html #Southern:hover { height: 60px; }
* html #Seeboard, * html #Seeboard:hover { height: 34px; }
* html #London, * html #London:hover { height: 13px; }
* html #Eastern, * html #Eastern:hover { height: 66px; }
div.radio:hover + #ScottishHydro, #ScottishHydro:hover, #ScottishHydro.selected { background-position: -332px -2px; }
div.radio:hover + #ScottishPower, #ScottishPower:hover, #ScottishPower.selected { background-position: -332px -148px; }
div.radio:hover + #Norweb, #Norweb:hover, #Norweb.selected { background-position: -530px -99px; }
div.radio:hover + #Northern, #Northern:hover, #Northern.selected { background-position: -466px -2px; }
div.radio:hover + #Yorkshire, #Yorkshire:hover, #Yorkshire.selected { background-position: -464px -99px; }
div.radio:hover + #NIreland, #NIreland:hover, #NIreland.selected { background-position: -561px -6px; }
div.radio:hover + #Manweb, #Manweb:hover, #Manweb.selected { background-position: -451px -172px; }
div.radio:hover + #Swalec, #Swalec:hover, #Swalec.selected { background-position: -334px -255px; }
div.radio:hover + #Sweb, #Sweb:hover, #Sweb.selected { background-position: -326px -300px; }
div.radio:hover + #Midlands, #Midlands:hover, #Midlands.selected { background-position: -409px -231px; }
div.radio:hover + #EastMidlands, #EastMidlands:hover, #EastMidlands.selected { background-position: -523px -200px; }
div.radio:hover + #Southern, #Southern:hover, #Southern.selected { background-position: -434px -307px; }
div.radio:hover + #Seeboard, #Seeboard:hover, #Seeboard.selected { background-position: -466px -136px; }
div.radio:hover + #London, #London:hover, #London.selected { background-position: -474px -251px; }
div.radio:hover + #Eastern, #Eastern:hover, #Eastern.selected { background-position: -506px -267px; }
/* end */


/* COOKIE CONSENT */
@media only screen and (max-width: 860px) {
.mapdiv .buttondiv .content{width:96%;}
}
@media only screen and (max-width: 767px) {
    #friContent, #RegionSelection, #RegionSelection form{width:100%; padding:0 5%;}
    #RegionLabelsLeft{position:static; float:left; margin-right:15px; width:45%;}
    #RegionLabelsRight{position:static; float:left; width:45%;}
    #RegionSelection form{background:none !important;}
    div.MapLabel{background:none !important;}
}
@media only screen and (max-width: 700px) {
.mapdiv .buttondiv .content { width: 100%; }
}

@media only screen and (max-width: 600px) {
}


@media only screen and (max-width: 480px) {
#RegionSelection, #RegionSelection form{height:auto;}
 #RegionLabelsLeft{float:none; margin-right:0; width:100%;}
    #RegionLabelsRight{float:none; width:100%;}
}