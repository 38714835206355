.partner-email {
  .partner-container {
    background-color:#f5f5f5;
    margin: 0 auto;
    text-align: center;
    width: 60%;
    min-width:250px;
    border-radius: 3px;
    color:#242424;
    padding-bottom:20px;
  }

  h2 {
    color:#0067b9;
    padding-top:10px;
  }

  .partner-header {
    width:100%;
    background-color:#e7e7f0;
    height:50px;
  }

  .partner-reset-button {
    height: 34px;
    width: 200px;
    background-color: #a68ad4;
    border: 1px solid #a68ad4;
    text-align: center;
    margin: 0 auto;
    line-height: 30px;
    font-weight: bold;
    font-size: 19px;
    border-radius: 4px;
  }
}