.information-trigger, .begin-upload-trigger, .show-history-trigger, .begin-download-trigger, .information-trigger-invoice {
  i {
    &:hover {
      color: $hover-button;
    }

    color: $main-brand-colour;
  }

  cursor: pointer;
  float: right;
  padding: $standard-padding;
  display: inline-block;
  @include transition(all 0.5s ease);
}

.list-upload-validation-header {
  background-color: #f2fdff;
  font-weight: bold;
  font-size: 16px;
  border-bottom: 1px solid #b9b9b9
}

.list-upload-data-row {
  margin-left:0px;
  margin-right:0px;

  .col-md-5 {
    padding-left:0px;
  }

  .row {
    height:40px;
    border-bottom:1px solid #d5d5d5;
  }
  .center-data-row {
    border-left: 1px solid grey;
    border-right: 1px solid grey;
    height:100%
  }

  .negative-result-value {
    background-color: #ffd9a1;
  }

  .positive-result-value {
    background-color: #ddffdd;
  }
}

#uploaded_fields_list {
  margin: 0;
  padding: 0;
  list-style: none;

  li {
    font-weight:500;
    height:40px;
  }

  .ui-state-highlight {
    background-color: #fff4e0;
    border: 1px solid #c9c9c9;
    color: black;
  }
}

#available_fields_list {
  margin: 0;
  padding: 0;
  list-style: none;

  .ui-state-highlight {
    background-color:white;
    border: 1px solid #c9c9c9;
    color: black;
  }

  li {
    font-weight:500;
    padding:8px;
    //width:100%;
    height:40px;
    border:1px solid #b1b1b1;
  }
}
.pre-validate-upload-wizard {
  padding:30px;

  a.upload-list-data-button.btn.btn-success {
    margin-left: 0px;
    margin-top: 10px;
  }
}

.remove-wizard-field {
  color: #ff2d2d;
}

.mapping-input {
  width:100%;
  height:35px;
}

#upload-history-container {
  .history-container-row {
    //background-color: #ebf9ff;
    border-bottom:1px solid #bebebe;
    margin-left:0px;
    margin-right:0px;

    .row {
      margin-left:0px;
      margin-right:0px;
    }

    .sub-history-row {
      border-bottom:1px solid #e3e3e3;
    }

    .history-details-row {
      background-color: #f3fff9;
    }

    p {
      padding-left:15px;
      padding-right:15px;
    }
  }
}

#history-table {
  .col-md-3 {
    margin-left:0px;
  }

  .download-history-item {
    cursor: pointer;
    color: #4774b7;
  }
}

.dialler-list-management-page{
	.event-icon-small{
		height:40px;
	}
}


.link-different-call-modal{
	.modal-content{
		width: 90%;
		margin: auto;
		margin-top: 20px;
	}
}
