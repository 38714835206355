div.price-alert-warning {
    //width: 1000%;
    //margin-left: -1000%;
    background: #cc1800;
    padding: 5px;
    color: white;
    font-weight: bold;
	border-radius: 10px;
    border-radius: 10px;
    a {
        color: white;
    }
}

@for $i from 1 through 100 {
    div.price-alert-warning.status-#{$i} {
        opacity: $i/100;
    }
}

div.price-alert-warning.status-101 {
    opacity: 0.8;
}

input.target-saving-slider{
    padding:10px;
    border:none;
    width:40%;
}

input.addoned-left {
    margin-left: 0;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}
input.addoned-right {
    margin-right: 0;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}

span.inline{
    display:inline;
}

span.stupid-percent {
    position: absolute;
    padding-right: 25px;
}
