$nice_grey : #aaaaaa;
$nice_ish_green : #a8ca5c;
$nicer_green : #5cb85c;
$nice_orange : #ffab2c;
$nice_red : #a94442;
$nice_blue : #4774b7;
$nice_ish_grey :#eeeeee;


@for $i from -100 through 200 {

	.percent-width-#{$i}, .width-#{$i}-percent {
		width: #{$i + '%'};
	}
	
	.max-percent-width-#{$i}, .max-width-#{$i}-percent {
		max-width: #{$i + '%'};
	}

	.fixed-width-#{$i}, .width-#{$i} {
		width: #{$i}px;
	}
	
	.fixed-height-#{$i} {
		height: #{$i}px;
	}
	
	.height-#{$i} {
		height: #{$i}px;
	}

	.min-height-#{$i} {
		min-height : #{$i}px;
	}

	.pad-me-please-#{$i}{
		padding : #{$i}px;
	}
	
	.pad-me-please-left-#{$i}, .pad-me-left-please-#{$i}{
		padding-left : #{$i}px;
	}
	
	.pad-me-please-right-#{$i}, .pad-me-right-please-#{$i}{
		padding-right : #{$i}px;
	}
	
	.pad-me-please-top-#{$i}, .pad-me-top-please-#{$i}{
		padding-top : #{$i}px;
	}
	
	.pad-me-please-bottom-#{$i}, .padding-bottom-#{$i}, .pad-me-bottom-please-#{$i}{
		padding-bottom : #{$i}px;
	}

	.rounded-#{$i}, .border-radius-#{$i}{
		border-radius: #{$i}px;
	} 

	.rounded-top-left-#{$i}{
		border-top-left-radius : #{$i}px;
	}
	
	.rounded-top-right-#{$i}{
		border-top-right-radius : #{$i}px;
	}
	
	.grey-border-#{$i}{
		border : #{$i}px solid $nice_grey;
	}
	
	.grey-border-bottom-#{$i}{
		border-bottom : #{$i}px solid $nice_grey;
	}

	.grey-ish-border-bottom-#{$i}{
		border-bottom : #{$i}px solid $nice_ish_grey;
	}

	.grey-border-top-#{$i}{
		border-top : #{$i}px solid $nice_grey;
	}

	.blue-border-#{$i}{
		border : #{$i}px solid $nice_blue;
	}
	
	.red-border-#{$i}{
		border : #{$i}px solid $nice_red;
	}

	.margin-#{$i}{
		margin: #{$i}px;
	}
	
	.margin-top-#{$i}{
		margin-top: #{$i}px;
	}
	
	.margin-bottom-#{$i}{
		margin-bottom: #{$i}px;
	}

    .margin-left-#{$i} {
		margin-left: #{$i}px;
    }

	.margin-right-#{$i}{
		margin-right: #{$i}px;
	}

	.font-size-#{$i}{
		font-size: #{$i}px;
	}

	.transition-duration-#{$i}{
		transition-duration: #{($i / 10)}s;
	}

	.right-#{$i}{
		right : #{$i}px;
	}
	
	.bottom-#{$i}{
		bottom : #{$i}px;
	}
	
	.top-#{$i}{
		top : #{$i}px;
	}
	.left-#{$i}{
		left : #{$i}px;
	}

	.float-right{
		float:right;
	}
	
	.float-left{
		float:left;
	}

}


/* postitioning */
.full-width {
	width:100%;
}

.half-width{
	display:inline-block;
	width:49%;
}

.display-inline-block{
	display:inline-block;
}

.display-block{
	display:block;
}

.display-inline{
	display:inline;
}

.no-margin-left{
	margin-left : 0;
}

.position-relative{
	position: relative;
}

.position-absolute{
	position: absolute;;
}


/********* background colors *********/
.white-bg{
	background-color: white;
}

.green-bg{
	background-color: $nice_ish_green;
}

.blue-bg{
	background-color: $nice_blue;
}

.grey-bg{
	background-color: $nice_grey;
}

.light-grey-bg{
	background-color: $nice_ish_grey;
}

.red-bg{
	background-color: $nice_red;
}

.orange-bg{
	background-color: $nice_orange;
}

.no-bg {
	background: none;
}

/* font colors */

.white-font, .font-color-white, .text-color-white, .white-text, .color-white{
	color: white;
}

.red-font, .font-color-red, .text-color-red, .red-text, .color-red{
	color: $nice_red;
}

.green-font, .font-color-green, .text-color-green, .green-text{
	color : $nice_ish_green;
}

.greener-font, .font-color-greener, .text-color-greener, .greener-text{
	color : $nicer_green;
}

.blue-font, .font-color-blue, .text-color-blue, .blue-text, .color-blue{
	color:$nice_blue;
}

.grey-font, .font-color-grey, .text-color-grey, .grey-text{
	color:$nice_grey;
}

.orange-font, .font-color-orange, .text-color-orange, .orange-text{
	color:$nice_orange;
}


.bold{
	font-weight: bold;
}


/* buttons */


// Lets start this section by re-bootstraping buttons
button.btn-info{
	color: #fff;
    background-color: #5bc0de;
    border-color: #46b8da;
}

button.btn-primary{
	color: #fff;
	background-color: #3A485C;
    border-color: #3A485C;
}

button.btn-default{
	color: #333;
	background-color:#fff;
	border-color: #ccc;
}

/* other stuff */

.cursor-pointer{
	cursor: pointer;
}

.center-text, .text-center, .text-align-center{
	text-align: center;
}

.left-text, .text-left, .text-align-light, .text-align-left{
	text-align: left;
}

.right-text, .text-right, .text-align-right{
	text-align: right;
}

.not-really-hidden, .material-switch > input[type=checkbox].not-really-hidden {
	display: inline;
    position: absolute;
    opacity: 0;
}

.flat-right{
	border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
}
.flat-left{
	border-top-left-radius: 0px;
	border-bottom-left-radius: 0px;
}
.white-space-pre-line{
	white-space: pre-line;
}

.row-reverse{
	flex-direction: row-reverse;
}


.heading.primary{
    color: white;
    background-color: #3a485c;
}

/* 3 column lay out styles  */

.sortable-handle{
//	background: #fff;
    list-style-type: none;
    width: 100%;
    min-height: 40px;
	border-bottom: 1px solid #ddd;
	position: relative;
	white-space:nowrap;
	
	.name{
		float: left;
		padding: 10px;
		position: absolute;
		left: 50px;
		z-index: -1;;
	}
}

div.sub-list{
	width:80%;
	margin: auto;
}

.hide-delete{
	.delete-trigger{
		display: none;
	}
}

.edit-trigger{
	cursor: pointer;
}


/* lightbox */
.light-box {
	display: none; /* Hidden by default */
	position: fixed; /* Stay in place */
	z-index: 1; /* Sit on top */
	padding-top: 100px; /* Location of the box */
	left: 0;
	top: 0;
	width: 100%; /* Full width */
	height: 100%; /* Full height */
	overflow: auto; /* Enable scroll if needed */
	background-color: rgb(0,0,0); /* Fallback color */
	background-color: rgba(0,0,0,0.9); /* Black w/ opacity */
	backdrop-filter: blur(2px);
  }

.light-box-content {
	margin: auto;
	display: block;
	width: 80%;
	max-width: 700px;
	text-align: center;
}

.light_box_caption {
	margin: auto;
	display: block;
	width: 80%;
	max-width: 700px;
	text-align: center;
	color: #ccc;
	padding: 10px 0;
	height: 150px;
}

.light-box-content {
	animation-name: zoom;
	animation-duration: 0.6s;
}

@keyframes zoom {
	from {
		transform: scale(0)
	}
	to {
		transform: scale(1)
	}
}

.close {
	position: absolute;
	top: -6px;
	right: -15px;
	color: #f1f1f1;
	font-size: 29px;
	font-weight: bold;
	-webkit-transition: 0.3s;
	transition: 0.3s;
}

.close:hover,
.close:focus {
	color: #bbb;
	text-decoration: none;
	cursor: pointer;
}

/* 100% Image Width on Smaller Screens */
@media only screen and (max-width: 700px) {
	.modal-content {
		width: 100%;
	}
}

.dropdown-component-container{
	padding: 5px;
	margin-top: -5px;
	cursor: pointer;
	position: absolute;
	right: 5px;
	top: 5px;

	&:hover{
		.dropdown-component-menu{
			display: block;
			background-color: white;
			position: absolute;
			right: 0px;
			color: #4774b7;
			padding: 5px;
			border: 1px solid #4774b7;
			border-radius: 5px;
			width: max-content;
			div{
				display: inline-block;
				margin-top: -5px;
				cursor: pointer;
				padding: 5px;
				i{
					&:hover{
						color: $nice_ish_green;
					}
				}
			}
		}
	}
	.dropdown-component-menu{
		z-index:50;
		display: none;
	}
}

.custom-confirm-modal{
	.modal-header{
		.close{
			margin-top: 15px;
			margin-right: 30px;
			color: black;
		}
	}
}

//Start switch input
.switch-input {
	position: relative;
	display: inline-block;
	width: 55px;
	height: 29px;
}

.switch-input input {
	opacity: 0;
	width: 0;
	height: 0;
}

.switch-input-slider {
	position: absolute;
	cursor: pointer;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background-color: #ccc;
	-webkit-transition: .4s;
	transition: .4s;
}

.switch-input-slider:before {
	position: absolute;
	content: "";
	height: 23px;
	width: 23px;
	left: 3px;
	bottom: 3px;
	background-color: white;
	-webkit-transition: .4s;
	transition: .4s;
}

input:checked + .switch-input-slider {
	background-color: #2196F3;
}

input:focus + .switch-input-slider {
	box-shadow: 0 0 1px #2196F3;
}

input:checked + .switch-input-slider:before {
	-webkit-transform: translateX(26px);
	-ms-transform: translateX(26px);
	transform: translateX(26px);
}

/* Rounded sliders */
.switch-input-slider.round {
	border-radius: 34px;
}

.switch-input-slider.round:before {
	border-radius: 50%;
}
//end switch input

.form-control.height-30{
	height:30px;
}
