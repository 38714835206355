//Notifications
.dropdown {
	display:inline-block;
    margin-left:20px;
    padding:10px;
    position: absolute;
    z-index: 10;
 }
 
.dropdown-menu {
	background-color: #000;   
}

.dropdown-menu {
    position: static;
    float: none;

    li a{
	    height: 40px;
		padding-top: 11px;
    }
    .divider{
    	margin:5px 0;
    }

}

.menu-title {
    color:#008ae6;
    font-size:1.1em;
    display:inline-block;
}

.notifications-wrapper {
	overflow:auto;
	max-height:250px;
}
    

.notifications {
	min-width:420px; 
	a.content {
		 text-decoration:none;
		 margin-left: 15px;
	}
 }

.notification-item {
	padding:2px 10px;
	margin:5px;
	border-radius:4px;
 }

.notify-drop {
    background-color: #fff;
    padding: 3px 0;
}

.notify-line {
    border-color: #D0CDCD;
    margin-top: 5px;
    margin-bottom: 10px;
}

.notification-heading  {
    background-color: #fff;
    border-bottom: solid 1px #ddd;
    border-bottom: solid 1px rgba(100, 100, 100, .22);
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
    padding: 0px 8px 0px 8px;
}

.glyphicon-bell {
	font-size:1.3rem;
 }

.notification-footer {
	width: 100%;
	border-top: 1px dotted #fff;
}

.notification-contact,.notification-count {
	display: inline;
    font-size: 1.5em;
    animation: pulsate 2s infinite;
    padding: 2px 4px;
    border-radius: 15px;
	position: relative;
    top: -2px;
}

.notification-events {
	display: inline;
    font-size: 9px;
	background: #ef7d24;
    /* animation: pulsate 2s infinite; */
    padding: 2px 4px;
    border-radius: 15px;
	position: relative;
    top: -2px;
}

.notification-leads {
	display: inline;
    font-size: 9px;
	background: #ef7d24;
    /* animation: pulsate 2s infinite; */
    padding: 2px 4px;
    border-radius: 15px;
	position: relative;
    top: -2px;
}