.new-invoice-form {
  padding:20px;
}

.invoice-logo-image {
  width:150px;
}

.invoice-table-items {
  width:90%;
  margin: 0 auto;
  border: 1px solid #c3c3c3;

  thead {
    color: black;
    background-color: #fbfbfb;
    height: 30px;
    border: 1px solid #b9b9b9;
  }

  tr {
    height:30px;
    border-bottom:1px solid #e0e0e0;
    text-align: center;
  }
}

.invoice-dash-component {
  border: 1px solid #d8d8d8;
  border-bottom: 2px solid #87c5d6;
  border-radius: 5px;
  padding-left: 10px;
  padding-right: 10px;
  padding-bottom: 5px;
  background-color: #f8f8f8;
  box-shadow: 0px 3px 5px 5px rgba(199, 199, 199, 0.23);
  height: 90px;

  h3 {
    margin-top:10px;
  }
}