div.info-template-list{

	li.trigger-container{
		display: grid;
    	grid-template-columns: 50px 1fr 50px 32px 32px;
		grid-template-rows: 1fr;
		gap: 1px 1px;
		grid-template-areas: ". . . . .";
		width: 100%;
		height: 100%;

		.trigger {
			max-width: 100%;
			max-height: 92%;
			overflow-y: hidden;
			text-overflow: ellipsis;
			white-space: nowrap;
			overflow-x: hidden;
		}

	}
}