.headline-test {
  padding: 10px;
  border-radius: 4px;
  font-size: 16px;
  border: 1px solid #e0dfdf;
}

.headline-error {
  //#ffb9b9
  background-color: rgba(255, 214, 214, 0.5);
}

.headline-success {
  background-color: rgba(226, 255, 241, 0.76);
}

.recent-tests {
  border: 1px solid #ececec;
  border-radius: 4px;
  padding: 15px;
  background-color: #fbf8f833;
}

.spotlight-test-result {
  border: 1px solid #d6d6d6;
  padding: 10px;
  border-radius: 4px;
}

.spotlight-test-result-success {
  background-color: #e2fff0;
}

.spotlight-test-result-error {
  background-color: #ffeeee;
}

.inner-padding-container {
  padding:10px;
}

.test-drill-down {
  float:right;
  padding-left:10px;
}

.test-date-created {
  float:right;
  font-size: 10px;
  line-height:20px;
}

.drill-down-container {
  display:none;
  background-color: #f7f7f7;
  margin-left: 4px;
  margin-right: 4px;
  border: 1px solid #f3efef;
  border-top: 0;
}

.recent-tests-action-left {
  border: 1px solid #ececec;
  border-radius: 4px;
  padding: 15px;
  margin-left: -15px;
  background-color: #fbf8f833;
  height: 70px;
}

.recent-tests-action-right {
  border: 1px solid #ececec;
  border-radius: 4px;
  padding: 15px;
  margin-right: -15px;
  background-color: #fbf8f833;
  height: 70px;
}

.recent-tests-failures {
  border: 1px solid #ececec;
  border-radius: 4px;
  margin-right: -15px;
  padding-top:15px;
  background-color: #fbf8f833;
  height: 100px;
}

.recent-test-failures-actions {
  border: 1px solid #ececec;
  border-radius: 4px;
  margin-right: -15px;
  padding: 15px;
  background-color: #fbf8f833;
  height: 70px;
}

.row-container-spacer {
  margin-bottom:15px;
}

.sites {
  border: 1px solid #ececec;
  border-radius: 4px;
  padding: 15px;
  margin-right: -15px;
  background-color: #fbf8f833;
  height: 70px;
}

.recent-tests-failures-view {
  border: 1px solid #ececec;
  border-radius: 4px;
  margin-right: -15px;
  padding: 15px;
  background-color: #fbf8f833;
}

#saved-events{
	.event-container.system-test{
		display: grid;
		grid-template-columns: 50px 1fr;
		grid-template-rows: 1fr;
		gap: 0px 0px;
		grid-template-areas: ". .";
		cursor: pointer;


		&.active{
			background-color: lightgrey;
			cursor: auto;
			color: #313131;

		}

		&.passed{
			.event-icon-small{
				background-color: forestgreen;
			}
		}
		&.failed{
			.event-icon-small{
				background-color: darkred;
			}
		}

		.group-information{
			padding: 5px;
		}
	}
}

.test-results-table{
	margin:0px;

	thead{
		font-size: 14px;
		/* text-align: center; */
		background-color: lightgrey;
		color: #313131;
		font-weight: normal;

		th{
			border-radius: 0;
			font-weight: normal;
			text-align: center;
			padding: 5px;
			border-right: 1px solid grey;
			&:last-of-type{
				border-right:none;
			}
		}
	}

	tr{
		border-bottom: 1px solid grey;
	}
	
	tbody{
		tr{

			&.passed{
				background-color: rgba(34,139,34,0.24);
			}
			&.failed{
				background-color: rgba(139, 0, 0, 0.28);
			}
			td{
				border-right: 1px solid grey;
				text-align: center;
				padding: 5px;
				&:last-of-type{
					border-right:none;
				}
			}
		}
	}
}

.refresh-test-list{
	float: right;
	margin-right: 5px;
	cursor: pointer;
}


.passive-mode-toggle-container{
	position: fixed;
    right: 0px;
    bottom: 0px;
    background-color: lightgrey;
    padding: 20px;
    padding-right: 10px;
    padding-bottom: 5px;
    padding-left: 20px;

	label.control-label{
		font-size: 16px;
		margin-right: 20px;
	}

	div.material-switch{
		margin-top: 5px;;
	}
}