div.business-contact{
	background-color: #e3eaf4;
    padding: 10px;
	
	div.contact-name {
		background-color: $navbar-bg;
		color: white;
		padding: 2px;
		padding-left: 10px;
		margin: -10px;
		margin-bottom: 0px;
		i.edit-button{
			float: right;
			padding: 3px;
			padding-left: 5px;
			cursor: pointer;
			color:white;
		}
	}

	div.contact-field{
		margin-top: 2px;
	}

	form {
		padding-top: 5px;

		.edit-business-contact-field {
			display: none;
		}
	}
}

div.business-contact-warning, div.business-primary-address-warning{
	padding:10px;
}

div.duplicate-contact, div.new-contact{
	display: flex;
    border-radius: 5px;
    overflow: hidden;
	margin-bottom: 10px;
	box-shadow: 2px 2px 5px $navbar-bg;
	
	.contact-info{
		width: 80%;
		background-color: #e3eaf4;

		.duplicate-contact-title{
			background-color: $navbar-bg;
			color: white;
			padding: 5px;
			a{
				color:white;
			}
		}
		.contact-field{
			padding-left:5px;

		}
	}

	.select-button{
		width:20%;
		background-color: $main-brand-colour;
		color: white;
		position: relative;
		cursor: pointer;

		.floating-button-text{
			position: absolute;
			left: 50%;
			top: 50%;
			transform: translate(-50%, -50%);
			text-align: center;
		}
	}
}

.address-primary-display{
	.delete-address{
		display: none;
	}
}

.vacated-at-date-picker-holder{
	.vacated-at-date-picker{
		width:100%;
	}
	.calendar-icon{
		position: absolute;
		right: 40px;
		top: 5px;
	}
	.caret{
		position: absolute;
		right: 25px;
		top: 12px;
	}
}


.business-stats-contianer{
	padding: 10px;


	.business-stat-item{
		text-align: center;
		width: 70%;
		margin: auto;
		background-color: $navbar-bg;
		color: white;
		border-radius: 5px;
		margin-top: 10px;
		margin-bottom: 20px;
		overflow: hidden;
		border: 2px solid $navbar-bg;

		.business-stat-title{
			font-size: 20px;
		}

		.business-stat-number{
			font-size: 40px;
			background-color: white;
			color: $main-brand-colour;
		}
	}
}

.business-orders-contianer{
	padding: 10px;

	.business-orders-table-contianer{
		padding: 10px;

		thead, tr{
			font-size: 14px;
		}

		td{
			padding: 3px;
		}

		.form-inline .form-control{
			margin-bottom: 0;
		}

		.business-order-details-loader-container{
			text-align: center;
			font-size: 20px;
		}
		.business-order-details-error-container{
			text-align: center;
			font-size: 20px;
			color :darkred;
		}
	}
}

.business-installs-contianer{
	padding: 10px;

	.business-installs-calendar-contianer{
		max-height: 65vh;
	}
}