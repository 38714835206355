/* ----------------------------- LEAD DETAILS PAGE >> SCRIPT CONTAINER ------------------------ */

.lower-module {
  margin-top: 10px;
  background-color: $module-bg;
  margin-left: 1%;
  width: 73.5%;
  position: relative;
  @include padding(15px);
}

/* ----------------------------- LEADS PAGE TABLES ------------------------  */

.table-container {
    width: 100%;
    background: #fff;
    margin-top: 20px;
	@include padding(20px);
}

table {
    table-layout:fixed;
    width:100%;
	margin-left: 20px;
	margin-right: 20px;
}

table.plain-table > thead > tr > th {
	background: #e3eaf4;
	color: $main-brand-colour;
	padding-left: 10px;
	border-bottom: none;
	font-weight: normal;
	font-size: 11px;
}

table.plain-table-tariff > thead > tr > th {
	background: #fff;
	color: $main-brand-colour;
	padding-left: 10px;
	border-bottom: none;
}

.plain-table-tariff {
	margin: 0px;
	margin-bottom: 20px;
}

div.dataTables_wrapper div.dataTables_info {
	margin-left: 10px;
    margin-bottom: 10px;
}

.col-lg-12 {
    padding-left: 0px;
	padding-right: 0px;
}

.even, .odd {
  font-size: 11px;
}

tr.highlight {
    background-color: $main-brand-colour !important;
}

.sorting, .sorting_asc {
  background-color: $main-brand-colour;
  color: #fff;
}

tr {
  height: 10px;               // <-- the rows height 
}

td {
	vertical-align: middle;
}

thead { 
	color: #4774b7;
    font-size: 11px;
	background: #e3eaf4;
}

table.dataTable thead > tr > th.sorting_asc, table.dataTable thead > tr > th.sorting_desc, table.dataTable thead > tr > th.sorting, table.dataTable thead > tr > td.sorting_asc, table.dataTable thead > tr > td.sorting_desc, table.dataTable thead > tr > td.sorting {
	padding: 5px;
}

table {
  height: 100%;               // <-- Select the height of the table
  display: -moz-groupbox;    // For firefox bad effect
  word-wrap: break-word;
}

table.table-bordered.dataTable tbody th, table.table-bordered.dataTable tbody td {
  padding-left: 10px;
  padding-right: 10px;
  vertical-align: middle;
}

.table > thead > tr > th {
  background: $main-brand-colour;
  color: $white-text;
  font-size: $standard-paragraph;
  padding: 5px;
}

table.dataTable thead .sorting:after, table.dataTable thead .sorting_asc:after {
  line-height: 1;
}

table.dataTable {
    border-bottom: 1px solid #ddd;
}

.pagination > .active > a {
	background-color: $main-brand-colour;
	border-color: $main-brand-colour;
}

.edit-lead {
	color: $main-brand-colour;
	margin-right: 10px;
	margin-left: 5px;
}

.delete-lead {
	color: #666;
	margin-right: 10px;
}

.stop-lead {
	color: #ec0d0d;
	margin-right: 10px;
}