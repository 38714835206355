.rss-providers-wrapper .group-information.saved-rss-provider-name,
.rss-feeds-wrapper .group-information.saved-rss-feed-name {
	width: 390px;
}

#rss-wrapper {
	#saved-rss-providers {
		cursor: default;
	}
}

.form-feed-editor {
	width: 100%;
	.text-header {
		width: 100%;
	}
}

.rss-item-name {
    display: inline-block;
    width: 80%;
	font-size: 12px;
    line-height: 15px;
    padding: 2px;
}