.navmenu {
   	background-color: $primary-color;
    top: 0;
    width: 250px;
	@include padding(0);
	@include margin(0);
    list-style: none;
}

.sidebarheader {
    background-color: $secondary-color;
	@include padding(10px 0 10px 10px);
    text-transform: uppercase;
    color: $white;
    margin-top: 15px;
}

.childlist {
    background-color: #313d4e;
}

.list-group-item {
	&:first-child {
		@include padding(9px 12px 1px 10px);
		@include border-radius-top(0px);
	}
	&:last-child {
		@include border-radius-bottom(0px);
    }
	a {
		&:focus {
			background-color: rgba(0,0,0,0);
		}
		color: $white;
		font-size:15px;
	}
	background-color: $primary-color;
    border: none;
	position: relative;
    display: block;
	@include padding(12px 12px 10px 12px);
}

a.list-group-item, button.list-group-item { 
	&:hover, &:focus {
		text-decoration: none;
		color: $main-brand-colour;
		background-color: #f5f5f5;
	}
    color: $white;
	@include transition(background-color 0.5s ease);
}

a.list-group-item.sidebar-menu-item-dark-blue {
	&:hover, &:focus {
		background-color: #21303e;
	}
	background-color: #21303e;
}
.list-group.panel {
	height: 98vh;
	margin-top: 10px;
	background: #1e0354;
	overflow-y: auto;
}

.list-group.panel::-webkit-scrollbar {
    width: 0px;  /* Remove scrollbar space */
    background: transparent;  /* Optional: just make scrollbar invisible */
}

.tooltip.in{
	opacity: 1;
}

.tooltip.right .tooltip-arrow{
	border-right-color: #3A485C;
}
.tooltip-inner{
	background-color: #3A485C;
}



.panel {
    border-radius: 0px;
    border:0;
    box-shadow: 0 0px 0px rgba(0, 0, 0, 0.05);
}

.logospace {
	font-size: 18px;
	padding-left: 80px;
	@media(max-width:768px) {
		 padding-left: 15px;
		 font-size: 18px;
	}
}

.activity-feed {
    .feed-item {
		&:after {
			content: "";
			display: block;
			@include absolute-left-top(0, -6px);
			width: 10px;
			height: 10px;
			border-radius: 6px;
			background: #fff;
			border: 1px solid #f37167;
		}
		&:last-child {
			border-color: transparent;
		}
		.activity-date {
			position: relative;
			top: -5px;
			color: #6E757D;
			text-transform: uppercase;
			font-size: 13px;
		}
		.activity-text {
			position: relative;
			top: -3px;
		}
		position: relative;
		@include padding(0 0 20px 30px);
		border-left: 2px solid #e4e8eb;
    }
	@include padding(15px);
}

#sidebar {
	min-width: 44px;
}

#main {
	width: 1%;
	left: 0;
}

.row-offcanvas {
	.active {
		left: 3%;
	}
    position: relative;
	@include transition(all 0.25s ease-out);
}

.row-offcanvas-left.active .sidebar-offcanvas {
	@include absolute-left-top(0, -3%);
	width: 3%;
	text-align: center;
	min-width: 42px;

}

//.sidebar-offcanvas {
//	.nav {
//		overflow: auto;
//	}
//
//	.nav::-webkit-scrollbar-thumb {
//		background-color: #4774b7;
//		outline: 1px solid slategrey;
//	}
//
//	.nav::-webkit-scrollbar {
//		width: 0.5em;
//	}
//
//	.nav::-webkit-scrollbar-track {
//		-webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
//	}
//}
  
#main {
    left: 0;
}
  
.dropbtn {
    border: none;
    cursor: pointer;
}

.dropdown-sidebar {
    position: relative;
    display: inline-block;
	width: 100%;
}

.orders-dropdown {
	padding-bottom: 10px!important;
}

.dropdown-content-orders, .dropdown-content-remarket, .dropdown-content-admin {
	a {
		color: #fff;
		text-decoration: none;
		display: block;
		background: $navbar-bg;
	}
    position: relative;
    min-width: 100%;
    z-index: 1;
	background: #54647b;
	vertical-align: top;
}

.sidebar-child-list-contianer{
	.sidebar-items-wrapper{
		a.list-group-item{
			background: $navbar-border;
			border-color: $navbar-border;
		}
	}
}

.dropdown-content-orders {
	a {
		&:first-of-type {
			border-bottom: 1px solid #3a485c;
		}
		&:hover { 
			background-color: #f1f1f1;
		}
	}
	@include transition(background-color 0.5s ease);
}

.sidebar-caret {
    position: relative;
    top: 9px;
}

.dropdown-sidebar:hover .dropbtn {
    background-color: #3e8e41;
	@include transition(background-color 0.5s ease);
}

.nav-tabs > li.active > a, .nav-tabs > li.active > a:hover, .nav-tabs > li.active > a:focus {
    color: #fff;
	font-size: 12px;
    background-color: #4774b7;
    border: 1px solid #4774b7;
	@include transition(background-color 0.5s ease);
}

.nav-tabs {
    border-bottom: none;
	font-size: 12px;
}

.nav > li > a {
    position: relative;
    display: block;
    padding: 5px 10px;
    border-radius: 0px;
    background-color: rgba(71, 116, 183, 0.15);
}

.nav-tabs > li {
	margin-bottom: 0px;
}

.diy-tooltip {
	display: none;
	position: absolute;
    left: 57px;
	height: 38px;
	white-space: nowrap;
    background: #3a485c;
    padding: 11px;
	color: #fff;
    top: 0px;
    z-index: 999 !important;
	border-top-right-radius: 4px;
	border-bottom-right-radius: 4px;
}

#sidebar-wrapper {
  margin-left: -250px;
  left: 0;
  top: 51px;
  left: 250px;
  width: 250px;
  background: #000;
  position: fixed;
  height: 100%;
  overflow-y: auto;
  z-index: 1000;
  @include transition(all 0.4s ease 0s);
}

#wrapper.active {
  padding-left: 170px;
  left: 0;
}

#page-content-wrapper {
  width: 100%;
  padding-right: 40px;
  padding-top: 0px;
  transition: all 0.4s ease 0s;
}

#sidebar {
	.collapse.in {
		display: inline;
	}
	.nav {
		li {
			a {
				white-space: nowrap;
				overflow: hidden;
			}
		}
	}
    padding-left: 0;
    float: left;
    min-height: 100%;
    position: fixed;
    left: 0;
    top:0;
    background-color: $navbar-bg;
    min-width: 55px;
    padding-top: 0px;
	z-index: 1;
}

#main {
    padding: 15px;
    left: 0;
    display: none;
}

.menu-text {
	position: relative;
	float: left;
}

.navbar, .navbar-default {
	border-radius:0px;
	background-color: $navbar-bg;
	color: $white-text;
	border: none;
	margin-bottom: 0px;
	padding-top:10px;
	.col-md-3 {
		margin:0px;
	}
	a {
		color:white;
	}
	.nav-icon-container {
		padding:0px;
		text-align:center;
	}
}

.dropdown-toggle a {
	color: $white-text;
}

#wrapper {
	padding-left: 55px;
}


// navigation colour coding

a.list-group-item {
	border-top: 1px solid $navbar-bg; // 1px solid #506380 revert this line for standard nav settings
	font-size: 13px;
	height: 40px;
}

.breadcrumbs-bar {
	height: 30px;
	border-bottom: 1px solid $navbar-border;
	background: $navbar-bg;
	@include padding(5px 0 0 0);
	color: $white-text;
	border-top: 1px solid $navbar-border;
}

ul.user-info {
    list-style: none outside none;
    @include margin(0);
    @include padding(0);
}
.pull-left {
    float: left!important;
	height: 30px;
    line-height: 1;
	font-size: $api-button-font-size;
}

.pull-right {
    float: right!important;
	height: 30px;
    line-height: 1;
	font-size: $api-button-font-size;
}

.search-drop-button {
    float: right!important;
	height: 30px;
    line-height: 1;
	@include margin(10px 0 0 0);
	font-size: $api-button-font-size;
}

.profile-info img.avatar {
    @include margin(0 8px 0 0);
    height: 31px;
    width: 31px;
    border: 2px solid;
}

li.profile-info.dropdown {
	@include margin(5px 0 0 0px);
	border: none;
}

.profile-icon {
  color: $main-brand-colour;
  @include margin(0 5px 0 0);
}

.profile-name  {
	a {
		color: $white-text;
	}
}

.profile-name { 
	a {
		&:last-of-type {
			@include padding(0 15px 0 0);
			border-right: 1px solid #506380;
		}
	}
}

.profile-icon-sidebar {
	color: $white-text;
}

.profile-controls {
	li {
		float:left;
		list-style-type: none;
	}
    float:right;
	@include padding(12px);
}

.profile-quick-links {
	color: $white-text;
	width: auto!important;
	// @include padding(0px 15px 0px 10px);
	@include margin(5px);
}

.profile-control-icon {
	&:hover {
		color: $main-brand-colour;
	  }
    @include margin(0 0 0 25px);
    vertical-align: middle;
    line-height: inherit;
	color: $white-text;
}

.profile-icon-last {
    line-height: inherit;
}

/* ----------------------------- NOTIFICATIONS ------------------------  */

#icon, #icon-to-do {
    background-color: $training-orange;
    position:relative;
}

#not {
	@include absolute(-29.9px, none, none, 8px);
	@include padding(1px);
    background-color: #ef7d24;
    width: 17px;
    font-size: 9px;
    height: 17px;
    color: $white-text;
    border-radius: 20px;
    -moz-border-radius: 20px;
    -webkit-border-radius: 20px;
    text-align: center;
    border: 2px solid $white;
}

#to-do {
	@include absolute(-29.9px, none, none, 8px);
    @extend #not;
}

#profile-notify {
	@include absolute(-30.9px, none, none, 19px);
    @extend #not;
}

#current-product-type {
	line-height: 2;
	font-style: italic;
	@include margin(0 5px 0 5px);
}

#search {
    float: right;
	@include margin(9px 0 0 0);
    width: 100%;
}

.search-results-pagination {
	active & {
		color: $white;
		background: #7a9fd6;
	}
	color: #7a9fd6;
    background: $white;
    border-radius: 4px;
	@include padding(5px 7px 5px 7px);
	@include margin(0 3px 0 0);
}

#search-drop {
    background: rgb(58, 72, 92);
    z-index: 1;
    width: 30%;
	@include padding(10px);
	@include absolute(50px, 20px, none, none);
}

#close-search {
	&:hover {
		cursor: pointer;
	}
	color: #d25252;
    text-align: right;
    width: 30px;
    float: right;
    z-index: 1000;
    font-size: 30px;
	@include absolute(60px, 25px, none, none);
}

.search-results {
	a {
		&:hover {
			color: #7a9fd6;
		}
		color: $white;
		@include margin(0 0 0 10px);
	}
	h2 {
		@include padding(0 0 0 12px);
		font-size: 25px;
		color: $white;
	}
	h3 {
		border-bottom: 1px solid #506380;
		color: #7a9fd6;
		@include padding(0 0 10px 0);
	}
	h4 {
		color: #7a9fd6;
		@include margin(20px 0 0 0);
	}
	ul {
		li {
			list-style-type: none;
			@include padding(0 0 0 0px);
		}
		@include padding(0 0 0 0px);
	}
	z-index: 999;
	background: #1e0354;
	border-top: 1px solid #506380;
	@include padding(0 0 20px 0);
}

#mini-search-results-wrapper {
	background: #3a485c;
	display: none;
}

.search-results-pagination {
	&:hover {
		cursor: pointer;
	}
}

.search-wrapper {
	@include padding(9px 0 0 0);
}

.search {
	input {
		float: Left;
		line-height: 18px;
		@include absolute(0, 0, 0, 0);
		@include padding(0px 2px 0px 10px);
		@include margin(0 0 0 0px);
		@include inputs(100%, 30px);
	}
	width: 100%;
	overflow: hidden;
    height: 30px;
    position: relative;
    left: 10px;
    line-height: 22px;
	float: right;
	@include margin(0 20px 0 0);
}

.search:hover input, .search input:focus {
    @include margin(0 0 0 0px);
}

.table-avatar {
    height: 31px;
    width: 31px;
    border: 2px solid $main-brand-colour;
	border-radius: 50%;
}

// this code switches the navigation to a colour scheme
/*.users-nav {
	i {
		color: $training-blue!important;
	}
	span {
		margin-left: 5px;
	}
}

.dashboard-nav {
	i {
		color: $main-brand-colour!important;
	}
	span {
		margin-left: 5px;
	}
}

.leads-nav {
	i {
		color: $templating-yellow!important;
	}
	span {
		margin-left: 5px;
	}
}

.quotes-nav {
	i {
		color: $training-orange!important;
	}
	span {
		margin-left: 5px;
	}
}

.compliance-nav {
	i {
		color: $action-group-green!important;
	}
	span {
		margin-left: 5px;
	}
}

.orders-nav {
	i {
		color: $training-red!important;
	}
	span {
		margin-left: 5px;
	}
}

.email-templating {
	i {
		color: $templating-yellow!important;
	}
	span {
		margin-left: 5px;
	}
}

.trigger-nav {
	i {
		color: $trigger-orange!important;
	}
	span {
		margin-left: 5px;
	}
}

.actions-nav {
	i {
		color: $action-group-green!important;
	}
	span {
		margin-left: 5px;
	}
}*/

.remarket-dashboard {
/*	&:hover {
		color: $white!important;
	}*/
	border-top: 1px solid #6d7787!important;
/*	background: $main-brand-colour!important;*/
	span {
		margin-left: 5px;
	}
}

.user-nav {
/*	&:hover {
		color: $white!important;
	}*/
	border-top: 1px solid #6d7787!important;
	border-bottom: 1px solid #6d7787!important;
/*	background: $main-brand-colour!important;*/
	span {
		margin-left: 5px;
	}
}

.logout-icon {
	color: #ff0000;
}
.orange-icon {
	color: $trigger-orange;
}
.blue-icon {
	color: $rules-blue;
}
.green-icon {
	color: $action-group-green;
}

.purple-icon {
        color: purple;
}
.cyan-icon {
        color: #38ACEC;
}

.dropdown-nav-btn {
	float:right;
}

.dropdown-menu-btn {
	padding: 10px;
	left: 20px;
}

.dropdown-nav-item {
	border: 0px;
	background-color: #FFFFFF;
	color: #000000
}