.dependant-group-heading {
	@include module-headings($background: $action-group-green, $padding: 5px, $radius: 8px, $color: #fff, $margin-bottom: null);
}

.hidden {
	display: none;
}

.show {
	display: unset;
}

.dependants-create {
  
}

.saved-dependants-wrapper {
		padding: 0px;
		min-height: 80px;
		margin: 0px;
}
        
.dependant-group-ul {
	.action-icon {
		@include flex-center;
		width: 50px;
		height: 100%;
		margin-right: 5px;
		float: left;
		color: $white;
		background: #a8c95c;
	}
	.group-li {
/*		&:hover:after {
			cursor: pointer;
			content: "\A";
			width: 88%;
			height: 100%;
			background: rgba(72, 116, 182, 0.18);
			@include absolute(0, 0, 0, 0);
		}*/
		position: relative;
		border-bottom: 1px solid $light-grey;
		height: 40px;
	} 
	.group-name {
		padding: 0.7% 0;
		font-size: $standard-paragraph;
		height: 50%;
		display: inline-block;
	}
	.group-stats {
		height: 50%;
		font-size: 11px;
		padding: 0.7%;
		background: #f1f1f1;
	}
	padding: 0px;
	margin: 0px;
    list-style-type: none;
}

.active-trigger {
	background: $main-brand-colour!important;
}

.dependant-icon-small {
	@include trigger-icon($background: $rules-blue);	
}

#dependant-container {
    padding: 0px;
	min-height: 80px;
	margin: 0px;
}
	.dependant-container {
		.dependant-icon-small {
			@include trigger-icon;
		}	
		background: $white;
		list-style-type: none;
		width: 100%;
		height: 40px;
		border-bottom: 1px solid $light-grey;
	}
	.depedant-icon {
		@include trigger-icon-main;
	}
	.col-md-10 {
		margin: 0px;
	}
	.dependant {
		float: left;
		padding: $standard-padding;
	}
	


.delete-depedant {
	@include position(absolute, $top: 0, $right: 0);
}

.edit-dependant {
	@include position(absolute, $top: 0, $right: 32px);
}

.delete-dependant {
	i {
		&:hover {
			color: $main-brand-colour;
		}
		color: $red-warning;
	}
	cursor: pointer;
	float: right;
	padding: $standard-padding;
	display: inline-block;
	@include transition(all 0.5s ease);
}

.edit-dependant {
	i {
		&:hover {
			color: $hover-button;
		}
		color: $main-brand-colour;
	}
	cursor: pointer;
	float: right;
	padding: $standard-padding;
	display: inline-block;
	@include transition(all 0.5s ease);
}

.dependant-container {
	position: relative;
	border-bottom: 1px solid $light-grey;
	height: 50px;
}

#action-triggers, #pass-rules, #fail-rules, #action-actions, #schedule-actions {
	display: flex;
	z-index: 0;
	background: #f5f5f5;	
}

.action-group-create, .actions-create {
	input {
		@include inputs(100%, 30px);
	}
	a {
		&:hover {
			background-color: $hover-button;
		}
		white-space: nowrap;
		cursor: pointer;
		@include button-standard($main-brand-colour, $white, 10px, 0px);
		@include transition(background-color 0.5s ease);
	}
	display: flex;
	padding: $standard-padding;
	border-bottom: 1px solid $light-grey;
}

.dependants-create {
	@extend .action-group-create;
}

#rules-edit-container, #rules-saved-container {
	.sales-heading {
		margin-bottom: 0px;
	}
}

.call-event-drop {
	&:hover {
		border: 1px solid $main-brand-colour;
	}
	@include inputs(100%, 30px);
}

#discard-action-options {
	&:hover {
		background: $main-brand-colour;
	}
	background: $red-warning;
	margin-left: 0px;
}

#saved-dependants ul li {
	&:hover {
		background: $light-blue;
	}
	@include transition(background-color 0.5s ease);
}

.config-rule-form {
	input {
		&:last-of-type {
			margin-bottom: 0px;
		}
		margin-bottom: 10px;
	}
	width: 100%;
}

#saved-triggers, #saved-rules, #saved-actions, #saved-email-templates, #saved-sms-templates {
	&:last-of-type {
		border-bottom: none;
	}
}

.dependants-footer {
	height: 10px;
    background: $action-group-green;
	@include border-radius-bottom($border-radius-modules);
}
.disabled {
	&:hover {
		background: #ddd!important;
	}
	background: #ddd!important;
}

.action-edit {
	&__heading {
		@include module-headings($background: $action-group-green!important, $padding: 5px, $radius: 8px, $color: #fff, $margin-bottom: null);
	}
}

// this is the 1st table in 1st column of the rule edit page
.rule-edit, .action-edit {
	&__heading {
		@include module-headings($background: $training-blue, $padding: 5px, $radius: 8px, $color: #fff, $margin-bottom: null);
	}
	button {
		&:hover {
			background: $hover-button;
		}
		@include button-standard($main-brand-colour, #fff, null, null);
	}
	table {
		thead {
			th {
				&:first-of-type {
					text-align: left;
					padding-left: 10px;
					border-radius: 0px;
				}
				&:last-of-type {
					border-radius: 0px;
				}
				color: #333;
				text-align: center;
				@include padding(10px 0px);
			}
		}
		tbody {
			tr {
				td {
					&:first-of-type {
						padding-left: 10px;
						text-align: left;
					}
					text-align: center;
				}
				border-top: 1px solid #ddd;
				height: 30px;
				@include padding(5px 0px 5px 0px);
			}
		}
		margin: 0px;
		font-size: $standard-paragraph;
	}
}

// this is the 2nd table in 2nd column of the rule edit page
.attribute-table {
	label {
		width: 100px;
	}
	input, 
	select {
		@include inputs(100%, 30px);
		margin-left: 0px;
	}
}

.table-edit-container {
	border-bottom: 1px solid #ddd;
}

.action-group-edit {
	label {
		white-space: nowrap;
		margin-right: 10px;
		margin-bottom: 0px;
		font-size: $standard-paragraph;
	}
	select {
		margin-left: 10px;
		width: 50%;
	}
	@extend %flex-x-y-align;
	@extend .action-group-create;
}

.rule-group-edit {
	input {
		margin-right: 10px;
	}
	label {
		white-space: nowrap;
		margin-right: 10px;
		margin-bottom: 0px;
		font-size: $standard-paragraph;
	}
	select {
		margin-left: 0px;
		width: 100%;
	}
	@extend %flex-x-y-align;
	@extend .action-group-create;
}

.master-edit-container {
	@include padding(0px);
}

#actions-edit-container {
	.actions-create {
		@include padding(0px);
	}
}
.config-action-form {
	width: 100%;
}

.back-to-previous {
	margin-left: 10px;
}

.update-success {
	@include notifications;
	background: success(0.8); // this is a function which can be found in _mixins.scss
}

.update-error {
	@include notifications;
	background: error(0.8); // this is a function which can be found in _mixins.scss
}

.module-error {
	@include padding(5px 10px 5px 10px);
	color: #fff;
	font-size: 12px;
	background: error(0.8); // this is a function which can be found in _mixins.scss
}

.cancel-button {
	@include button-standard();
	float: right;
}

.col-footer {
	.cancel-button {
		&:hover {
			@include button-standard($hover-button, $white, 0px, 0px);
			float: right;
		}
		@include button-standard($red-warning, $white, 0px, 0px);
		float: right;
	}
	.save-button {
		&:hover {
			@include button-standard($hover-button, $white, 0px, 0px);
			float: left;
		}
		@include button-standard($main-brand-colour, $white, 0px, 0px);
		float: left;
	}
	padding: $standard-padding;
	height: 50px;
}