.ticker-navbar-top {
	margin-bottom: 14px;
    background: #000000;
    color: #fff;
    padding: 8px 15px;
    border-radius: 10px;
    font-size: 16px;
}

.breaking-news-img, .appointments-due-img {
	border-radius: 5px;
    padding-bottom: 2px;
}

.ticker-anchor-text {
	font-weight: bold;
}

.event-edit table tbody tr td.ticker-visible-checkboxes {
	text-align: left;
}

td.role-visibility.ticker-visibility {
	width: 15%;
}


div.role-selectors.ticker-visibilities {
	padding: 5px 30px;
    width: 200px;
    display: inline-block;
	
	label {
		width: 120px;
	}
}

.event-edit table tbody tr td.ticker-visible-table-header{
	text-align: center;
	font-weight: bold;
}

#saved-tickers-wrapper .group-information.saved-ticker-name {
	width: 390px;
}