@page { margin: 0px; }

html { margin: 0px}

body { margin: 0px; }

.pdf-print-container {
  div.row {
    width:100%;
    margin:0;

    div.col-md-6 {
      width: 50%;
      position: relative;
      min-height: 1px;
      padding-right: 0px;
      padding-left: 0px;
      float: left;
    }
  }
}