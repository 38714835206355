// email related templates
.template-variable-container {
	margin-bottom: 0px;
}

.errors-container {
	padding: 10px;
    text-align: left;
    border-bottom: 1px solid #ddd;
	background: #ffe6e6;
}

#add-cc, #add-bcc {
		&:hover {
			background: $training-green;
			color: #fff;
		}
		@include button-standard($main-brand-colour, #fff, 10px, 0px);
		@include transition;
	}

.template-group-edit {
	button {
		&:hover {
			background: $training-green;
			color: #fff;
		}
		@include button-standard($main-brand-colour, #fff, 10px, 0px);
		@include transition;
	}
	label {
		white-space: nowrap;
		@include margin(0 10px 0px 0);
		font-size: $standard-paragraph;
	}
	select {
		margin-left: 10px;
		width: 50%;
	}
	@extend %flex-x-y-align;
	@extend .action-group-create;
}

.template-editor-container {
	@include margin(0px);
/*	width: 100%;*/
	border-left: 1px solid #ddd;
}

.template-variable-list {
	.btn {
		&:hover {
			color: #fff;
		}
		&:last-of-type {
			margin-bottom: 0px;
		}
		width: 100%;
		height: 26px;
		padding: 4px;
		margin-left: 0px;
		margin-bottom: 10px;
		background: $templating-yellow;
		@extend %push-auto;
	}
	list-style-type: none;
	padding: 10px;
	border:1px solid #ddd;
	margin-bottom: 10px;
	background: #fff;
	@include border-radius-bottom(4px);
}

.all-the-variables {
	@include padding(10px);
    max-height: 571px;
    overflow-y: auto;
}
.contact-variables {
	.panel {
		margin-bottom: 0px!important;
	}
}
.variable-option-heading {
	button {
		&:hover {
			background: $templating-yellow;
		}
		@include button-standard($main-brand-colour, #fff, 0px, 0px);
		width: 100%;
		text-align: center;
		margin-bottom: 10px;
	}
}

.cc-address {
	margin-right: 10px;
}

.template-heading {
	background: $templating-yellow;
	padding: 5px;
	@include border-radius-top($border-radius-modules);
	padding-left: 10px;
	color: $white;
}

.email-body {
	textarea {
		width: 100%;
		max-width: 100%;
		min-height: 300px;
		border-radius: 4px;
	}
	@include padding(10px);
}

.email-footer {
	button {
		&:first-of-type {
			margin-left: 0px;
		}
	}
	@include padding(10px);
	border-top: 1px solid #ddd;
}

.eq-height {
    @media (min-width:$screen-sm-min) {
        display: flex;
        flex-wrap: wrap;
    }
    & > [class*='col-'] {
        background-clip: content-box;
        @media (min-width:$screen-sm-min) {
            display: flex;
            flex-wrap: wrap;
            flex-direction: column; // FF 47-
            -webkit-flex-direction: row; // iOS safari
            -ms-flex-direction: column; // IE11
        }
    }
    &:before,
    &:after {
        @media (min-width:$screen-sm-min) {
            content: normal; // IE doesn't support `initial`
        }
    }
}

//email preview in modal 
.email-screen {
	height: 540px;
    padding: 10px;
    border-radius: 8px;
    box-shadow: rgba(0, 0, 0, 0.5) 0px 0px 15px 4px;
	
	.preview-header {
		text-align: center;
		padding: 5px;
		padding-top: 0px;
		border-bottom: 1px solid #ddd;
		margin-bottom: 5px;
		p {
			margin: 0px;
			font-size: 11px
		}
	}
	.screen-container {
		all: unset;
	}
/*	.screen-header {
		text-align: left;
	}
	
	.screen-header, .preview-header {
		padding: 5px;
		padding-top: 0px;
		border-bottom: 1px solid #ddd;
		margin-bottom: 5px;
	}*/	
}


// sms templating related styles
.phone {
	.ear-phone {
		@include position(absolute, $top: 33px, $right: null, $bottom: null, $left: null);
		width: calc-percent(40px, 271px);
		border-radius: 20%;
		height: 5px;
		border: 1px solid #ddd;
	}
	.screen {
		.screen-header {
			p {
				margin-bottom: 0px;
				font-size: 12px;
			}
			padding: 5px;
			padding-top: 0px;
			border-bottom: 1px solid #ddd;
			text-align: center;
			margin-bottom: 5px;
		}
		.inner-screen {
			&:before {
				content: "\A";
				border-style: solid;
				border-width: 10px 23px 10px 0;
				border-color: transparent #ddd transparent transparent;
				position: absolute;
				left: -9px;
				bottom: -2px;
				@include transform(rotate(-37deg));
			}
			white-space: normal;
			padding: 10px;
			max-width: 70%;
			background: #ddd;
			margin: 10px;
			border-radius: 10px;
			position: relative;
			
		}
		width: 93%;
		height: 75%;
		background: #fff;
		color: #333;
		font-size: 14px;
		z-index: 1;
		padding: 5px;
	}
	.home-button {
		position: absolute;
		bottom: 18px;
		width: 35px;
		border-radius: 50%;
		height: 35px;
		border: 2px solid #ddd;
	}
	position: relative;
	@extend %flex-x-y-align;
	width: 100%;
	height: 540px;
	background: #333;
	border-radius: 30px;
	border: 3px solid #ddd;

	&.modern-iphone{

		position: relative;
		width: 100%;
		height: 630px;
		background: black;
		border-radius: 30px;
		border: 3px solid #ddd;
		box-shadow : 4px 7px 20px -5px rgba(0, 0, 0, 0.32);

		.screen{
			width: 98%;
			height: 98%;
			background: #fff;
			color: #333;
			font-size: 14px;
			z-index: 1;
			padding: 0px;
			overflow: hidden;
			border-radius: 20px;

			iframe{
				border: none;
			}			

			.ear-phone{
				position: absolute;
				top: 2px;
				width: 51.760148%;
				height: 20px;
				background-color: black;
				z-index: 9999999999;
				border-radius: 0px;
				border-bottom-right-radius: 20px;
				border-bottom-left-radius: 20px;
				left: 50%;
				transform: translateX(-50%);
				border: none;
			}
		}

	}
}

.sms-wrapper {
	textarea {
		max-width: 100%;
		min-width: 100%;
		height: 400px;
		@include border-radius(4px);
	}
	padding: 10px;
}

.sms-footer {
	button {
		margin-left: 0px;
		margin-right: 10px;
	}
	padding: 10px;
	margin-top: 10px;
	border-top: 1px solid #ddd;
}

.message-information {
	#char-count {
		width: 20%;
		display: block;
		float: right;
		text-align: right;
	}
	.two-sms {
		width: 50%;
		display: inline-block;
	}
}

.info-group-edit, .sms-group-edit {
	@extend .template-group-edit;
	@include padding (10px 0px 10px 0px);
    border-top: 1px solid #ddd;
    border-bottom: none;
}

#form-email-template {
	width: 100%;
}

.new-contact-form {
	.script-inputs {
		width:50%;
		&#postcode_input{
			width:78%;
		}
	}
	.margin-top-10 {
		margin-top:10px;
	}
	.margin-top-20 {
		margin-top:20px;
	}
	.new-customer-address-select-section {
		.customer-address-selection-row {
			margin-right:0;
		}
		.margin-top-10 {
			margin-top:10px;
		}
	}
	.new-customer-manual-address-section {
		margin-top:16px;
		.row {
			margin-right:0px;
		}
	}
}

#saved-assets-list,
#saved-info-templates,
#saved-info-category-templates,
#saved-sms-templates, 
#saved-email-templates, 
#saved-action-groups, 
#saved-triggers, 
#saved-rules, 
#saved-actions,
.groups-statistics-container {
	@include overflow($height : 600px, $y : auto, $x : hidden);
	.hourly-stats-table {
		 thead {
			background: none;
		 }
	}
}

.info-page-sections {
	padding: 10px;
	border-bottom: 1px solid #ddd;
	border-top: 1px solid #ddd;
	align-items: center;
	justify-content: center;
	display: unset;
}

.info-page-display-block {
	display: block;	
	padding:10px;
	border-bottom: 1px solid #ddd;
	border-top: 1px solid #ddd;
	align-items: center;
	justify-content: center;	
}

.info-category-block {
	border: none;
}

.info-height {
	height: 30px;
	font-size: 12px;
}

#info-meta-textarea {
	height: unset;
	font-size: 12px;
}

.info-page-enable-trigger, .contact-page-optin-trigger, .rss-provider-toggle-trigger, .rss-feed-toggle-trigger, .rss-feed-item-toggle-trigger {
	margin-top: 9px;
	margin-right: 5px;
	float: right;
}

.info-page-display-block.info-groups-container {
	max-height: 300px;
    overflow: hidden;
    overflow-y: auto;
}

div.info-group-input-container {
	font-size: 12px;
	input.info-group-input-checkbox {
		margin: 0px;
		vertical-align: middle;
	}
}

.info-groups-checkbox-container {
	background: #f5f5f5;
	padding: 5px;
	border-radius: 5px;
}

div select#brand_id.form-control.input-sm.search-triggers.brand-selection-template {
	width:100%;
}
.email-input-group-append {
	background-color: #e2e2e2;
	padding: 5px;
	margin-right: -10px;
}

.email-input-prepended {
	width: 60%;
}

.send-email-preview-data-container {
	padding:20px;

	input {
		width:100%;
		height:32px;
	}
}

div.user-signatures-toggle{
	width: 100%;
	label.label-success{
		margin-bottom: 5px;
		margin-left: 20px;
	}
}