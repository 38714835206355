#saved-events-wrapper, #saved-tickers-wrapper, .rss-providers-wrapper, .rss-feeds-wrapper {
	.group-information {
		.group-name {
			height: 100%;
			font-size: 14px;
			@include padding(1.9% 0.7% 1.7% 2%);
		}
		width: 50%;
		float: left;
		height: 100%;
	}
}

.role-visibility {
	border-right: 1px solid #ddd;
}

.role-selectors {
	&:first-of-type {
		margin-top: 5px;
	}
	&:last-of-type {
		margin-bottom: 5px;
	}
	label {
		width: 65px;
		margin-bottom: 0px;
		text-align: left;
	}
	input {
		float: right;
	}
	@include padding(5px 90px 5px 30px);
}

.saved-events-wrapper, #saved-tickers-wrapper {
	//max-height: 600px;
	overflow-y: auto;
}

#saved-events-wrapper, #saved-tickers-wrapper, .rss-providers-wrapper, .rss-feeds-wrapper, .rss-feed-editor-wrapper {
	.sales-heading {
		margin-bottom: 0px;
		background: $navbar-bg;
	}
}

.event-edit {
	.sales-heading {
		margin-bottom: 0px;
		background: $navbar-bg;
	}
	button {
		&:hover {
			background: $hover-button;
		}
		@include button-standard($main-brand-colour, #fff, null, null);
	}
	table {
		thead {
			th {
				&:first-of-type {
					text-align: left;
					padding-left: 10px;
					border-radius: 0px;
				}
				&:last-of-type {
					border-radius: 0px;
				}
				color: #333;
				text-align: center;
				@include padding(10px 0px);
			}
		}
		tbody {
			tr {
				td {
					&:first-of-type {
						padding-left: 10px;
						text-align: left;
					}
					text-align: center;
				}
				border-top: 1px solid #ddd;
				height: 30px;
				@include padding(5px 0px 5px 0px);
			}
		}
		margin: 0px;
		font-size: $standard-paragraph;
	}
}

.event-input {
	margin-right: 0px!important;
}

#saved-events {
	cursor: auto!important;
}

.event-icon-small {
	@include trigger-icon($background: $navbar-bg);
}

label.event-attribute-toggles.active {
    background: #4774b7;
    box-shadow: inset 2px 3px 5px rgba(0, 0, 0, 0.48);
}

.event-action-group-heading{
	.delete-trigger, .edit-trigger{
		padding: 0px 10px;
		i{
			color: white;
		}
	}
	span.header-text:focus{
		padding: 3px 5px;
	}
}

.event-action-group-container{
	max-height: unset;
    padding: 5px 0px 0px 1px;
    background-color: #dddddd;

	.rules-section{
		display: flex;
		min-height: 60px;
		z-index: 0;
		background: #f5f5f5;
		position: relative;
		cursor: move;
		border-bottom: 2px solid #ddd;
		overflow: auto;

		li.rule-container{
			list-style: none;
			background-color: white;
		}

		div.rule{
			padding-right: 0px;
			display: inline-block;
			padding: 10px;
		}

		// div.edit-rule{
		// 	right:0px;
		// }

		.trigger-wrapper{
			min-height:60px;
		}
		
		.trigger-pass{
			max-height: unset;
			padding: 10px;
			background-color: rgba(52, 159, 9, 0.5);
			margin-bottom: 0;
		}
		
		.trigger-fail{
			max-height: unset;
			padding: 10px;
			background-color: rgba(179, 26, 26, 0.5);
			margin-bottom: 0;
		}
	}

	.actions-section {
		display: flex;
		min-height: 60px;
		z-index: 0;
		background: #f5f5f5;
		position: relative;
		cursor: move;
		border-bottom: 2px solid #ddd;
		overflow: auto;

		.action-container{
			position: relative;
			left: 0px;
			top: 0px;
			background: #fff;
			list-style-type: none;
			width: 100%;
			height: 40px;
			border-bottom: 1px solid #ddd;

			div.action{
				max-width: 65%;
				max-height: 100%;
				overflow: hidden;
				padding-right: 0px;
				display: inline-block;
				padding: 10px;
			}
		}

		.trigger-actions{
			max-height: unset;
			padding: 10px;
			background-color: rgba(14, 118, 188, 0.5);
			margin-bottom: 0;
			cursor: pointer;
			transition: background-color 0.5s ease;
			color: #fff;
			margin: 0px;
			display: flex;
			align-items: center;
			justify-content: center;
			display: -webkit-flex;
			flex-wrap: wrap;
			border-right: 1px solid #fff;
			p{
			    font-size: 12px;
				text-align: center;
				margin: 0px;	

				i{
					font-size: 20px;
				}
			}
		}
	}
}

.input-entity {
	margin: 2px;
	padding: 10px;
	background-color: #fbfbfb;
	border: 1px solid #d2d2d2;
	margin-bottom: 10px;
	margin-top: -1px;
	border-bottom: 1px solid #9c9c9c;
}

.input-entity-passed-result {
	background-color: #daffda;
}

.input-entity-failed-result {
	background-color: #ffe2df;
}

.event-passed-entity-collapsed {
	height: 41px;
	margin-top: 3px;
	border-radius: 3px;
	border: 1px solid #d0d0d0;
	-webkit-box-shadow: 0px 0px 5px 0px rgba(185, 185, 185, 0.75);
	-moz-box-shadow: 0px 0px 5px 0px rgba(185, 185, 185, 0.75);
	box-shadow: 0px 0px 5px 0px rgba(185, 185, 185, 0.75);
	padding: 10px;
	cursor: pointer;
}

.header-simulation-info {
	text-align: center;
	border-bottom: 1px solid #b7b7b7;
}

.all-events-list{
	.three-column-actions{
		.delete-trigger{
			display:none;
		}
	}
}

.event-action-group-groups-page{
	.module-inner {
		overflow: visible;
	}
}


.new-event-link-order-check-modal{
	.event-action-group-group-container{
		.three-column-actions{
			display:none;
		}
	}
}

.event-link-conflict-list, .event-link-new-conflict-list{
	border: 1px solid #cacaca;
}
.event-action-group-list{
	list-style: none;
}

.magic-floater{
	position: sticky;
    right: 10px;
    top: 10px;
    max-height: 90vh;
    // overflow-y: auto;
	// overflow-x: hidden;
	
	.three-column-list{
		max-height: 80vh;
		overflow-y: auto;
		overflow-x: hidden;
		@include customScrollBar()
	}
}


.all-events-list, .all-rules-list, .all-action-list{
	.three-column-item{
		cursor: grab;
	}
}

div.event-icon.three-column-icon-holder{
	background-color: lightcoral;
}

div.custom-drag-handle{
	display: inline-block;
	padding: 0px 5px;
	//the below styles are fighting we styles applied to jquery ui classes 
	width:auto;
	min-height: auto;
	border-bottom:none;
	cursor: grab;
}

i.expand-caret{
	float: right;
	padding: 3px;
	cursor: pointer;
}

div[data-toggle='collapse'][aria-expanded='true'] {
	i.expand-caret:before {
		content: "\f0d7";
	}
}

ul.ruleSortable[data-verdict="0"]{
	.rule-icon-small{
		background-color: darkcyan;
		i:before{
			content: "\f00d";
		}
	}
}

i.fa-entity-name-dynamic{
	width: 15px;
	&.contact:before{
		content: "\f007" ;
	}
	&.salesorder:before{
		content: "\f0d6" ;
	}
	&.ticket:before{
		content: "\f145" ;
	}
	&.user:before{
		content: "\f06a" ;
	}
	&.contactdiallerinstance:before{
		content: "\f095" ;
	}
}

.event-action-group-read-only{
	.custom-drag-handle, .edit-trigger, .delete-trigger, .edit-rule, .delete-rule, .edit-action{
		display: none;
	}
	.preview-rule, .preview-action{
		display: inline-block;
		color: #3a475c;
		&:hover{
			color: #4774b7;
		}
	}
}

.preview-rule, .preview-action{
	display:none;
}

.preview-item-row{
	border-bottom: 1px solid gainsboro;
    padding-bottom: 5px;
	margin-bottom: 5px;
	.preivew-label{
		color: #868686;
		text-align: center;
	}
	.preview-value{
		font-weight: bold;
    	color: #4774b7;
	}
}

.header-simulation-info.rules{
    display: grid;
    grid-template-columns: 50px 1fr 50px;
}

div.log-container.rule.event-action-rule-container{
	min-height: 50px;
    display: grid;
	grid-template-columns: 50px 1fr 50px;
	width: 100%;
	padding: 0;
	border-bottom: 1px solid #b7b7b7;
	
	.rule-icon-small.fail-rule{
		background-color: rgba(179, 26, 26, 0.5);
	}

	.actual-result.pass{
		background-color: #5cb85c ;
	}
	.actual-result.fail{
		background-color: #d43f3a ;
	}
	div.rule{
		padding:10px;
	}
}


div.action-container.action-log-container{
	display: grid;
    grid-template-columns: 50px 1fr;
    grid-template-rows: 50px 1fr;
    gap: 1px 1px;
    grid-template-areas:
        ". ."
        "full-width full-width";
    height: auto;
	div.action-name{
		padding:10px;
	}
	div.action-details{
		grid-area: full-width;	
		border-top: 1px solid #b7b7b7;	
	}
}

div.log-simulate-heading{
	padding:10px;
	text-align: center;
}