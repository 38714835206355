/* ----------------------------- TARIFF STYLING ------------------------  */

.tariff-box {
    border: 1px solid $main-brand-colour;
    margin-bottom: 20px;
	border-radius: 8px;
    margin-top: 20px;
    background-color: #fff;
	min-height: 130px;
}

.smart_prepay_available {
	display:none;
}

.tariff-box.selected{
	border-color: $training-green;
}

div.select_tariff_image{
	img{
		width:100px;
	}
}

.tariff-headings {
    color: $main-brand-colour;
    margin-bottom: 10px;
	@include padding(5px 10px 5px 10px);
    margin-top: 0px!important;
    font-size: 14px;
    font-weight: bold;
    line-height: 1.5;
}

.toolbar-dropdowns {
    @include padding(0px);
    margin-left: 10px;
    margin-bottom: 10px;
    background: none;
    float: left;
}

.tariff-bottom-bar {
    background-color: #4774b7;
    height: 35px;
    color: #fff;
    margin-bottom: 10px;
    @include padding(5px 10px 5px 10px);
    margin-top: 0px!important;
    font-size: 18px;
    line-height: 1.5;
}

.tariff-titlebar {
	&:label {
		color: $main-brand-colour;
		font-size: 11px;
	}
	background: $light-blue;
	padding-top: 10px;
	padding-bottom: 10px;
	margin-bottom: 10px;
}

.tariff-footer {
    width: 98%;
    margin: 0 auto;
    margin-top: 10px;
    padding-top: 10px;
    margin-bottom: 10px;
    border-top: 1px solid #e3eaf4;
}

.tariff-info-box {
	margin-top: 20px;
	padding: 20px;
}

.tariff-options {
	width: 14%;
	float: right;
}

.tariff-columns {
	float: left;
	flex-grow: 1;
	margin-bottom: 0px;
}

.seven-cols {
	text-align: center;
	margin: 0 auto;
	display: flex;
}

.seven-cols .col-md-1 p {
	font-size: 14px;
	color: $label;
}

.seven-cols .col-md-1, .seven-cols .col-sm-1, .seven-cols .col-lg-1 {
	flex-grow: 1;
    border-left: 1px solid #e3eaf4;
    text-align: center;
 } 
.toolbar {
	width: unset;//50.25%;
	padding-right: 10px;
}
.fuel-select li a {
	padding: 21px 50px;
	cursor: pointer;
}
.postcode-cell {
	width: 9%;
}
.payment-type-cell, .monthly-spend-cell, .date-modified-cell {
	width: 15%;
}
.tariff-5-pad {
	@include padding(0 9px 0 9px);
	padding-left: 9px;
	padding-right: 9px;
 }

.term-column {
	width: 5%;
}

.col-md-1:first-of-type {
    border-left: none;
}  

/* ----------------------------- TARIFF TABLES ------------------------ */

.table-label {
    color: #404142;
	font-size: 10px;
	vertical-align: middle;
}

.tariff-detail {
	font-size: 11px;
	vertical-align: middle;
}

.fuel-select {
	li {
		a {
			padding: 21px 15px;//58.7px;
			border-top-right-radius: 8px;
			border-top-left-radius: 8px;
			cursor: pointer;
		}
	}
   margin-top: 10px;//20px;
}

.table-divider {
   background-color: $light-blue;
}

.table-divider {
	p {
		padding-left: 0px;
		margin-top: 10px;
	}
	h3 {
		margin-top: 10px;
	}
}

.fuel-label {
   margin-top: 5px;
   margin-right: 10px;
   margin-left: 10px;
}

.tariff-container {
    background-color: #f7f7f7;
    padding: 20px;
    padding-top: 0px;
}

.tariff-type-dual {
    background-image: url("/images/UD/dual-fuel-icon.png");
}

.tariff-type-gas {
    background-image: url("/images/UD/gas-icon.png");
}

.tariff-type-electricity {
    background-image: url("/images/UD/elec-icon.png");
	background-size: cover;
    float: left;
	margin-top: 12px;
    width: 25px;
    height: 25px;
    margin-right: 10px;   
}

.tariff-type-electric {
	background-image: url("/images/UD/elec-icon.png");
}

.tariff-type-dual, .tariff-type-gas, .tariff-type-electric {
    background-size: cover;
    float: left;
    width: 25px;
    height: 25px;
    margin-right: 10px;
}

.multiselect-container {
    height: 200px;  
    overflow-x: hidden;
    overflow-y: scroll;  
}

div.selected-tariff-notice{
	background-color: $training-green;
	color: white;
    font-weight: bold;
    text-align: center;
}

span.expired-tariff-option{
	color:lightgrey;
}

div.ev-usage-container{
    padding: 20px;
    background-color: #e3eaf4;
    border: 1px solid #c1dbff;
    border-radius: 4px;
	margin: 5px;
	
	label.ev-usage-label{
		display: block;
		font-weight: bold;
	}

	div.btn-group{
		width: 100%;

		button.ev-usage-toggle{
			width: 50%;
			padding: 10px;
			&.active{
				background-color: #4774b7;
				color: white;	
			}
		}
	}

	span.ev-usage-message{
		text-align: center;
		width: 100%;
		display: block;
		padding: 5px;

		.sub-message{
			display: block;
		}
	}
}


div.three-cols{
    display: grid;
    grid-template-columns: 70px 1fr 100px;
    grid-template-rows: 1fr;
    gap: 0px 0px;
    text-align: center;
}
