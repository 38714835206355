.search-criteria-panel {
	background-color: #f5f5f5;
	border-radius: 4px;
	border: 1px solid #e3e3e3;
	padding: 5px;

	.form-control {
		height:30px;
	}

	label {
		margin-bottom:0px;
	}

	.select2.select2-container{
		.select2-selection--multiple{
			max-height: 150px;
			overflow-y: scroll;
		}
	}

}
