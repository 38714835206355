#product-groups, 
#product-types, 
#edit-product, 
#products,
#product-value-edit,
#product-variables,
#product-creation-flow {
	.action-group-heading {
		background: $navbar-bg;
	}
	.saved-trigger-wrapper, .saved-trigger-wrapper {
		margin-bottom: 0px;
	}
}

#unallocated-groups, #unallocated-products {
	.team-icon-small {
		background: $unallocated;
	}
}

#unallocated-products, #product-types {
	.delete-trigger {
		display: none;
	}
	edit-trigger {
		margin-right: 5px;
	}
}

.script .product-bundle-tabs {
	cursor:pointer;
	padding-top:5px;
	padding-bottom:5px;
	text-align: center;
	font-weight: bold;
	border: solid 1px black;
	background-color: #f1f1f1;

	span {
		border-radius: 100%;
		padding-left: 4px;
		padding-right: 4px;
		background-color: #c7521a;
		-moz-border-radius: 5em;
		border: 1px solid black;
		color:white;
		transition: background-color 0.5s ease;
	}
}

.script .product-bundle-tabs:nth-child(2) {
	border-left:0;
	border-right:0;
}

.heading {
	p {
		margin-bottom: 0px;
	}
	@include padding(5px 10px 5px 10px);
	background: $light-grey;
	color: $navbar-bg;

	&.variable-group-heading{
		font-weight: bold;
		background: white;
		border-bottom: 1px solid grey;
		cursor: pointer;
	}
}

.title {
	.item-info {
		.title-name {
			color: $main-brand-colour;
			margin-bottom: 0px;
			font-weight: bold;
		}
		.description {
			font-size: 12px;
			margin-bottom: 0px;
		}
		width: 50%;
		float: left;
	}
	.edit-info {
		.created {
			margin-bottom: 5px;
		}
		p {
			margin-bottom: 0px;
		}
		b {
			color: $main-brand-colour;
		}
		text-align: right;
		font-size: 11px;
		width: 50%;
		float: right;
	}
	@include padding(10px);
	border-bottom: 1px solid #ddd;
	display: inline-flex;
	width: 100%;
}
.product-single {
	.product-activate {
		height: 20px;
		margin-top: 9px;
		margin-left: 10px;
		width: 20px;
		float: right;
	}

	.retire-product {
		i {
			color: $nice_red;
		}
	}
	.toggle-all-products{
		&[data-action="enable"]{
			i{
				color:forestgreen;
			}
		}
		
		&[data-action="disable"]{
			i{
				color:darkred;
			}
		}

	}
}

#product-variable {
	input {
		margin-right: 0px;
	}
}

ul.featured-porducts-sortable {
	div.product-activate {
		display:none;
	}
	div.edit-trigger{
		display: none;
	}
	
}

#product-brands, #product-list, #sort-products-by-type {
	.team-drop {
		cursor: initial;
	}

	.team-container {
		transition: color 0.5s ease, background-color 0.5s ease;
		&.active {
			background-color: #808080;
			color: #ffffff;

			.edit-trigger i {
				color: #FFFFFF;
			}
		}
	}
}

#product, #product-creation-variables {
	.product-variables {

		.dynamic {
			font-weight: bold;
			color: #ff3300;
			float: right;
		}

		input {
			width: 85%;
			margin: 0px;
			display: inline-block;
		}

		label {
			text-align: right;
			width: 10%;
			display: inline-block;
		}

		.btn-contact {
			float: left;
			width: 100%;
		}

		.action {
			padding: 0px 0px 0px 10px;
			text-align: center;

			i {
				float: left;
				margin-top: 2px;
			}
		}

		.copy-variable {
			cursor: pointer;
		}

		div.material-switch{
			input{
				width: unset;
				display: none;
			}
			label{
				text-align: left;

			}
		}

		.variable-backdate-section{
			label {
				width: auto;
			}

			div.material-switch{
				display: inline;
			}
		}

		.product-variable-advanced-section{
			padding:10px;
			border-bottom: 1px solid #dddddd;

			hr{
				margin-bottom: 10px;
				margin-top: 5px;
				border-top: 1px solid #dddddd;
			}
		}
	}
}

#sort-products-by-type {
	#teams-container {
		border-bottom: 0px;

		ol, ul {
			margin-bottom: 0px;
		}
	}
}

#product-creation-flow {
	#product-types {

		input {
			width: auto;
			height: auto;
		}
	}
}

#product-view-flow, #product-creation-flow {
	display: none;

	.btn {
		float: left;
		width: 100%;
		margin: 0px;
	}

	.edit-team-options label {
		display: inline-block;
	}
}

#ratecard-import-modal {
	.modal-dialog {
		width: 90%;

		.action-group-create {
			border: 0px;
		}
	}
}


.upload-new-rate-card-trigger{
	float:right;
	cursor: pointer;
}

.regex-input{
	font-family: 'Courier New', monospace;
    font-weight: bold;
}

.regex-text-input{
	font-weight: bold;
	&.pass{
		color:forestgreen;
	}
	&.fail{
		color:darkred;
	}
}

.regex-test-result{
	i{
		font-size: 24px;
		&.pass{
			color:forestgreen;
		}
		&.fail{
			color:darkred;
		}
	}
}

.product-variable-validation-error{
	padding: 10px;
    color: darkred;
    font-weight: bold;
    font-size: 15px;
}

.tab-pane-products {
	padding:10px;
	background-color: #a9ffc4;
	border:1px solid #999999;
	cursor: pointer;
	font-weight: bold;
	margin:0;
}

.tab-pane-products:hover {
	padding:10px;
	background-color: #82c798;
	cursor: pointer;
}

.tab-pane-products.active {
	background-color: #6dc188;
}

.product-option-container {
	.row {
		margin:0;

		li {
			border-bottom:1px solid #9c9c9c;

			.name-container {
				width: 70%;
				display: inline-block;
			}
		}

		li div.name {
			padding-top: 10px;
			padding-left: 10px;
		}
	}
}

#all_products_container {
	li {
		border-bottom:1px solid #9c9c9c;

		.name-container {
			width: 70%;
			display: inline-block;
		}
	}

	li div.name {
		padding-top: 10px;
		padding-left: 10px;
	}
}

.variable-input {
	padding-left:5px;
	border-bottom:1px solid #c9c9c9;

	label {
		font-weight:bold;
		width:35%;
	}

	input {
		width: 62%;
	}
}

#product-bundle-criteria-pane {
	padding-left:5px;
	border-bottom:1px solid #c9c9c9;

	label {
		font-weight:bold;
		width:35%;
	}

	input {
		width: 62%;
	}
}

.broadband-bundle:hover {
	box-shadow: 1px 2px 7px #b8b8b8;
}

.broadband-bundle {
	margin-bottom: 5px;
	.bundle-sub-container {
		padding : 5px;
		.select-bundle {
			float:right;
			margin-top: 10px;
		}
	}

	border: solid 1px #9a9a9a;
	border-top:0;
	box-shadow: 1px 2px 7px #cacaca;
	border-radius: 0 0 4px 4px;

	.row {
		margin: 0;
		.col-md-3, .col-md-4 {
			margin:0;
		}
		.bundle-logo img {
			width:50px;
		}
		.bundle-name {
			padding-top : 14px;
			font-size: 1.3em;
			color:#0a4fb5;
			font-weight: bold;
		}

	}

	.option-selection {
		cursor: pointer;
		text-underline: #454545;
	}
}
.bundle-tab-container {
	.bundle-active-tab {
		background-color: #4774b7;
		color:white;

		span {
			background-color: #ea6d31;
		}
	}
}
.bundle-addon-container {
	.row {
		margin: 0;
	}
	.add-on-product-details {
	}
	.col-md-4, .col-md-6, .col-md-3 {
		margin : 0;
	}

	.col-md-4 {
		padding:0;
	}

	.add-on-actions {
		span {
			color:red;
		}
	}
}

table.bundle-selection-table {
	margin: 0px;

	td {
		padding: 6px;
		text-align: center;
		border: 1px solid #c2c2c2;
		-webkit-transition: all 0.3s ease;
		-moz-transition: all 0.3s ease;
		-o-transition: all 0.3s ease;
		transition: all 0.3s ease;
	}

	td:first-child {
		border-left:0;
	}

	td:last-child {
		border-right:0;
	}

	td.option-selection:hover {
		cursor: pointer;
		background-color: #ddffd6;
		font-weight: bold;
	}

	td.option-selection-active {
		background-color: #d2ffc9;
		font-weight:bold;
	}
}

table.bundle-products-table {
	margin: 0px;

	thead {
		color: white;
		font-size: 14px;
		font-weight: bold;
		background: #4774B7;
		text-align: center;

		td {
			padding-top:5px;
			padding-bottom:5px;
		}
	}

	tbody {
		td {
			text-align: center;
		}
	}
}

.product-bundle-table-row {
	height:40px;
	border-bottom:1px solid #bbbbbb;
}
.broadband-package-details {
	border : solid 2px black;
}

.broadband-package-details.active {
	border : solid 2px red;
}

.upload-new-image-trigger {
	cursor: pointer;
	float: right;
	padding-left: 5px;
}

div.add-on-product-details.selected {
	div.product-selection-banner {
		background-color: #b0ff9e;
	}

	button {
		background-color: #992020;
	}

	button:hover {
		background-color: #7b1818;
	}
}

div.add-on-product-details {
	border:1px solid #cdcdcd;
	box-shadow: 1px 2px 7px #c6c6c6;
	margin:10px;
	cursor: pointer;
	border-radius:4px;

	div.product-selection-title {
		text-align: center;
		font-weight: bold;
		color: #31cd31;
		font-size:20px;
	}

	div.product-selection-banner {
		background-color: #eeeeee;
		text-align: center;
		width:100%;
		padding-bottom: 5px;
		padding-top: 5px;
		border-bottom: 1px solid #c4c4c4;
		font-weight:bold;
	}

	div.add-on-actions {
		text-align: center;

		button {
			margin:0;
			width:100%;
			border-radius:0;
		}

		button:hover {
			background-color: #88aa31;
			color:white;
		}
	}

	div.product-selection-price {
		text-align: center;
	}

	div.product-selection-features {
		text-align: center;
	}
}

div.add-on-product-details:hover {
	border:1px solid #939393;
	box-shadow: 1px 2px 7px #909090;

	div.add-on-actions {
		text-align: center;

		button {
			background-color: #88aa31;
			color:white;
		}
	}
}

div.row.filter-pane {
	background-color: #f1f1f1;
	margin: 0 0 10px;
	box-shadow: 1px 1px 4px 0 #cacaca;
	border: 1px solid #c6c6c6;

	.bundle-filter-tab-container:nth-child(1n) {
		border-right: 1px solid #dddddd;
	}

	.bundle-filter-tab-container:last-child {
		border-right: 0;
	}

	.bundle-filter-tab-container:first-child {
		border-right: 1px solid #dddddd;
	}

	.col-md-3, .col-md-4, .col-md-2 {
		margin:0;
		padding:0;
	}

	.filter-item {
		cursor: pointer;
		transition: background-color 0.5s ease, height 0.2s ease;
		height: 75px;
		padding: 4px;
		text-align: center;

		select {
			margin-top: 10px;
		}

		output {
			margin-top: -6px;
			text-align: center;
		}

		span.range-span {
			height: 13px;
			line-height: 1.5;
		}
	}

	.filter-item.multi-select {
		.select2-container {
			overflow-y: hidden;
			width: 100%;
			height: 60%;
		}

		.select2-container:hover {
			overflow-y: scroll;
			width: 100%;
			height:unset;
			max-height:300px;
			box-shadow: 2px 2px 3px 0 #999999;
			z-index: 500;
		}
	}

	.filter-item:hover {
		background-color: #e0e9f5;
	}
}

.channel-tab-pane {
	margin:0;
	text-align: center;
	font-weight:bold;

	.col-md-3, .col-md-4, .col-md-5 {
		padding:0;
	}

	.channel-tab {
		padding:5px;
		text-align: center;
		cursor: pointer;
		color: #3e6fea;
		display: inline-block;

		span {
			border-radius: 100%;
			padding-left: 4px;
			padding-right: 4px;
			background-color: #c7521a;
			-moz-border-radius: 5em;
			border: 1px solid black;
			color:white;
			transition: background-color 0.5s ease;
			text-decoration: underline;
		}
	}

	.channel-tab:hover {
		text-decoration: underline;
		color: #265ceb;
	}
}

.channel-block {
	display:inline-block;
}

.channels-wrapper {
	.name-container {
		padding-top: 10px;
		padding-left: 8px;
		display: inline-block;
	}

	.script-input {
		border-bottom: 1px solid #b1b1b1;
	}
}

#edit-base-channel {
	.form-group {
		padding:10px;

		input, select {
			width: 100%;
		}
	}
}

.product-features-edit-textarea{
	width:100%;
	min-height: 400px;
}

.broadband-product-selection {
	.row {
		margin:0;
		.broadband-logo {
			img {
				padding-top:20%;
				padding-left:15%;
			}
		}
		.broadband-product-selection {
			button {
				margin-top:25%;
			}
		}
	}
}

.broadband-basket-product-detail {
	.row {
		margin:0;
	}
}

.add-product-stock-container {
	width: 50%;
    margin: auto;
    border: solid 1px;
    padding: 25px;
    border-radius: 15px;

    label {
        display: block;
        margin-bottom: 0.5rem;
        font-weight: bold;
        color: #333;
		text-align: right;
		padding-right: 20px;
    }

    select, .variable-input {
        width: 100%;
        padding: 0.375rem 0.75rem;
        line-height: 1.5;
        border: 1px solid #ced4da;
        border-radius: 0.25rem;
        &:focus {
            outline: none;
            border-color: #80bdff;
            box-shadow: 0 0 0 0.2rem rgba(0,123,255,.25);
        }
    }

    .row {
        margin-bottom: 1rem;
        &:last-child {
            margin-bottom: 0;
        }
    }

    .button-container {
		width: 80%;
		margin: auto;
		margin-top: 24px;
        button {
            width: 100%;
            padding: 0.375rem 0.75rem;
            border: none;
            border-radius: 0.25rem;
            font-size: 1rem;
            line-height: 1.5;
            cursor: pointer;
            &.btn-danger {
                background-color: #dc3545;
                color: white;
                &:hover {
                    background-color: darken(#dc3545, 5%);
                }
            }
            &.btn-success {
                background-color: #28a745;
                color: white;
                &:hover {
                    background-color: darken(#28a745, 5%);
                }
            }
        }
    }
}

.modal-content-wide {
	width:1000px;
}

#stock_allocation_modal_content, #stock_dispatch_modal_content, #stock_return_modal_content {
	.item-to-fulfil, .item-to-dispatch, .item-to-return, .item-to-unallocate {
		border: solid red 1px;
		padding: 20px;
		border-radius: 15px;
		margin: 5px;
		.item-summary-row {
			margin-bottom: 12px;
		}
		.item-input-template  {
			width: 60%;
			margin: auto;
			border: solid 1px;
			padding: 20px;
			border-radius: 15px;
		    margin-bottom: 5px;
			i.validation-passed {
				color:green;
			}
			i.validation-failed {
				color:red
			}
		}
		.dispatch-variable-input-wrapper {
			width: 90%;
			margin: auto;
			border: solid 1px;
			padding: 20px;
			border-radius: 15px;
		    margin-bottom: 5px;
			i.validation-passed {
				color:green;
			}
			i.validation-failed {
				color:red
			}
		}
		.remove-stock-item-inputs-button {
			margin-left:0;
		}
		.error-feedback-section {
			font-size: 2em;
			font-weight: bold;
			color:red;
		}
	}
	.couriers-select-wrapper {
		border: solid 1px;
		padding: 20px;
		border-radius: 15px;
		margin: 5px;
	}
}
.stock-assignment-actions-wrapper {
  margin-top: 20px;
}
#stock_item_history {
	margin:20px;
}
.stock-item-allocation-summary {
  .stock-item-allocation-summary-header,
  .event-history-list-header,
  .profile-header,
  .event-history-add-header {
    background-color: #f8f9fa;
    border-bottom: 2px solid #dee2e6;
    padding: 10px 0;
    font-weight: bold;
  }
  .stock-item-add-event-table {
	.add-event-select select{
		width:100%;
		margin-top: 10px;
		height: 26px;
	}
	.event-history-event-row input {
		width:100%;
		margin-top: 10px;
	}
	.event-history-event-row textarea {
		width: 100%;
	}
	.add-stock-event-button {
		margin: auto;
    	margin-top: 8px;
	}

  }

  .stock-item-allocation-items {
    .stock-item-summary-row {
      border: 1px solid #dee2e6;
      margin-bottom: 5px;
      padding: 10px 0;
      cursor: pointer;
      &:hover {
        background-color: #f2f2f2;
      }
    }

    .stock-item-details {
      padding: 10px;
    //   background-color: #f9f9f9;
      border-top: none;
    }

    .event-history-container,
	.stock-item-add-event-table {
      margin-top: 10px;
	  border: solid 1px;
	  background-color: white;

      .event-history-event-row {
        border-top: 1px solid #dee2e6;
        padding: 5px 0;
      }

      ul {
        padding-left: 20px;
        list-style-type: none;
        li {
          margin: 5px 0;
        }
      }
    }

	.profile-container {
		margin-top: 10px;
	  	border: solid 1px;
		padding:0;
		background-color: white;

		
		.profile-row {
			border-top: 1px solid #dee2e6;
			padding: 5px 0;

			&-key {
				font-weight: bold;
			}
			input {
				width:100%;
			}
		}
	}
  }
}

.payment-summary {
  margin-top: 20px;

  .payment-summary-header {
    background-color: #f8f9fa;
    border-bottom: 2px solid #dee2e6;
    padding: 10px 0;
    font-weight: bold;

    div {
      padding: 0 15px; // Adjust padding as needed for alignment
    }
  }

  .payment-summary-items {
    .payment-summary-row {
      border: 1px solid #dee2e6;
      margin-bottom: 5px;
      padding: 10px 0;
      transition: background-color 0.3s ease;

      &:hover {
        background-color: #f2f2f2;
      }

      &.refund {
        color: red;
      }

      div {
        padding: 0 15px; // Adjust padding as needed for alignment
      }
    }
  }
}

// Replace inline style with class for refund row
.refund {
  color: red;
}

// Apply these styles to your actions column if needed
.payment-actions {
  display: flex;
  align-items: center;
  justify-content: center;

  a {
    margin: 0 5px;
    color: #007bff; // Or any color you prefer for the icons

    &:hover {
      color: darken(#007bff, 10%);
    }

    i.fa-pencil, i.fa-trash {
      cursor: pointer;
    }
  }
}

