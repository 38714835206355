$dark-blue : #3a485c;
$lighter-blue : #4774b7;
$switch_green : #a8ca5c;
$nice_grey : #cccccc;

.script-form{

	div.script-input-container{
		position: relative;
		label.script-input-label{
			width: 20%;
			//text-align: center;
			font-size: 18px;
			margin-top: 10px;
		}

		.script-input{
			width: 75%;
			padding: 5px;
			font-size: 16px;
			margin-top: 5px;
			position: absolute;
			right: 0;
			display: inline-block;
			&.pad-me-please-0{
				padding: 0;
			}
		}

		i.floating-icon{
			position: absolute;
			top: 15px;
			right: 20px;
		}

		div.tooltip-icon-holder{
			display: inline-block;
			text-align: center;
			width: 4%;
			font-size: 18px;
			color: $lighter-blue;
			&:hover{
				div.script-input-tooltip{
					opacity: 1;
					z-index: 99;
				}
			}
	
			div.script-input-tooltip{
				position: absolute;
				z-index: -1;
				background-color: whitesmoke;
				opacity: 0;
				transition-duration: 0.5s;
				color: black;
				top:102%;
				text-align: left;
				border-radius: 15px;
			}
	
		}

		/*address specifics*/

		div.address-block{
			width: 100%;

			div.search-section{
				position: relative;

				input.gbg-address-search{
					width:100%;
					position: relative;
				}

				div.result-item{
					padding: 2px 5px;
					border-radius: 5px;
					margin: 0px 5px;
					text-align: center;

					div.address-result-text{
						background-color: $dark-blue;
						color: white;
						border-top-left-radius: 5px;
						border-bottom-left-radius: 5px;
						text-align: left;
						font-size: 16px;
						padding: 5px 20px;
						width: 80%;
						display: inline-block;

						span.highlight{
							color: $switch_green;
						}
					}
					button.address-result-select{
						width: 19%;
						display: inline-block;
						margin-left: -4px;
						margin-top: -3px;
						padding: 5px;
						font-size: 16px;
						border-radius: 0;
						border-bottom-right-radius: 5px;
						border-top-right-radius: 5px;
						border: 0;
						background-color: $lighter-blue;
					}
				}

				button.cant-find{
					width: 50%;
					margin-left: 23%;
				}

			}

			.address-form{
				button.show-search{
					background-color: white;
					color: $lighter-blue;
					font-size: 16px;
				}

				span.address-heading{
					font-weight: bold;
					float: right;
					margin-right: 10px;
					margin-top: 5px;
				}

				div.address-sub-input{
					.address-input-label{
						width: 20%;
						text-align: center;
					}
					input{
						width:79%;
						padding-left: 10px;
					}
				}
			}

			div.time-at-address{
				p{
					font-size: 14px;
					margin-top: 5px;
					font-weight: bold;
					margin-bottom: 0;
				}
				label{
					width: 10%;
					text-align: center;
				}
				input{
					width: 39%;
					padding-left: 10px;
				}
			}
		}
	}

	/* custom group classes */
	div.form-group.labels-width-40{
		div.script-input-container {
			label.script-input-label {
				width: 40%;
			}

			input.script-input {
				width: 60%;
			}
			div.script-input {
				width: 60%;
			}
		}
	}

	div.form-group.labels-width-60 {
		div.script-input-container {
			label.script-input-label {
				width: 60%;
			}

			input.script-input {
				width: 40%;
			}

			div.script-input {
				width: 40%;
			}
		}
	}

	div.form-group.labels-width-80 {
		div.script-input-container {
			label.script-input-label {
				width: 80%;
			}

			input.script-input {
				width: 20%;
			}

			div.script-input {
				width: 20%;
			}
		}
	}

	div.form-group.labels-width-90 {
		div.script-input-container {
			label.script-input-label {
				width: 90%;
			}

			input.script-input {
				width: 10%;
			}

			div.script-input {
				width: 10%;
			}
		}
	}
}

.select2-container--default.select2-container span.select2-selection--multiple{
	border-color: $nice_grey;
	input{
		border:none;
	}
}

div#all_script_container{
	
	li.script-input{
		display: grid;
		grid-template-columns: 50px 1fr 32px 32px;
		grid-template-rows: 1fr;
		gap: 0px 0px;
		grid-template-areas:
			". . . .";
		border-bottom: 1px solid #4774b7;

		.name{
			font-size: 12px;
			padding: 2px;
			height: 50%;
			font-weight: bold;
			display: block;
			max-width: 280px;
			width: 100%;
			overflow-x: hidden;
			text-overflow: ellipsis;
			overflow-wrap: break-word;
			word-wrap: break-word;
			-ms-word-break: break-all;
			word-break: break-word;
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
			&.sub-name{
				font-size: 12px;
				padding: 2px;
				height: 50%;
				top: 50%;
				margin-left: 5px;
				font-weight: normal;
			}
		}
	}
}

.delete-dependant-record i{
	cursor: pointer;
}

.delete-repeatable-row i {
	cursor: pointer;
}

div.form-group.ledger-container {
	border: 1px solid #b4b4b4;
	padding: 10px;
}

div.form-group.ledger-total-border {
	border-top: 1px solid #b4b4b4;
	padding: 10px;
}

.ledger-select {
	width:400px;
	height:30px;
}

.div.form-group.add-new-ledger-item {
	margin-top: 5px;
}

.ledger-input-container {
	//background-color: #eeeeee;
	border: 1px solid #ccc;
}

.add-new-ledger-item-span {
	cursor: pointer;
	background-color: #ededed;
	border-radius: 10px;
	text-align: center;
	width: 80%;
	height: 105%;
	padding:10px 20px 10px 20px;
	color: #337ab7;
	transition: 1s;
}

.add-new-ledger-item-span:hover {
	background-color: #e9e9e9;
}

.edit-ledger-item-span {
	cursor: pointer;
	background-color: #ededed;
	border-radius: 5px;
	text-align: center;
	width: 80%;
	height: 105%;
	padding: 10px 7px 10px 10px;
	transition: 1s;
	color: #337ab7;
	margin-right: 2px;
}

.edit-ledger-item-span:hover {
	background-color: #e9e9e9;
}

.remove-ledger-item-span {
	cursor: pointer;
	background-color: #ededed;
	border-radius: 5px;
	text-align: center;
	width: 80%;
	height: 105%;
	padding: 10px 7px 10px 10px;
	transition: 1s;
	color: #337ab7;
	margin-right: 2px;
}

.remove-ledger-item-span:hover {
	background-color: #e9e9e9;
}

.ledger-title {
	background-color: #676c73;
	color:white;
}

.ledger-item-row {
	padding-left:10px;
	padding-bottom:6px;
	border-bottom: 1px solid #a2a2a2;
}

div.repeatable.table{
	//border : 1px solid grey;
	select {
		width:100%;
		height:100%;
	}

	.header-row {
		//padding:10px;
		background-color: #eeeeee;
		.header-item {
			display: inline-block;
			padding: 10px 25px 10px 20px;
			border: 1px solid #ccc;
			margin-right: -5px;
		}
	}

	label.script-input-label{
		display:none;
	}

	.script-input{
		width: auto;
		display: inline-block;
	}

	.table-row-container {
		width:100%;
		display: inline-block;

		input {
			height:33px;
		}
	}

	.script-input-container {
		height:100%;
	}

	.repeatable-cell {
		border-right:1px solid #ccc;
		border-left:1px solid #ccc;
	}
}

.repeatable {
	margin-bottom:30px;
}

.repeatable-table-row {
	display: inline-flex;
	width:100%;
	border-bottom:1px solid #ccc;
}

#add-new-repeatable {
	border: 1px dashed #ccc;
	padding-top: 10px;
	//margin-bottom: 10px;
	padding-bottom: 10px;
}

.script-save-button-container {
	text-align:right;
}

.repeatable-table-item {
	width:100%;
	height:100%;
	display: inline-block;
}

/*address specifics*/

div.address-block{
	width: 100%;

	div.search-section{
		position: relative;

		input.gbg-address-search{
			width:100%;
			position: relative;
		}

		div.result-item{
			padding: 2px 5px;
			border-radius: 5px;
			margin: 0px 5px;
			text-align: center;

			div.address-result-text{
				background-color: $dark-blue;
				color: white;
				border-top-left-radius: 5px;
				border-bottom-left-radius: 5px;
				text-align: left;
				font-size: 16px;
				padding: 5px 20px;
				width: 80%;
				display: inline-block;

				span.highlight{
					color: $switch_green;
				}
			}
			button.address-result-select{
				width: 19%;
				display: inline-block;
				margin-left: -4px;
				margin-top: -3px;
				padding: 5px;
				font-size: 16px;
				border-radius: 0;
				border-bottom-right-radius: 5px;
				border-top-right-radius: 5px;
				border: 0;
				background-color: $lighter-blue;
			}
		}

		button.cant-find{
			width: 50%;
			margin-left: 23%;
		}

	}

	.address-form{
		button.show-search{
			background-color: white;
			color: $lighter-blue;
			font-size: 16px;
		}

		span.address-heading{
			font-weight: bold;
			float: right;
			margin-right: 10px;
			margin-top: 5px;
		}

		div.address-sub-input{
			.address-input-label{
				width: 20%;
				text-align: center;
			}
			input{
				width:79%;
				padding-left: 10px;
			}
		}
	}

	div.time-at-address{
		p{
			font-size: 14px;
			margin-top: 5px;
			font-weight: bold;
			margin-bottom: 0;
		}
		label{
			width: 10%;
			text-align: center;
		}
		input{
			width: 39%;
			padding-left: 10px;
		}
	}
}

.customizable-options-inputs {

}

.script-input-textarea {
	min-width:100%;
	max-width:100%;
	min-height:35px;
	max-height:300px;
}

.script-input-container-textarea {
	width: 100%
}

.script-slider {
	-webkit-appearance: none;
	width: 100%;
	height: 15px;
	border-radius: 2px;
	background: #d3d3d3;
	outline: none;
	opacity: 0.7;
	-webkit-transition: .2s;
	transition: opacity .2s;
}

.script-slider::-webkit-slider-thumb {
	-webkit-appearance: none;
	appearance: none;
	width: 20px;
	height: 20px;
	border-radius: 50%;
	background: $nice_blue;
	cursor: pointer;
}

.script-slider::-moz-range-thumb {
	width: 20px;
	height: 20px;
	border-radius: 50%;
	background: $nice_blue;
	cursor: pointer;
}

