.insurance-table {
	h5 {
		padding-left: 5px;
	}
	button {
		&:first-of-type {
			margin-top: 5px;
		}
		margin-left: 6px;
	}
	.insurance-table-header {
		height: 30px;
	}
	font-size:12px; 
	margin-left: 8px;
    width: 98.5%;
	border-spacing: 2px; 
	border-collapse: separate;
}

.example-benefits-table {
	
}

.insurance-table-header {
	tr {
		height: 30px;
	}
}

td[id^="pi-total-"] {
	h4 {
		margin-top: 5px;
	}
}