body, html {
	overflow-x: hidden;
	width:100%;
	height: 100%;
}

body {
	font-family: helvetica;
	background-color: $page-bg;
}

.modal-backdrop.in {
	z-index: 5;
}

.modal-open .modal {
	z-index: 10;
}

.modal {
	z-index: 15;
}

h3 {
	color: $main-brand-colour;
}

#slideout {
	@include absolute(62%, -300px, none, none);
    background: #e3edfb;
	float: right;
    width: 300px;
}
   
#containclickme {
	background: $white;
    float: left;
    height: 80px;
    width: 0px;
}

#support-success {
	@include margin(20px 0 40px 0);
	text-align: center;
}

#clickme {
    float: right;
    height: 40px;
    width: 30px;
    background: #e8873b;
	border-bottom-left-radius: 6px;
	border-top-left-radius: 6px;	
}

#slidecontent {
    float: right;
    width: 100%;
	@include padding(20px 20px 0px 20px);
	border-bottom-left-radius: 6px;
}

.support-header {
	margin-top: 0px!important;
}

.support-icon {
	display: table-cell;
	color: #fff!important;
    vertical-align: middle;
    text-align: center;
}

.error, .error-message, .error-notice {
	color: $red-warning!important;
}

.errors {
	color: #b31a1a !important;
    font-size: 12px;
}

a {
	&:hover, &:focus {
		text-decoration: none;
	}
}

.margin-top-10 {
	@include margin(10px 0 0 0);
}

.margin-top-20 {
	@include margin(20px 0 0 0);
}

.margin-top-bottom-10 {
	@include margin(10px 0 10px 0);
}

.margin-right-20 {
	@include margin(0 20px 0 0);
}

.margin-right-10 {
	@include margin(0 10px 0 0);
}

.modalHeadingGreen .ui-dialog-titlebar {
    background:green;
    color:white;
}

.modalHeadingRed .ui-dialog-titlebar {
    background:red;
    color:white;
}
.modalHeadingRestful .ui-dialog-titlebar {
    background:darkseagreen;
    color:white;
}

#title-error, #firstname-error, #lastname-error, #phone-error, #email-error, #dob-error {
	color: red;
	font-size: 10px;
	text-align: right;
}

/* ----------------------------- LEAD DETAILS PAGE (Form with clients details) ------------------------  */

.form-control {
    height: 20px;
    font-size: 11px;
	box-shadow: none;
	@include padding(1px 1px 1px 5px);
}

input.date-filter  {
	border: 1px solid #eeeeee;
}

input.date-filter.reports  {
	width : 300px;
}

.dropdowns-details {
    border-radius: 4px;
    border: 1px solid #ccc;
	@include margin(0 0 0 5px);
}

.form-group {
	@include margin(0 0 5px 0);
}

// .col-md-3 {
//     background-color: $module-bg;	
// 	margin-left: 1%;
// 	@include padding(0px);
// }

.main-div-borders {
	border: 1px solid $bordergrey;
	border-radius: 10px;
}

.col-md-9 {
	background-color: $module-bg;
	width: 72%;
	float: right;
	@include padding(0px);
	@include margin(0 1% 1% 1%);
}

dashboard-modules {
	@include margin(0 20px 0 1%);
}

.fixed {
	position: fixed;
	width: 25%;
	top: 25px;
}
.scrollit {
	float: right;
	width: 72.5%;
}

.lead-label {
	float: left;
	text-align: right;
	@include margin(0 0 0 25px);
	@include padding(0px 0 0 0);
}

.lead-field {
	width: 55%;
	float: right;
	@include margin(0 25px 0 0);

	.telephone-input-with-call-button{
		width: 80%;
		margin-right: 0;
		display: inline;
		border-top-right-radius: 0;
		border-bottom-right-radius: 0;
		float:left;
	}
	.click-to-call-button{
		width: 20%;
		display: inline;
		margin: 0px;
		margin-top: 0px;
		height: 20px;
		padding: 0px;
		border-top-left-radius: 0;
		border-bottom-left-radius: 0;
	}

}

.address-field {
	width: 90%;
	float: right;
}

.lead-field-supp-info {
	width: 50%;
	float: right;
	@include margin(0 25px 0 0);
}

.form-headings {
	height: 30px;
	color: $white-text;
	background-color: $main-brand-colour;
	@include padding(5px 10px 5px 10px);
	@include border-radius-top(8px);
}

.curved-top-corners {
	@include border-radius-top(4px!important);
}

.form-headings-no-sharp-corners {
	background-color: $main-brand-colour;
	height: 30px;
	color: $white-text;
	border-top-right-radius: 8px;
	@include margin(0 0 10px 0);
	@include padding(5px 10px 5px 10px);
}

.low-priority {
	background-color: $grey;
}

.tarrif-header {
	background-color: $main-brand-colour;
	height: 30px;
	color: $white-text;
	font-weight: bold;
	@include padding(5px 10px 5px 10px);
	@include border-radius-top(8px);
}
//thanks for the typo Mr Whitmore
.tarrif-header.selected{
	background-color: $training-green;
}

.form-headings, .tarrif-header {
	a {
		color: $white-text;
	}
}

legend {
	border-bottom: none;
	font-size: 14px;
	color: $white;
	display: block;
	@include padding(0);
	@include margin(0);
}

.forum-header-buttons {
	&:hover {
		color: $training-green;
	}
    display: block;
    float: right;
    text-align: right;
	font-size: 11px;
	@include padding(3px 0 0 0);
}

.form-horizontal .control-label {
	color: $label;
	font-size: 11px;
	@include padding(2px 0 0 0);
}

#contact-address-select {
	width: 96%;
	height: 25px;
	@extend %push-auto;
	@include margin(2% 2% 2% 2%);
}

.open>.dropdown-menu {
    position: absolute;
    background-color: $white;
}

.fa-1x {
	color: #ffce3e;
}

.fa-1x-red {
	color: $red-warning;
}

.profile-save {
	text-align: right;
	clear: both;
	margin-top:10px;
	@include padding(0 10px 0 0);
	margin-bottom: 10px;
}

.dependants-save {
	text-align: left;
	clear: both;
	@include margin(10px 20px 10px 0);
	@include padding(0 10px 0 25px);
}

#postcode {
  width: 50%;
  display: inline;
//   float: left;
  @include margin(0 10px 0 0);
}

.postcode-field {
	a {
		float: left;
		cursor: pointer;
		font-size: $standard-paragraph;
		@include padding(3px 0 0 0);
	}
}
/* ----------------------------- CLIENT PROFILE ------------------------  */

.dl-horizontal {
	dt {
		text-align: left;
		color: $label;
		font-size: 10px;
		margin-left: 10px;
		padding-top: 2px;
		width: 140px;
	}
	dd {
		font-size: 10px;
		padding-top: 2px;
		margin-left: 90px;
	}
}


/* ----------------------------- CLIENT POLICIES --------------------- */

.contact-policies-group {
    margin-bottom: 15px;
    max-height: 300px;
    overflow-y: auto;
    overflow-x: hidden;
}

.contact-agreed-policies-list {
    margin: 0px;
    padding: 0px;
    list-style-type: none;
    font-size: 11px;
}

.contact-agreed-policies-list-item {
    position: relative;
    border-bottom: 1px solid #ddd;
    height: 35px;
}

.view-trigger {
    cursor: pointer;
    float: right;
    padding: 4px;
    display: inline-block;
    transition: all 0.5s ease;
	margin-right: 5px;
}

.view-trigger .fa {
    font-size: 20px;
    color: #65b6e6;
}

button.policy-close {
	padding: 10px;
	font-size: 28px;
}

.policy-modal-dialog {
	width: 1000px;
}

.modal-policy-body {
	height: 600px;
	width: 100%;
	overflow-y: scroll;
}

.policy-date.view-trigger {
    padding: 8px;
}


/* ----------------------------- QUOTES PAGE ------------------------- */

.order-details {
	font-size: 11px!important;
	margin-top: 10px;
}

.quotes-titlebar {
	background: $light-blue;
	@include padding(5px 0 5px 0);
}

.quotes-titlebar {
	label {
		color: $main-brand-colour;
		font-size: 11px;
	}
}

/*.order-table {
	width: 11%!important;
}*/

.table-buttons {
	margin-top: 4px;
}

.table-buttons a {
	margin-left: 10px;
}

.tariff-box-quote {
    border-bottom: 1px solid #ddd;
    background-color: #fff;
	clear: both;
}

.tariff-box-quote:last-of-type {
	border-bottom: none;
}

.tarrif-header-quote {
	background-color: $training-orange;
	height: 30px;
	color: $white;
	@include padding(5px 10px 5px 10px);
}

.tarrif-header-quote, .tarrif-header-order {
	a {
		color: #fff;
	}
}

.tarrif-header-order {
	background-color: $training-green;
	height: 30px;
	color: #fff;
	@include padding(5px 10px 5px 10px);
}

/* ----------------------------- SUMMARY PAGE ------------------------  */


.summary-page {
	background: none;
	padding-top: 0px;
	padding-bottom: 0px;
}

#new-address {
    overflow: hidden;
	@include padding(10px 0px 10px 0px);
	height: 40px;
	margin-bottom: 10px;
	background: $light-blue;
}

.add-address-module {
	font-size: 10px;
	color: $label;
	margin-top: 3px;
}

.saved-addresses {
	p {
		@include padding(10px);
	}
	border: 1px solid #e8e7e7;
	margin-bottom: 10px;
	@include padding(10px);
	border-radius: 8px;
	border-top-left-radius: 0px;
}

#address-box {
	margin-top: 10px;
	min-height: 100px;
    margin-bottom: 10px;
	@include padding(5px);
}

.address-label {
    text-align: center;
    border: 1px solid $main-brand-colour;
    background: $main-brand-colour;
    color: #fff;
}

#address-list-display {
	margin-left: 10px;
    margin-right: 10px;
}

.other-address-label {
	@include padding(5px 10px 5px 10px);
    text-align: center;
    border: 1px solid $main-brand-colour;
    background: $main-brand-colour;
    color: #fff;
}

.clock {
	float: right;
	text-align: right;
    padding-right: 10px;
	margin-top: 14px;
}

.previous-addresses-footer {
	width: 98%;
    margin: 0 auto;
    margin-top: 10px;
    padding-top: 10px;
    border-top: 3px solid $main-brand-colour;
}

.previous-address-button {
	margin-bottom: 10px;
}

.previous-address-label {
	margin-left: 0px;
	font-size: 11px;
	margin-bottom: 0px;
}

.addresses-field {
	font-size: 11px;
	width: 55%;
    float: right;
    margin-right: 25px;
	line-height: 2.3;
}

.main-nav tooltip {
	position: absolute;
	z-index: 999;
	background:#33b744;
}

.popover1 {
	background: #3A485C;
	width: 100px;
	color: #fff;
	height: 42px;
}

.hidden {
	display: none;
}

#user-ledger-table {
	margin: 0 auto;
}

#user-ledger-table > thead > tr > th {
	width: auto!important;
}

#user-ledger-table_wrapper {
	width: 70%;
	margin: 0 auto;
	background: #fff;
	border-radius: 8px;
	@include padding(20px);
	position: relative;
	top: 40%;
}

#winning {
	display: none; /* Hidden by default */
	position: fixed; /* Stay in place */
	z-index: 1; /* Sit on top */
	left: 0;
	top: 0;
	width: 100%; /* Full width */
	height: 100%; /* Full height */
	overflow: auto; /* Enable scroll if needed */
	background-color: rgb(0,0,0); /* Fallback color */
	background-color: rgba(0,0,0,0.4); /* Black w/ opacity */
}
#winning-content {
	background-color: #fefefe;
	margin: 5% auto; /* 15% from the top and centered */
	@include padding(20px);
	border: 1px solid #888;
	width: 40%; /* Could be more or less, depending on screen size */
}
#user-ledger-pop {
	display: none; 
	position: fixed; 
	z-index: 1; 
	left: 0;
	top: 0;
	width: 100%; 
	height: 100%; 
	overflow: auto; 
	background-color: rgb(0,0,0); 
	background-color: rgba(0,0,0,0.5);
}

#close-ledger {
	color: #d25252;
    text-align: right;
    width: 30px;
    float: right;
	@include absolute(41.9%, 16.4%, none, none);
    z-index: 1000;
    font-size: 30px;
	cursor: pointer;
}

th:first-of-type {
	border-top-left-radius: 8px;
}

th:last-of-type {
	border-top-right-radius: 8px;
}

.client-tabs {
	li {
		@include border-radius-top(4px);
	}
	margin-left: 1px;
}

.no-top-border {
	border-top: none!important;
}
.profile-header {
    text-align: center;
    margin-bottom: 40px;
	@include padding(20px);
    border-bottom: 1px solid #ddd;
	background-color: #e3eaf4;
}

.profile-header img {
	border: 5px solid #ddd;
}

.profile-header {
	h2 {
		color: $main-brand-colour;
	}
	h4 {
		color: #3a485c;
		font-style: italic;
	}
}

.contact-id-display {
	width: 40%;
    text-align: right;
    float: right;
    margin-right: 5px;
}

#dashboard-ledger {
	cursor: pointer;
}

.rankGlobalButton, .rankTeamButton {
	margin: 5px;
	padding-top: 10px;
	padding-bottom: 10px;
	cursor: pointer;
}

.rankGlobalButton, .rankTeamButton {
	&:active {
		color: $main-brand-colour;
	}
}

.all-good {
	background: $training-green;
	border: none;
	border-radius: 4px;
	color: #fff;
}

.no-cigar {
	background: $training-orange;
	border: none;
	border-radius: 4px;
	color: #fff;
}

.no-cigar label, .all-good label {
	color: #fff;
}

#contact-events-table td, contact-notes-table td {
	font-size:13px;
}

#contact-events-table_length, #contact-notes-table_length  {
	display: none;
}

#contact-events-table th, #contact-notes-table th {
	@include border-radius-bottom(0px);	
}

#contact-events-table, #contact-notes-table {
	@include border-radius-bottom(0px);
}

#contact-events-table_paginate, #contact-notes-table_paginate {
	margin: 5px;
	margin-right: 10px;

}

.pagination > li > a, .pagination > li > span {
	padding: 3px 6px;
}

.dataTables_info {
	font-size: $standard-paragraph;
	padding-top: 15px!important;
}

/*.todo-input {
	height: 30px!important;
}*/

.profile-quick-links {
	.ticket-task {
		background: #e26161;
		border-radius: 3px;
		padding: 2px;
		width:100%;
		white-space: pre-wrap;
	}
}

.todo-list {
	input {
		margin-right: 10px;
		@include inputs(100%, 30px);
	}
	button {
		margin-left: 0px;
	}
	@include padding(10px);
	height: 52px;
	border-bottom: 1px solid #ddd;
	background: $light-blue;
	@include flex-center;
}

.todo-note {
	ul {
		&:first-of-type {
			border-top: none;
		}
	}
	width: 100%;
	margin: 0 auto;
	padding: 0px;
}

.todo-individual {
	@include padding(10px 10px 0px 10px);
	font-weight: bold;
	border-top: 1px solid #ddd;
	margin-bottom: 0px;
	width: 100%;
	margin: 0 auto;
}

.todo-individual {
	ul {
		color: #3a485c;
		white-space: pre-wrap
	}
}

.profile-quick-links div.todo-individual-notification {
	.notification-item {
		margin: 5px;
		border: 2px solid #fff;
		border-radius: 5px;
		width: 95%
	}
}

div.todo-individual-notification{
	@include padding(10px 0px 0px 0px);
	font-weight: bold;
	border-top: 1px solid #ddd;
	margin-bottom: 0px;
	width: 100%;
	margin: 0 auto;
	
	ul.notification-title {
		padding: 3px;
		color: orange;
		a {
			color: orange;
			white-space: pre-wrap;
		}
		button {
			margin-top: 1px;
			margin-right: 15px;
			color: white;
			opacity: 1;
			text-shadow: none;
		}
	}
	.notification-body {
		padding: 0px 5px;
	}
}

.task-complete {
	color: #ddd;
}

.notify-date {
	color: red;
	font-size: 11px;
}

.delete-user-task {
	margin-right: 10px;
}

.report-task{
	a{
		color: white;
		text-decoration: none;
		white-space: normal;
	}
}

.file-view-individual {
	@include padding(10px);
	border-top: 1px solid #ddd;
	margin-bottom: 0px;
}

.upload-module {
	@include padding(10px);
	height: 52px;
	@include border-radius-bottom(8px);
	border-bottom: 1px solid #ddd;
	background: $light-blue;
}
.sender-text {
	float: right !important;
	height: 30px;
	font-size: $standard-paragraph;
	padding-top: 2px;
}
.module-footer {
	background: #4774b7;
	height: 30px;
	border-bottom-left-radius: 8px;
	border-bottom-right-radius: 8px;
}

/* CSS3 ANIMATION CODE For tasks icon notification flash */	
@-webkit-keyframes pulsate {
    0%   { background-color: #ef7d24; }
    50%  { background: #a8ca5c; }
    100% { background: #ef7d24; }
}

.notification-contact, .notification-count {
	display: inline;
    font-size: 1.5em;
    animation: pulsate 2s infinite;
    padding: 2px 4px;
    border-radius: 15px;
	position: relative;
    top: -2px;
}

.notification-events {
	display: inline;
    font-size: 9px;
	background: #ef7d24;
    padding: 2px 4px;
    border-radius: 15px;
	position: relative;
    top: -2px;
}

.nav-profile {
	ul { 
		li {
			float: left;
			list-style-type: none;
		}
		padding: 0px;
	}
	margin-top: 8px;
}

#stuff {
    width: 200px;
    z-index: 11;
    border-radius: 8px;
	background: rgba(58, 72, 92, 0.8);
	@include absolute-left-top(50px, 23px);
	@include padding(10px);
	@include transition(all 500ms ease-in-out);
}

.menu_class {
    border:1px solid #1c1c1c;
}

.file_menu {
	li {
		a {
			&:hover {
				padding: 10px;
				font-weight: bold;
				color: $training-green;
			}
			color: #fff; 
			text-decoration: none; 
			padding: 10px;
			font-size: 10px;
			display: block;
		}
		background: none;
		width: 100%;
	}
	div {
		&:first-of-type {
			border: none;
		}
		ul {
			color: #fff;
			font-size: $standard-paragraph;	
		}
		color: #fff;
		text-decoration: none; 
		font-size: 10px;
		display: block;
	}
	position: absolute;
    display: none;
	width: 220px;
    left: 177px;
    padding-top: 15px!important;
	z-index: 11;
	border-radius: 8px;
	background: rgba(58, 72, 92, 0.95);
}

.details-saved {
	width: 50%;
	font-style: italic;
	font-size: 11px;
    display: inline-block;
}

.new-contact-form {
	input, select {
		@include margin(0px);
	}
	@include margin(20px 0 0 0);
}

body::-webkit-scrollbar {
    width: 0.5em;
}
 
body::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
}
 
body::-webkit-scrollbar-thumb {
  background-color: $main-brand-colour;
  outline: 1px solid slategrey;
}

li.db-compare{
	list-style-type: none;
    width: 100%;
    height: 40px;
    border-bottom: 1px solid #ddd;
	color: white;
	cursor: default;
	
	div.view-action{
		cursor: pointer;
		float: right;
		padding: 10px;
		display: inline-block;
		transition: all 0.5s ease;
	}
}

li.db-compare.new{
	background-color: #d9534f;
}

li.db-compare.modified {
	background-color: #d28a06;
}

li.db-compare.good {
	background-color: #a8ca5c;
}

div.script-group-link-drop-zone#pass-rules:after{
	content:'Drop Script Steps Here';
}

div.no-drop#pass-rules:after{
	content : '';
}

ul.saved-trigger-wrapper.reportSortable.ui-sortable#script_group_steps_sort > li.trigger-container.ui-sortable-handle.ghost-container{
	border: 1px dashed lightgray;
	color : lightgray;
	height: 160px !important
}

div.rejection_buttons {
	display: inline;
}

#meter_search_container {
	padding:20px;
}