.found-fallback-container {
  margin-left:15px;
  margin-right:15px;
  margin-bottom:15px;

  select {
    height: 30px;
    margin-top: -1px;
  }

  input {
    height: 30px;
  }
}