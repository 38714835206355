/* ----------------------------- MIXINS ------------------------  */

@mixin border-radius-bottom($radius) {
	border-bottom-left-radius:	$radius;
	border-bottom-right-radius: $radius;
}

@mixin border-radius-top($radius) {
	border-top-left-radius:		$radius;
	border-top-right-radius:	$radius;
}

@mixin border-radius($radius : 8px) {
	border-radius:		$radius;
}

/* ----------------------------- POSITIONING ------------------------ */

@mixin absolutecenter($axis: "both"){
	position:absolute;
	@if $axis == "y"{
		top: 50%;
		transform: translateY(-50%);
	}
	@if $axis == "x"{
		left: 50%;
		transform: translateX(-50%);
	}
	@if $axis == "both"{
		top:50%;
		left:50%;
		transform: translate(-50%,-50%);
	}
}

@mixin position($type, $top: null, $right: null, $bottom: null, $left: null) {
    position:	$type;
    top:		$top;
    right:		$right;
    bottom:		$bottom;
    left:		$left;
}

	// use the above mixin, its better!!!
	@mixin absolute($top, $right, $bottom, $left) {
		position:	absolute;
			top:	$top;
			right:	$right;
			bottom: $bottom;
			left:	$left;
	}

	@mixin absolute-left-top($top, $left) {
		position:	absolute;
		top:		$top;
		left:		$left;
	}
	
@mixin flex-center($display : flex, $align : center, $justify : center) {
	display:		$display;
	align-items:	$align;
	justify-content:$justify;
	display:		-webkit-box;
	display:		-moz-box;
	display:		-ms-flexbox;
	display:		-webkit-flex;
}

@mixin center-both {
	@include position(absolute, $top: 50%, $left: 50%);
}

@mixin center-block {
	display:		block;
	margin-left:	auto;
	margin-right:	auto;
}

@mixin padding($padding) {
	padding:	$padding;
}

@mixin margin($margin) {
	margin:		$margin;
}

@mixin overflow($height : 600px, $y : auto, $x : hidden) {
	max-height: $height;
    overflow-y: $y;
	overflow-x: $x;
}

/* ----------------------------- VENDOR PREFIX MIXIN ------------------------ */

@mixin prefix($property, $value, $vendors: webkit moz ms o) {
	@if $vendors {
	  @each $vendor in $vendors {
		#{"-" + $vendor + "-" + $property}: #{$value};
	  }
	}
	#{$property}: #{$value};
}

/* ----------------------------- OTHER MIXINS ------------------------ */

// error and success at bottom of the page
@mixin notifications {
	h3 {
		color: #fff;
		font-size: 18px;
	}
    width: 100%;
	font-size: 18px;
    padding-top: 15px;
	padding-bottom: 10px;
    padding-left: 4%;
    color: #fff;
    z-index: 1;
	@include position(fixed, $bottom: 0, $left: 0);
}

@mixin trigger-icon-main($hoverbackground : $action-group-green, $background : $navbar-bg) {
	&:hover {
		background: $hoverbackground!important;
		border-right: 1px solid #fff;
	}
	p {
		font-size: $standard-paragraph;
		text-align: center;
		margin: 0px;
	}
	i {
		font-size: 20px;
	}
	cursor: pointer;
	@include transition(background-color 0.5s ease);
	color: $white;
	margin: 0px;
	@include flex-center;
	flex-wrap: wrap;
	font-size: 30px;
	border-right: 1px solid #fff;
	background: $background;
}

@mixin trigger-icon($background: $trigger-orange) {
	color: $white;
	@include flex-center;
	width: 50px;
	height: 100%;
	background: $background;
	float: left;
}

@mixin dashboard-modules($color, $bot-margin) {
	background:		$color;
	padding:		5px;
	@include border-radius-top(8px);
	padding-left:	10px;
	color:			#fff;
	margin-bottom:	$bot-margin;
}

@mixin button-standard($background : $main-brand-colour, $color : #fff, $l-margin : null, $r-margin : null) {
	padding:			8px;
    // margin-left:		$l-margin;
    background:			$background;
    border:				none;
    border-radius:		4px;
    color:				$color;
    margin-right:		$r-margin;
    height:				30px;
    border-radius:		4px;
    line-height:		1.2;
    float:				right;
    font-size:			12px;
	@include transition(background-color 0.5s ease);
}

@mixin full-screen-image($url) {
	background-image: $url; 
		-webkit-background-size:	cover;
		-moz-background-size:		cover;
		-o-background-size:			cover;
		background-size:			cover;
}

@mixin inputs($width, $height) {
	width:				$width;
	height:				$height;
	border-radius:		$border-radius-inputs;
	border:				1px solid #ccc;
	font-size:			$standard-paragraph;
}

@mixin gradient($start-color, $end-color, $orientation) { /// #07c, #06f, vertical
	background: $start-color;
	@if $orientation == 'vertical' {
		background: -webkit-linear-gradient(top,					$start-color, $end-color);
		background: linear-gradient(to bottom,						$start-color, $end-color);
	} @else if $orientation == 'horizontal' {
		background: -webkit-linear-gradient(left,					$start-color, $end-color);
		background: linear-gradient(to right,						$start-color, $end-color);
	} @else {
		background: -webkit-radial-gradient(center, ellipse cover,	$start-color, $end-color);
		background: radial-gradient(ellipse at center,				$start-color, $end-color);
	}
}

@mixin customScrollBar {
	&::-webkit-scrollbar {
		width: 0.5em;
	}
	
	&::-webkit-scrollbar-track {
		-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
		box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
	}
	
	&::-webkit-scrollbar-thumb {
		background-color: $main-brand-colour;
		outline: 1px solid slategrey;
	}

}

/* ----------------------------- ANIMATION RELATED MIXINS ------------------------ */

@mixin transition($args: background-color 0.5s ease) { /// background-color 0.5s ease
  -webkit-transition:	$args;
  -moz-transition:		$args;
  -ms-transition:		$args;
  -o-transition:		$args;
  transition:			$args;
}

@mixin transform($arg) { /// scale(1), rotate(-45deg
	-webkit-transform:	$arg;
	-moz-transform:		$arg;
	-o-transform:		$arg;
	-ms-transform:		$arg;
	transform:			$arg;
}

@mixin animation($arg) { /// spin 0.8s linear infinite
	-webkit-animation:	$arg;
    animation:			$arg;
}

@mixin opacity($opacity) {
	opacity: $opacity;
	$opacity-ie: $opacity * 100;
	filter: alpha(opacity = $opacity-ie); //IE8
}

@mixin dropzone-after-content($content : "Drop Pass Rules Here", $width : 100%, $padding-left : 15%, $font-size : 20px) {
	content: $content;
    width: $width;
    padding-left: $padding-left;
	z-index: -1;
    color: $light-grey;
    font-size: $font-size;
    text-align: center;
	@include absolute(32%, 0, 0, 0);
}

/* ----------------------------- TYPOGRAPHY MIXINS ------------------------ */

@mixin font-size($size) {
  @if unitless($size) {
    $size: $size * 1px;
  }
  font-size: $size;
  font-size: ($size / $base-font-size) * 1rem;
}

@mixin module-headings($background: $training-blue, $padding: 5px, $radius: 8px, $color: #fff, $margin-bottom: null) {
	background: $background;
    padding: $padding;
	@include border-radius-top($radius);
    padding-left: 10px;
    color: $color;
    margin-bottom: $margin-bottom;
}

/* ----------------------------- PLACEHOLDERS ------------------------ */

%flex-x-y-align {
	display:			flex;
	align-items:		center;
	justify-content:	center;
}

%push-auto {
    margin: { 
        left:	auto;
        right:	auto;
    }
}

%clearfix {
  *zoom:	1;
  &:before, &:after {
    content:	" ";
    display:	table;
  }
  &:after {
    clear:		both;
  }
}

%text-justify {
    text-align:	justify;
}



/* ----------------------------- FUNCTIONS ------------------------ */

@function black($opacity) {
    @return rgba(0, 0, 0, $opacity);
}

@function success($opacity) {
    @return rgba(0, 179, 60, $opacity);
}

@function error($opacity) {
    @return rgba(179, 0, 0, $opacity);
}

@function white($opacity) {
    @return rgba(255, 255, 255, $opacity);
}

@function make-greener($value) { // add a slight green tint to whatever $value you add in
    @return $value + rgb(0,50,0); // arithmetic with colors is totally fine, btw
}

@function calc-percent($target, $container) {
	@return ($target / $container) * 100%;
}



