.horizontal-dots-loader {
	width: 350px;
	margin: auto;
	background: white;
	margin-top: 10.5%;
	padding-bottom:  10px;
	border: 1px #e6e6e6 solid;
	border-radius:  8px;
}

.horizontal-dots-loader-logo {
	text-align:  center;
	padding-top: 9%;
}

@keyframes refine-loader-dot-keyframes {
	0% {
	  opacity: .4;
	  transform: scale(1, 1);
	}

	50% {
	  opacity: 1;
	  transform: scale(1.2, 1.2);
	}

	100% {
	  opacity: .4;
	  transform: scale(1, 1);
	}	
}

div.horizontal-dots .loaderAnimationInfinite {
	animation-iteration-count: infinite;
}

div.horizontal-dots .loaderAnimationOnce {
	animation-iteration-count: 1;
}


.horizontal-dots {
	text-align:  center;
	margin-top: 0px;
	font-size: 21px;
	width:100%;
  
  &--dot {
    animation: refine-loader-dot-keyframes 1.5s ease-in-out;
    background-color: #0079c1;
    border-radius: 10px;
    display: inline-block;
    height: 10px;
    width: 10px;
	margin: 0px 2px;
    
    &:nth-child(2) {
      animation-delay: .2s;
    }
    &:nth-child(3) {
      animation-delay: .3s;
    }
    &:nth-child(4) {
      animation-delay: .4s;
    }
    &:nth-child(5) {
      animation-delay: .5s;
    }
  }
}

.horizontal-dots-loader-text-container{
	text-align:center;
	margin-top:-2px;
	
	p.horizontal-dots-loader-text {
		font-size: 12px;
	}
}

/*refine quote page3 loader ends*/

#loader-container {
	width: 100%;
    height: 100%;
    //background-color: rgba(255, 255, 255, 0.69);
	background-color: rgba(0, 0, 0, 0.25);
    position: fixed;
    z-index: 20;
}