li.team-container.consent-type-single.active {
  background-color: #808080;
  color: #ffffff;
}

li.team-container.consent-policy-single.active {
  background-color: #808080;
  color: #ffffff;
}

#types-list {
  transition: 0.5s;
}

#policies-list {
  .col-md-3 {
      margin-left: 0;
  }

  transition: 0.5s;
}

.policy-edit-textarea {
  width:100%;
  min-width:100%;
  min-height:50vh;
}

.policy-edit-input {
  width:100%;
  height:30px;
}

del {
  background-color: #ff7e7e;
}

ins {
  background-color: #80ff80;
}