#viewport.slot-game
{
	background-color:#cdcdcd;
	background-image:-moz-linear-gradient(#bbb,#eee,#bbb);
	background-image:-webkit-linear-gradient(#bbb,#eee,#bbb);
	overflow:hidden;
	width: 100%;
	height: 100%;
	display:block;
	min-height:500px;

	.outcome-message{
		font-size: 18px;
		text-align: center;
		font-weight: bold;
		color: #0a5775;
		margin-top: 15px;
	}
	
	#container
	{
		position:absolute;
		width: 320px;
		height: 340px;
		left: 50%;
		top: 50%;
		margin-left: -160px;
		margin-top: -160px;
	}
	#container h1
	{

		font-size: large;
		text-align: center;
		color: white;
	} 
	#container #header
	{
		text-align: center;
	}
	#container #header h1
	{
		text-align: center;
		font: 26px Slackey;
		text-shadow:#000 0px 0px 1px , #000 0px 0px 1px;
	}
	#container #header h3
	{
		color: #555;
		padding-bottom: 10px;
		text-shadow:rgba(255,255,255,0.6) 0px 1px 0px;
	}

	#container #buttons
	{
		margin-top:3px;
	}

	#container #buttons #play 
	{
		font-family: 'Slackey'; 
		text-align: center;
		height: 40px;
		line-height: 40px;
		font-size: 24px;
		border-radius:10px;
		background-size:auto 62px;
	}

	#container #reels
	{
		display:block;
		position:relative;
		height: 180px;
		overflow: hidden;
		text-align: center;
		background:#000;
		border: 10px solid;
		border-color: #1941b2;
		border-radius:3px;
		-moz-box-shadow:#fff 0px 1px 0px;
	}
	#container #reels canvas
	{
		width: 87px;
		height: 300px;
		position: relative;
		background: white;
		border: 2px solid gold;
	}

	#container #overlay 
	{
		width: 100%;
		height: 100%;
		position: absolute;
		top: 0px;
		opacity: 0.3;
		background-image: -moz-linear-gradient(#555,#fff,#fff,#555);
		background-image: -webkit-gradient(linear, 0 25%, 0 100%, from(grey), color-stop(0.5, white), to(grey));
		display: none;
	}
	#container #overlay #winline 
	{	
		width: 100%;
		height: 5px;
		background: red;	
		position: relative;
		top: 50%;
	}
	#reels #results
	{
		display: none;
		background: white;
		border: 3px solid gold;
		height: 120px;
		width: 250px;
		left: 20px;
		top: 15px;
		position: absolute;
		font-size: x-large;
	}

	#results #score {
		margin: 5px;
	}

	#results #score img {
		vertical-align: middle;
	}


	.button
	{
		position:relative;
		display:block;
		text-align: center;
		color: #fff;
		box-shadow: inset rgba(255,255,255,0.15) 0px 1px 0px;
		border: 1px solid rgba(0,0,0,0.15);
		background-image: -moz-linear-gradient(rgba(255,255,255,0.15),rgba(0,0,0,0.15));
		background-image:-webkit-gradient(linear, left top, left bottom, from(rgba(255,255,255,0.15)), to(rgba(0,0,0,0.15)));
		background-position: 0px -1px;
		text-shadow:#000 0px 0px 1px;
		text-decoration: none;
	}
	.button.button-default
	{
		background-image:-moz-linear-gradient(#77e0fb,#3f93ac);
		background-image:-webkit-gradient(linear, left top, left bottom, from(#77e0fb), to(#3f93ac));
	}
	.button.button-default:hover
	{
		background-image:-moz-linear-gradient(#ffd539,#af861e);
		background-image:-webkit-gradient(linear, left top, left bottom, from(#ffd539), to(#af861e));
		cursor:pointer;
	}
	.button.button-default:active
	{
		background-image:-moz-linear-gradient(#ffb133,#b3671a);
		background-image:-webkit-gradient(linear, left top, left bottom, from(#ffb133), to(#b3671a));
	}
	html, body, div, span, applet, object, iframe,
	h1, h2, h3, h4, h5, h6, p, blockquote, pre,
	a, abbr, acronym, address, big, cite, code,
	del, dfn, em, font, img, ins, kbd, q, s, samp,
	small, strike, strong, sub, sup, tt, var,
	dl, dt, dd, ol, ul, li,
	fieldset, form, label, legend,
	table, caption, tbody, tfoot, thead, tr, th, td {
		margin: 0;
		padding: 0;
		border: 0;
		outline: 0;
		font-weight: inherit;
		font-style: inherit;
		font-size: 100%;
		font-family: inherit;
		vertical-align: baseline;
	}
	/* remember to define focus styles! */
	:focus {
		outline: 0;
	}
	body {
		line-height: 1;
		color: black;
		background: white;
		font-family:Lucida Grande,trebuchet ms,verdana,arial,helvetica,sans-serif;
	}
	ol, ul {
		list-style: none;
	}
	/* tables still need 'cellspacing="0"' in the markup */
	table {
		border-collapse: separate;
		border-spacing: 0;
	}
	caption, th, td {
		text-align: left;
		font-weight: normal;
	}
	blockquote:before, blockquote:after,
	q:before, q:after {
		content: "";
	}
	blockquote, q {
		quotes: "" "";
	}
}


$particles: 50;
$width: 500;
$height: 500;

// Create the explosion...
$box-shadow: ();
$box-shadow2: ();
@for $i from 0 through $particles {
  $box-shadow: $box-shadow,
               random($width)-$width / 2 + px
               random($height)-$height / 1.2 + px
               hsl(random(360), 100, 50);
  $box-shadow2: $box-shadow2, 0 0 #fff
}
@mixin keyframes ($animationName) {
    @-webkit-keyframes #{$animationName} {
        @content;
    }

    @-moz-keyframes #{$animationName} {
        @content;
    }

    @-o-keyframes #{$animationName} {
        @content;
    }

    @-ms-keyframes #{$animationName} {
        @content;
    }

    @keyframes #{$animationName} {
        @content;
    }
}

@mixin animation-delay ($settings) {
    -moz-animation-delay: $settings;
    -webkit-animation-delay: $settings;
    -o-animation-delay: $settings;
    -ms-animation-delay: $settings;
    animation-delay: $settings;
}

@mixin animation-duration ($settings) {
    -moz-animation-duration: $settings;
    -webkit-animation-duration: $settings;
    -o-animation-duration: $settings;
    -ms-animation-duration: $settings;
    animation-duration: $settings;
}

@mixin animation ($settings) {
    -moz-animation: $settings;
    -webkit-animation: $settings;
    -o-animation: $settings;
    -ms-animation: $settings;
    animation: $settings;
}

@mixin transform ($settings) {
    transform: $settings;
    -moz-transform: $settings;
    -webkit-transform: $settings;
    -o-transform: $settings;
    -ms-transform: $settings;
}


.pyro > .before, .pyro > .after {
  position: absolute;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  box-shadow: $box-shadow2;
  @include animation((1s bang ease-out infinite backwards, 1s gravity ease-in infinite backwards, 5s position linear infinite backwards));
}
    
.pyro > .after {
  @include animation-delay((1.25s, 1.25s, 1.25s));
  @include animation-duration((1.25s, 1.25s, 6.25s));
}
        
@include keyframes(bang) {
  to {
    box-shadow:$box-shadow;
  }
}
    
@include keyframes(gravity)  {
  to {
    @include transform(translateY(200px));
    opacity: 0;
  }
}
    
@include keyframes(position) {
  0%, 19.9% {
    margin-top: 10%;
    margin-left: 40%;
  }
  20%, 39.9% {
    margin-top: 40%;
    margin-left: 30%;
  }
  40%, 59.9% {  
    margin-top: 20%;
    margin-left: 70%
  }
  60%, 79.9% {  
    margin-top: 30%;
    margin-left: 20%;
  }
  80%, 99.9% {  
    margin-top: 30%;
    margin-left: 80%;
  }
}

