$background-color: pink; // usused test variable
$modal-lg: 1080px;

// bootstrap variables
$icon-font-path: "/fonts/";

// font-awesome variables
$fa-font-path: "/fonts/";

/* -----------------------------VARIABLES (COLORS)------------------------  */

$main-brand-colour: #4774b7;
$navbar-bg: #1e0354;
$navbar-border: #506380;
$module-bg: #fff;
$page-bg: #fff;
$highlight-icons: #ffce3e;
$call-to-action: #f39e1e;
$grey: #676c73;
$light-blue: #e3eaf4;
$label: #3c3c3c;
$bordergrey: #d4d4d4;

$training-green: #a8ca5c;
$training-blue: #87c5d6;
$training-orange: #e8873b;
$training-red: #e24956;
$training-pink: rgba(255, 99, 71, 0.5);
$dashboard-pink: #d87381;
$templating-yellow: #FF9F19;
$unallocated: #b5b5b5;

$lightgraybackground: #f4f3f5;
$magenta: #d44889;
$white-text: #fff;

/* -----------------------------VARIABLES (TYPOGRAPHY)------------------------  */

$api-button-font-size: 12px;

/* ----------------------------- VARIABLES (ACTIONS)------------------------  */

// COLOURS
$red-warning: #b31a1a;
$grey-text: #6d6d6d;
$white: #fff;
$hover-button: #a8ca5c;
$light-grey: #ddd;

$action-group-green: #a8ca5c;
$trigger-orange: #e8873b;
$rules-blue: #87c5d6;

// SIZING
$border-radius-modules: 8px;
$border-radius-inputs: 4px;
$standard-padding: 10px;

// FONT SIZES
$standard-paragraph: 12px;
$api-button-font-size: 12px;
$heading: 16px;
$help-tooltips-size: 18px;