.chat-container {
  div.row {
    margin:0;
  }

  .chat-sender {
    padding-left: 5px;
    color: #969696;
  }

//   .chat-window-message-container {
//     background-image: url('/images/UD/backgrounds/repeatable.png');
//   }

  margin: 40px;
  min-height: 80vh;
  border:1px solid #eaeaea;

  /*IE*/
  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    .directory-sub-items {



      /* ie scrollbar color properties */
      scrollbar-base-color: #efefef;
      scrollbar-face-color: #666666;
      scrollbar-3dlight-color: #666666;
      scrollbar-highlight-color: #666666;
      scrollbar-track-color: #efefef;
      scrollbar-arrow-color: #666666;
      scrollbar-shadow-color: #666666;
      scrollbar-width: thin;
      -ms-overflow-style: -ms-autohiding-scrollbar;
    }

    .chat-window-message-container {

      /* ie scrollbar color properties */
      scrollbar-base-color: #efefef;
      scrollbar-face-color: #666666;
      scrollbar-3dlight-color: #666666;
      scrollbar-highlight-color: #666666;
      scrollbar-track-color: #efefef;
      scrollbar-arrow-color: #666666;
      scrollbar-shadow-color: #666666;
      scrollbar-width: thin;
      -ms-overflow-style: -ms-autohiding-scrollbar;
    }
  }


  .directory-sub-items::-webkit-scrollbar {
    width: 8px;
    height: 0.5em;
    scrollbar-width: thin;
    -ms-overflow-style: -ms-autohiding-scrollbar;
  }

  .directory-sub-items::-webkit-scrollbar-track {
	-webkit-box-shadow: 0 0 6px rgba(173, 173, 173, 0.3);
	box-shadow: 0 0 6px rgba(173, 173, 173, 0.3);
    scrollbar-width: thin;
    -ms-overflow-style: -ms-autohiding-scrollbar;
  }

  .directory-sub-items::-webkit-scrollbar-thumb {
    background-color: #adadad;
    outline: 1px solid #adadad;
    width: 8px;
    scrollbar-width: thin;
    -ms-overflow-style: -ms-autohiding-scrollbar;
  }

  .chat-window-message-container::-webkit-scrollbar {
    width: 8px;
    height: 0.5em;
    scrollbar-width: thin;
    -ms-overflow-style: -ms-autohiding-scrollbar;
  }

  .chat-window-message-container::-webkit-scrollbar-track {
	-webkit-box-shadow: 0 0 6px rgba(173, 173, 173, 0.3);
	box-shadow: 0 0 6px rgba(173, 173, 173, 0.3);
    scrollbar-width: thin;
    -ms-overflow-style: -ms-autohiding-scrollbar;
  }

  .chat-window-message-container::-webkit-scrollbar-thumb {
    background-color: #adadad;
    outline: 1px solid #adadad;
    width: 8px;
    scrollbar-width: thin;
    -ms-overflow-style: -ms-autohiding-scrollbar;
  }

  div.directory-sub-items {
    overflow-y: scroll;
    padding: unset;
    max-height: 59vh;
    overflow-x: hidden;
  }

  div.directory-items {
    min-height: 80vh;
    padding:0;
	background-color: #f4f9fb;
	height:100%;

    div.directory-header {
      padding: 9px;
      border-top: 1px solid #b0b0b0;
      background-color: #efefef;
	  border-bottom: 1px solid #c1c1c1;
	  
	  &.row{
		  height:10%;
	  }

      div.header-column {
        padding:unset;
        height:100%;

        div.search-chat-input-container {
          height:40px;
          border-bottom: 1px solid #cacaca;
        }

        .search-chat-input {
          height:100%;
          width: 100%;
          border: 0;
          text-align: center;
        }

        .new-direct-chat-button-small {
          float: right;
          border-radius: 3px;
          border: 0px;
          //background-color: #96f196;
          background-color: #4774b7;
          color:white;
          font-size: 18px;
        }

        .new-direct-chat-button-small:hover {
          //background-color:#8de28d;
          background-color: #39485c;
        }

        .new-chat-button-small{
          float: right;
          border-radius: 3px;
          border: 0px;
          //background-color: #96f196;
          background-color: #4774b7;
          color:white;
          font-size: 18px;
          margin-right:5px;
        }

        .new-chat-button-small:hover {
          //background-color:#8de28d;
          background-color: #39485c;
        }
      }
    }

    div.directory-item {
		background-color: #ffffff;
		padding: 7px;
		/* border-radius: 15px; */
		color: #4b4b4b;
		border-bottom: 1px solid rgba(210, 210, 210, 0.69);
		font-size: 16px;
	  
	  	&.non-active{
			color: #9c9c9c;
    		background-color: #efefef;
		}

		&.active-directory-item{
			background-color: #3a485c;
			color: white;
			span.chat-sender{
				color:white;
			}
			div.chat-timestamp{
				color:white;
			}
			&:hover{
				background-color: #4774b7;
				color:white;
			}
		}

		.chat-name {
			font-weight:bold;
		}

		.chat-notification {
			.unread {
				color: #ffd400;
			}

			.read {
				color: #00ff5a;
			}
		}

		.chat-timestamp {
			font-size: 10px;
			color: #969696;
		}
    }

    div.active-directory-item {
      background-color: #cccccc;
    }

    div.directory-item:hover {
      background-color: #e9e9e9;
      cursor:pointer;
    }
  }

  div.chat-window {
    min-height: 80vh;
    padding:0;
    background-color: #f8fcfd;

    div.empty-chat-window {
      padding-top: 40px;
      text-align: center;
      width: 100%;
      height: 50vh;
      font-size: 20px;
    }
  }

  .new-chat-button {
    background-color: #4774b7;
    color:white;
    //background-color: #96f196;
    border-radius: 5px;
    border: unset;
    padding: 6px;
    font-size: 16px;
    padding-left: 10px;
    padding-right: 10px;
  }

  .new-chat-button:hover {
    //background-color:#8de28d;
    background-color: #39485c;
  }

  .new-chat-container {
    //padding:20px;

    .new-chat-config-container {
      margin-top: 10%;
      padding:20px;

      .new-chat-config-sub-container {
        margin-top:20px;
      }
    }
  }

  div.chat-window {

    div.message-content-bar {

      .nudge-button {
        padding: 5px;
        //background-color: #ddf8c8;
        background-color: #d9b710;
        border-radius: 4px;
        padding-left: 10px;
        padding-right: 10px;
        color:white;
        float: right;
        margin-right:3px;
      }

      .nudge-button:hover {
        cursor: pointer;
        //background-color: #ddf8c8;
        background-color: #c3a308;
      }

      .send-button {
        padding: 5px;
        //background-color: #ddf8c8;
        background-color: #4774b7;
        border-radius: 4px;
        padding-left: 10px;
        padding-right: 10px;
        color:white;
        float: right;
      }

      .send-button:hover {
        cursor: pointer;
        //background-color: #ddf8c8;
        background-color: #39485c;
      }
    }
  }

  .chat-window-header {
    h3 {
      color:#4b4b4b;
      margin-top:unset;
      margin-bottom:unset;
    }
  }

  .chat-window-message-container {
    padding:10px;
    //max-height:66.5vh;
    //min-height:66.5vh;
    //margin-bottom: 83px;
    overflow-y:scroll;
    height: 70%;
  }

  .chat-window {
    border-left: 1px solid #cacaca;
  }

  .message-bubble {
    width: fit-content;
    padding: 5px;
    border-radius: 10px;
    margin-bottom: 10px;
    padding-bottom: 0px;
    background-color: #f1f0f0;
    border: 1px solid #e8e8e8;
    -webkit-box-shadow: 0px 1px 4px 0px rgba(222, 222, 222, 0.75);
    -moz-box-shadow: 0px 1px 4px 0px rgba(222, 222, 222, 0.75);
    box-shadow: 0px 1px 4px 0px rgba(222, 222, 222, 0.75);


	&.system{
		background: none;
		border: none;
		box-shadow: none;
		width: 100%;
		text-align: center;
		.message-bubble-footer{
			display: none;
		}

      .message-bubble-header {
        display:none;
      }
	}

    .message-bubble-sender {
      color: #4ba5ff;
      font-size: 16px;
    }

    .message-bubble-footer {
      margin-top: -5px;
      margin-bottom: -5px;
    }

    .message-bubble-timestamp {
      float: right;
      color: #aeaeae;
      font-size:10px;
    }
  }
  .recipient-message {
    padding-bottom:5px;
    background-color: #f9f6f6;
  }

  .sent-message {
    float:right;
    background-color: #DDF8C8;

    .message-bubble-header {
      display: none;
    }
  }

  .load-more-messages {
    background-color: #4774b7;
    color:white;
    padding: 6px;
    padding-left: 10px;
    padding-right: 10px;
    border-radius: 4px;
    cursor: pointer;
  }

  .load-more-messages:hover {
    background-color: #3c5d95;
  }

  .active-chat-window-container {
    //max-height:65vh;
    height: 65vh;
  }
}

.show-message-pane {
  cursor:pointer;
}

div.minimise-message-pane {
  height:0px;
  margin-bottom: -2px;
  //height:40px;
}

.page-message-pane {
  background-color: white;
  border: 2px solid #8b8b8b;
  border-bottom: unset;
  position: fixed;
  bottom: 0;
  /* left: 30px; */
  margin-left: 30px;
  height: 65vh;
  width: 30vw;
  z-index: 100;
  -webkit-box-shadow: 0px 0px 5px 0px rgba(108, 108, 108, 0.75);
  -moz-box-shadow: 0px 0px 5px 0px rgba(108, 108, 108, 0.75);;
  box-shadow: 0px 0px 5px 0px rgba(108, 108, 108, 0.75);

  .chat-window-header {
    border-top: 1px solid #b0b0b0;
    border-bottom: 1px solid #c1c1c1;
    background-color: #efefef;
    height: 10%;
    padding-top: 0px;
    position: absolute;
    padding: 10px;
    z-index: 1000;
    top: 0px;
    width: 100%;

    h3 {
      margin-left:10px;
    }
  }

  div.search-chat-input-container {
    height:40px;
    border-bottom: 1px solid #cacaca;
  }

  .search-chat-input {
    height:100%;
    width: 100%;
    border: 0;
    text-align: center;
  }

  .chat-container {
    margin: 0;
    min-height: unset;
    border: unset;
    border-top: unset;

    .directory-items {
      min-height: unset;
      max-height: 70vh;

      .directory-item {
        font-size: 12px;
      }

      div.unread-directory-item {
        background-color: #ffbba2;
      }
    }

    .empty-chat-window {
      background-color:white;
    }

    .chat-window {
      min-height: unset;
      max-height: unset;
    }
  }

  .chat-window-message-container {
    //background-image: url('/images/UD/backgrounds/repeatable.png');
	height: 70%;
	
	div.date-notice{
		text-align: center;
		margin: 10px 0px;
		div.date-content{
			display: inline;
			padding: 5px;
			background-color: white;
			border-radius: 5px;
			font-size: 17px;
			border: 1px solid #dedede;
			box-shadow: 2px 2px 2px #d3d3d38c;
		}
	}
  }
}

.close-message-pane {
  position: absolute;
  top: 0;
  right: 0;
  height: 20px;
  z-index: 100000000;
  text-align: right;
  margin-right: 5px;
  color: #4f80a3;
  font-weight: bold;
  cursor:pointer;
}

.new-chat-window {
  height:59.9vh
}

.minimise-message-pane-button {
  position: absolute;
  top: 0;
  right: 0;
  height: 20px;
  z-index: 100000000;
  text-align: right;
  margin-right: 10px;
  color: #4f80a3;
  font-weight: bold;
  cursor:pointer;
}

.select2-dropdown {
  z-index: 999999;
}

.nudge-animation {
  -webkit-animation: nudge-animation 0.8s cubic-bezier(0.455, 0.030, 0.515, 0.955) both;
  animation: nudge-animation 0.8s cubic-bezier(0.455, 0.030, 0.515, 0.955) both;
}

@-webkit-keyframes nudge-animation {
  0%,
  100% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transform-origin: 50% 100%;
    transform-origin: 50% 100%;
  }
  10% {
    -webkit-transform: rotate(2deg);
    transform: rotate(2deg);
  }
  20%,
  40%,
  60% {
    -webkit-transform: rotate(-4deg);
    transform: rotate(-4deg);
  }
  30%,
  50%,
  70% {
    -webkit-transform: rotate(4deg);
    transform: rotate(4deg);
  }
  80% {
    -webkit-transform: rotate(-2deg);
    transform: rotate(-2deg);
  }
  90% {
    -webkit-transform: rotate(2deg);
    transform: rotate(2deg);
  }
}
@keyframes nudge-animation  {
  0%,
  100% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transform-origin: 50% 100%;
    transform-origin: 50% 100%;
  }
  10% {
    -webkit-transform: rotate(2deg);
    transform: rotate(2deg);
  }
  20%,
  40%,
  60% {
    -webkit-transform: rotate(-4deg);
    transform: rotate(-4deg);
  }
  30%,
  50%,
  70% {
    -webkit-transform: rotate(4deg);
    transform: rotate(4deg);
  }
  80% {
    -webkit-transform: rotate(-2deg);
    transform: rotate(-2deg);
  }
  90% {
    -webkit-transform: rotate(2deg);
    transform: rotate(2deg);
  }
}

.notification-window {
  -webkit-animation: pulsate-bck 0.5s ease-in-out infinite both;
  animation: pulsate-bck 0.5s ease-in-out infinite both;
}

@-webkit-keyframes pulsate-bck {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  50% {
    -webkit-transform: scale(0.9);
    transform: scale(0.9);
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}
@keyframes pulsate-bck {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  50% {
    -webkit-transform: scale(0.9);
    transform: translateY(-5px);
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

.collapsed-message-pane {
  background-color:rgb(226, 226, 226);

  .row {
    height: 0px;
    //height: 39px;
    margin:unset;

    .count-notification-col {
      background-color: #ddf8c8;
      height: 39px;
      text-align: center;
      line-height: 37px;
    }

    .message-content-col {
      line-height: 36px;
      height: 39px;
    }

    .timestamp-col {
      height: 39px;
      line-height: 36px;
      background-color: unset;
      padding: unset;
      margin-left: unset;
      padding-right: 15px;
      padding-left: 15px;
    }
  }
}

.notification-overlay-container {
	opacity: 0;
	z-index: 100000;
	position: absolute;
	top: 80px;
	right: 0px;
	background-color: #39485c;
	border-radius: 4px;
	border-top-right-radius: 0px;
	color: white;
	font-weight: bold;
	padding: 20px;
	box-shadow: 0px 0px 6px 0 #39485c;
	border-bottom-right-radius: 0px;
	border-bottom: 3px solid #4774b7;
	transition-duration: 0.5s;
	max-width: 20%;
	pointer-events: none;

	div.notification-body{
		font-weight: 100;
	}
}

.slide-in-right {
  -webkit-animation: slide-in-right 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
  animation: slide-in-right 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
  pointer-events: all;
}

@-webkit-keyframes slide-in-right {
  0% {
    -webkit-transform: translateX(1000px);
    transform: translateX(1000px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    opacity: 1;
  }
}
@keyframes slide-in-right {
  0% {
    -webkit-transform: translateX(1000px);
    transform: translateX(1000px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    opacity: 1;
  }
}

.slide-out-right {
	-webkit-animation: slide-out-right 0.5s cubic-bezier(0.940, 0.450, 0.460, 0.250) both;
	animation: slide-out-right 0.5s cubic-bezier(0.940, 0.450, 0.460, 0.250) both;
}

@-webkit-keyframes slide-out-right {
  	0% {
		-webkit-transform: translateX(0);
		transform: translateX(0);
		opacity: 1;
  	}
  	100% {
		-webkit-transform: translateX(1000px);
		transform: translateX(1000px);
		opacity: 0;
  	}
}
@keyframes slide-out-right {
	0% {
		-webkit-transform: translateX(0);
		transform: translateX(0);
		opacity: 1;
	}
	100% {
		-webkit-transform: translateX(1000px);
		transform: translateX(1000px);
		opacity: 0;
	}
}
.directory-item-container {
  .tooltip {
    width: 300px;
  }
}

div.open-message-pane-container{
	height:100%;
	div.chat-container{
		height:100%;
		div.row{
			height:100%;
			div.chat-window{
				height:100%;
				div.active-chat-window-container{
					position: relative;
					overflow-y: hidden;
					div.chat-window-header{
						border-top: 1px solid #b0b0b0;
						border-bottom: 1px solid #c1c1c1;
						background-color: #efefef;
						height: 10%;
						padding-top: 0px;
						position: absolute;
						padding: 10px;
						z-index: 1000000;
						top: 0px;
						width: 100%;
					}
					div.chat-window-message-container{
						position: absolute;
						width: 100%;
						height: 80%;
						top: 10%;
					}
					div.message-content-bar{
						height:auto;
						bottom: 0;
						left: 0;
						position: absolute;
						border-top: 1px solid #b9b9b9;
						padding: 1px;
						background-color: #efefef;
						width: 100%;
						height:10%;

						textarea.content-textarea{
							border-radius: 15px;
							max-width: 100%;
							padding: 5px;
							min-width: 100%;
							resize: none;
							border: 1px solid #cccccc;
							bottom: 0px;
							left: 0;
							border-left: unset;
							border-right: unset;
							border-bottom: unset;
							padding-left: 15px;
							box-shadow: 2px 1px 2px gainsboro inset;
						}
						div.send-button-holder{
							width: 100%;
							text-align: center;
							padding: 5px;
							color: #4774b7;
							cursor: pointer;
						}

						&.disabled{
							div.send-button-holder{
								color:grey;
							}
						}
					}
				}
			}
		}
	}
}



.chat-component-dropdown{
	padding: 5px;
    cursor: pointer;
    position: relative;
    width: 100%;
    text-align: center;

	&:hover{
		.chat-component-menu{
			display: block;
			background-color: white;
			position: absolute;
			left: 0;
			color: #333333;
			padding: 5px;
			border: 1px solid #bdbdbd;
			border-radius: 5px;
			width: -webkit-max-content;
			width: -moz-max-content;
			width: max-content;
			bottom: 100%;
			div{
				display: inline-block;
				margin-top: -5px;
				cursor: pointer;
				padding: 5px;
				i{
					&:hover{
						color: #4774b7;
					}
				}
			}
		}
	}
	.chat-component-menu{
		display: none;
	}
}

.chat-loader-overlay{
	position: absolute;
    height: 100%;
    width: 100%;
    background-color: rgba(128,128,128,0.63);
    top: 0;
	z-index: 10000000;

	.chat-loader-overlay-text{
		position: absolute;
		top: 50%;
		left: 50%;
		font-size: 40px;
		transform: translate(-50%, -50%);
		color: white;
	}
	
}

.chat-notification-count{
	background-color: #d21e1e;
    text-align: center;
    padding: 5px;
    border-radius: 50%;
    min-width: 23px;
    position: absolute;
    top: 0px;
	right: 5px;
	color:white;
}

.heartbeat {
	-webkit-animation: heartbeat 1.5s ease-in-out both;
	animation: heartbeat 1.5s ease-in-out both;
}

/* ----------------------------------------------
 * Generated by Animista on 2020-3-26 11:28:14
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation heartbeat
 * ----------------------------------------
 */
 @-webkit-keyframes heartbeat {
	from {
	  -webkit-transform: scale(1);
			  transform: scale(1);
	  -webkit-transform-origin: center center;
			  transform-origin: center center;
	  -webkit-animation-timing-function: ease-out;
			  animation-timing-function: ease-out;
	}
	10% {
	  -webkit-transform: scale(0.91);
			  transform: scale(0.91);
	  -webkit-animation-timing-function: ease-in;
			  animation-timing-function: ease-in;
	}
	17% {
	  -webkit-transform: scale(0.98);
			  transform: scale(0.98);
	  -webkit-animation-timing-function: ease-out;
			  animation-timing-function: ease-out;
	}
	33% {
	  -webkit-transform: scale(0.87);
			  transform: scale(0.87);
	  -webkit-animation-timing-function: ease-in;
			  animation-timing-function: ease-in;
	}
	45% {
	  -webkit-transform: scale(1);
			  transform: scale(1);
	  -webkit-animation-timing-function: ease-out;
			  animation-timing-function: ease-out;
	}
  }
  @keyframes heartbeat {
	from {
	  -webkit-transform: scale(1);
			  transform: scale(1);
	  -webkit-transform-origin: center center;
			  transform-origin: center center;
	  -webkit-animation-timing-function: ease-out;
			  animation-timing-function: ease-out;
	}
	10% {
	  -webkit-transform: scale(0.91);
			  transform: scale(0.91);
	  -webkit-animation-timing-function: ease-in;
			  animation-timing-function: ease-in;
	}
	17% {
	  -webkit-transform: scale(0.98);
			  transform: scale(0.98);
	  -webkit-animation-timing-function: ease-out;
			  animation-timing-function: ease-out;
	}
	33% {
	  -webkit-transform: scale(0.87);
			  transform: scale(0.87);
	  -webkit-animation-timing-function: ease-in;
			  animation-timing-function: ease-in;
	}
	45% {
	  -webkit-transform: scale(1);
			  transform: scale(1);
	  -webkit-animation-timing-function: ease-out;
			  animation-timing-function: ease-out;
	}
  }
  
.count-notification-col span {
  font-weight:bold;
}

.message-content-bar {
  .content-textarea:focus {
    outline-width: 0;
  }
}

.chat-confirmation-overlay{
	position: absolute;
    height: 100%;
    width: 100%;
    background-color: rgba(128, 128, 128, 0.63);
    top: 0;
	z-index: 10000000;
	
	div.chat-confirmation-section{
		padding: 20px;
		background-color: #ffffff;
		width: auto;
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		div.chat-confirmation-text{
			margin-bottom: 20px;;
		}
	}
}

div.typing-indicator{
	font-size : 0.8vw;
}

.message-bubble-body {
  font-size: 16px;
}