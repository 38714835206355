#admin_dialler_inbound_service {

    .module-inner {
        overflow: initial;
    }

    #create_inbound_service, #create_number {
      float: right;
      margin-right: 5px;
    }

    #services {

        .team-drop {
            cursor: initial;
        }

        .search-helper {
            display: none;
        }
    }

    #service {

        #phone-numbers {

            li {
                height: 60px;
            }
        }
    }
}

#dialler_inbound_service_modal, #dialler_phone_number_modal {

    .modal-body {

        .form-row {
            margin-bottom: 10px;

            input {
                height: 30px;
            }
        }
    }
}