.management-review-modal {
  overflow-y:scroll;

  output {
    display: block;
    font-size: 14px;
    line-height: 1.428571429;
    padding:0;
    color: #555555;
  }

  .review-report-date {
    position: absolute;
    right: 15px;
    top: 15px;
  }

  //.row {
  //
  //}

  .objectives-box {
    input {
      margin-bottom:10px;
    }
  }

  .previous-objectives {
    list-style: none;
    margin-left: 0px;
    padding-left: 0px;

    li {
      border-bottom:1px solid #c2c2c2;
    }
  }

  .reuse-objective {
    cursor: pointer;
    color: #487bc7;
    font-weight: bold;
  }

  .sub-item {
    margin-bottom:10px;
    margin-top:10px;
  }

  .main-item {
    margin-bottom:10px;
    padding:15px;
    border-bottom: 1px solid #bbbbbb;
  }

  .col-left {
    padding-right:15px;
    padding-left:0;
  }

  .col-right {
    padding-left:15px;
    padding-right:0;
  }

  .col-md-6 {
    background-color:unset;

    label {
      text-align: center;
    }
  }

  label {
    text-align: left;
    width: 100%;
    font-weight:bold;
    margin-bottom:0;
    padding:7px;
    background-color: #c1e9ff;
  }

  textarea, input, select {
    border-radius: 5px;
    width:100%
  }

  textarea {
    min-height:60px;
    max-width:100%;
    resize: vertical;
  }
}