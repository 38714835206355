.btn-contact {
	&:hover {
		background: $training-green;
		color: $white;
	}
	@include button-standard($main-brand-colour, #fff, 20px, 10px);
}

.btn-support {
	&:hover {
		background: $training-green;
		color: $white;
	}
	@include button-standard($main-brand-colour, #fff, none, none);
	margin-top: 10px;
	width: 100%;
	text-align: center;
}

.cancelBtn {
	&:hover {
		background: $main-brand-colour!important;
		color: $white-text;
	}
	background: #a5a6a7!important;
	color: $white-text;
	@include transition(background-color 0.5s ease);
}

.view-client-button {
	&:hover {
		color: #fff;
	}
	background: $training-green!important;
	color: #fff;
}

.view-client-remove-dnc-button {
	&:hover {
		color: #fff;
	}
	background: $training-red!important;
	color: #fff;
}

.view-client-add-dnc-button {
	&:hover {
		color: #fff;
	}
	background: $training-orange!important;
	color: #fff;
}


.back-btn, .looks-like-a-back-btn {
	background: #a5a6a7;
	margin-left: 10px;
	padding: 6px 12px;
	font-size: 12px;
	color: $white-text;
	display: inline-block;
	margin-bottom: 0;
	font-weight: normal;
	text-align: center;
	vertical-align: middle;
	touch-action: manipulation;
	cursor: pointer;
	border-radius: 3px;
	box-shadow: none;
	border: 1px solid transparent;
	@include transition(background-color 0.5s ease);
}

.tariff-details-button, .show-more-tariffs {
	&:hover {
		background-color: $main-brand-colour;
		border: 1px solid $main-brand-colour;
	}
    background-color: $training-orange;
    border: 1px solid $training-orange;
    line-height: 1.3;
	height: 30px;
	min-width: 30px;
    color: #fff;
    border-radius: 3px;
	font-size: $api-button-font-size;
	@include transition(background-color 0.5s ease);
}

.open-works-button {
	&:hover {
		background-color: $main-brand-colour;
		border: 1px solid $main-brand-colour;
	}
    background-color: $training-pink;
    border: 1px solid $training-pink;
    line-height: 1.3;
	height: 30px;
	min-width: 30px;
    color: #fff;
    border-radius: 3px;
	font-size: $api-button-font-size;
	@include transition(background-color 0.5s ease);
}

.send-documents-button {
	&:hover {
		background-color: $main-brand-colour;
		border: 1px solid $main-brand-colour;
	}
    background-color: $training-red;
    border: 1px solid $training-red;
    line-height: 1.3;
	height: 30px;
	min-width: 30px;
    color: #fff;
    border-radius: 3px;
	font-size: $api-button-font-size;
	@include transition(background-color 0.5s ease);
}

.download-cover-button {
	&:hover {
		background-color: $main-brand-colour;
		border: 1px solid $main-brand-colour;
	}
    background-color: $training-blue;
    border: 1px solid $training-blue;
    line-height: 1.3;
	height: 30px;
	min-width: 30px;
    color: #fff;
    border-radius: 3px;
	font-size: $api-button-font-size;
	@include transition(background-color 0.5s ease);
}

.sms-tariff-details-button, .email-tariff-details-button {
	&:hover {
		background-color: $training-orange;
		border: 1px solid $training-orange;
	}
	background-color: $main-brand-colour;
    border: 1px solid $main-brand-colour;
    @extend .tariff-details-button;
}

.sms-button {
	background-color: $magenta;
}

.tariff-details-select-button {
	a {
		color: #fff;
	}
}

.brand-button {
	background-color: $main-brand-colour;
}

.save-btn {
  background: $training-orange;
  margin-left: 12px;
  padding: 3px 6px;
  font-size: 10px;
  line-height: 1.428571429;
  color: $white-text;
  display: inline-block;
  margin-bottom: 0;
  font-weight: normal;
  text-align: center;
  vertical-align: middle;
  touch-action: manipulation;
  cursor: pointer;
  border-radius: 3px;
  box-shadow: none;
  border: 1px solid transparent;
  @include transition(background-color 0.5s ease);
}
.delete-btn {
	&:hover {
		background: $main-brand-colour;
	}
    background: $training-red;
    @extend .save-btn;
	
}

.fa-2x {
	&:hover {
		color: $white-text;
	}
    margin-top: 7px;
    margin-left: 10px;
    color: $white-text;
	
}

.previous-address-button {
	background: $training-green;
	margin-left: 0px!important;
	@include transition(background-color 0.5s ease);
}

#currentSupplierContinue-dual, #compareTypeContinue, #postcodeContinue, .btn {
  margin-left: 10px;
  font-size: 12px;
  color: $white;
  background: $training-green;
  @include transition(background-color 0.5s ease);
}

.btn, .save-btn, .back-btn {
	&:hover {
		background: $main-brand-colour;
		border: 1px solid transparent;
	}
}

.back-to-top {
    cursor: pointer;
    position: fixed;
    bottom: 0px;
    right: 0px;
    width: 100%;
    height: 25px;
	padding: 3px;
    font-size: 13px;
    background-color: rgba(0, 0, 0, 0.4)!important;
    display:none;
}

.fa-1x-green {
	color: $training-green;
}

.fa-1x-blue {
	color: $main-brand-colour;
}

.fa-1x-blue-nav {
	&:hover {
		color: $training-green;
	}
	color: $main-brand-colour;
}

.btn-search {
    height: 30px;
    position: absolute;
	background: $main-brand-colour;
    right: 0;
    border-radius:4px;
}

.custom-btn {
    color: #f9f7f7;
    background-color: $main-brand-colour;
    height: 20px;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
    border: 0px;
    padding-right: 8px;
	@include transition(background-color 0.5s ease);
}

.blue-btn {
    color: #f9f7f7;
    background-color: $main-brand-colour;
	@include transition(background-color 0.5s ease);
}


div.material-switch.price-alert-toggle {

	label {
		display: inline;
		&:before,
    	&:after {
			margin-top:6px;
			margin-left:-10px;
		}
	}

}