#unallocated-teams {
	.team-icon-small {
		background: $unallocated;
	}
	
}

.team-drop {
	border-bottom: none!important;
}

.unit-options {
	background: $light-blue;
}	

.unit-footer {
	p {
		margin: 0px;
	}
	color: $grey-text;
	border-bottom: 10px solid $light-grey;
	@extend %flex-x-y-align;
}

.team-drop, #teams-container, #user-container {
	.unit-wrapper {
		padding: 0px;
		min-height: 80px;
		margin: 0px;
	}
	.team-container {
		.trigger-icon-small {
			@include trigger-icon;
		}	
		background: $white;
		list-style-type: none;
		width: 100%;
		height: 40px;
		border-bottom: 1px solid $light-grey;
	}
	.trigger-icon {
		&:hover {
			cursor: auto;
		}
		border-bottom: 1px solid #ddd;
		@include trigger-icon-main($hoverbackground : $navbar-bg, $background : $navbar-bg);
	}
	
	#teamsort_, #user-container, #usersort {
		margin-bottom: 0px;
	}
	
	.col-md-10 {
		margin: 0px;
	}
	.team  {
		float: left;
		padding: $standard-padding;
	}
	min-height: 80px;
	position: relative;
	cursor: move;
	border-bottom: 10px solid $light-grey;
	display: -webkit-box;
    display: flex;
    z-index: 0;
    background: #f5f5f5;
}

.team-icon-small {
	@include trigger-icon($background: $main-brand-colour);
}

.user-icon-small {
	@include trigger-icon($background: $training-green);
}

.manager-icon-small {
	@include trigger-icon($background: $magenta);
}

.god-icon-small {
	@include trigger-icon($background: $templating-yellow);
}

.compliance-icon-small {
	@include trigger-icon($background: $navbar-bg);
}

.unallocated-icon-small {
	@include trigger-icon($background: $unallocated);
}

.saved-trigger-wrapper, .team-container {
	list-style-type: none;
    width: 100%;
    padding-left: 0px;
}

#user-container {
	.user-icon-small {
		background: $unallocated;
	}
}

#units-active, #unallocated-teams, #users, #edit-team {
	.action-group-heading {
		background: $navbar-bg;
	}
}

.edit-team-options {
	label:not(.label-success), .pound {
		margin-bottom: 0px;
		white-space: nowrap;
		font-size: 12px;
		display: flex;
		justify-content: center;
		flex-direction: column;
		text-align: left;
		padding-left: 5px;
	}
	.label-success {
		margin-top:13px;
	}
	
	
	input.form-control, select.call-event-drop{
		width : 50%;
		margin-left: 10px;
		margin-right: 10px;
	}
	
}

div.edit-team-options.center{
	display: flex;
	align-items: center;
	justify-content: center;
}

h4.edit-team-sub-headings{
	padding: 5px;
    font-size: 16px;
    padding-bottom: 0px;
    font-weight: 500;
}

#usersort2:after  {
	padding-left: 0%!important;
	@include dropzone-after-content("Drop Users Here");
}

#saved-units ul li,
#saved-types ul li,
#teams-container ul li, 
#user-container ul li,
#team-edit-container ul li,
#script-steps ul li {
	&:hover {
		background: $light-blue;
	}
	@include transition(background-color 0.5s ease);
}

#edit-user-main {
	.action-group-create {
		&:first-of-type {
			padding-top: 20px;
		}
		border-bottom: 0px;
		padding-top: 0px;
	}
	input, select {
		margin-left: 10px;
		margin-right: 5px;
	}
	.profile-header {
		h2 {
			font-size: 25px;
			margin-top: 10px;
		}
		.avatar {
			width: 100px;
			height: 100px;
			margin: 0 auto;
		}
		margin-bottom: 0px;
		padding: 10px;
	}
	.checkboxes {
		width: 179px;
		margin-bottom: 10px;
		float: right;
	}
}

.bg-info {
    background-color: #d9edf7;
    padding: 10px;
    margin-top: 10px;
    margin-bottom: 0px;
    border-radius: 8px;
}

#new-user {
	.modal-body {
		padding-top: 0px;
	}
	.modal-footer {
		padding-bottom: 0px;
		margin-bottom: 0px;
	}
	.form-group {
		margin-bottom: 0px;
	}
	.action-group-create {
		border-bottom: 0px;
	}
}

/*.ui-sortable-handle {
	z-index: 1000;
}*/

/*[id^="teamsort_"] {
	@include dropzone-after-content("Drop Teams Here");
}*/