.bank-payments-section {
	.deposit-input {
		width:80%;
	}
}

#splitCommission {
	#commission_split_agent_add {
		height:27px;
		width:100%;
		text-align: center;
	}
	.commission-split-table-header {
		border-bottom: solid 1px;
		font-weight: bold;
		text-align: center;
	}
	.commission-split-table-row {
		text-align: center;
		padding: 6px 0px;
	}
	.add-commission-split-button-wrapper {
		width:100%;
		text-align: center;
	}
	.commission-split-add-row{
		margin-top:20px;
		text-align: center;
		input {
			height:27px;
			text-align: center;
		}
	}
	.remove-agent-commission-split-button {
		background-color: red;
		padding: 5px;
		border-radius: 8px;
		color: wheat;
	}
}

.add-products-to-basket-section, .basket-product-summary {
	.row {
		margin:0;
	}
	.bundle-calculator-container {
		margin-top: 20px;
	}
	.product-group-products-tabs {
		.col-md-3 {
			margin:0px;
			padding:0px;
			text-align:center;
			.product-group-products-tab {
				margin:10px;
				padding:10px 0;
				border: solid 1px black;
				border-radius: 5px;
				.product-group-title {
					font-weight: bold;
					font-size: 1.5em;
				}
			}
		}
	}
	.product-group-products-content, .basket-products-content {
		.add-product-table-title, .basket-product-table-title {
			font-weight: bold;
			font-size: 1em;
			text-decoration: underline;
		}
		.product-table-item-row, .basket-product-table-item-row {
			margin: 5px;
			input {
				width:90%;
			}
			.strike-through {
				color:red;
				text-decoration: line-through;
			}
			.disclaimer {
				font-size: 10px;
				color:black;
			}
		}
	}
}
.will-questionair-section {
	.row {
		margin:0;
		margin-bottom: 10px;
	}
	.rll-label {
		padding-top: 16px;
	}
}

.rll-number-container {
	width: 60%;
    margin: auto;
    margin-bottom:5px;
	input[type=number] {
		width: 100%;
		height: 55px;
		border-radius: 8px;
		box-shadow: none;
		border: solid 1px;
		font-size: 2em;
		text-align: center;
	}
	.rll-number-update-button {
		padding-top: 5px;
        border-radius: 10px;
        border:1px solid #444;
        box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
        cursor: pointer;

        &.active{
            background: green;
            border-color: black;
            .rlc-toggle-image{
                filter: brightness(0) invert(1);
            }

            i{
                color:white;
            }

            label {
                color: white;
                font-weight: bold;
            }
        }
	}
	.rll-toggle-image{
        max-width: 70%;
        margin: auto;
        -webkit-user-drag: none;
        -khtml-user-drag: none;
        -moz-user-drag: none;
        -o-user-drag: none;
        user-drag: none;
    }

    i{
        color:grey;
        font-size:20px;
    }

    label{
        display: block;
        color : #444;
        font-size: 14px;
        // margin-botto	m:10px;
        -webkit-touch-callout: none; /* iOS Safari */
        -webkit-user-select: none; /* Safari */
        -khtml-user-select: none; /* Konqueror HTML */
        -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
        user-select: none;
        cursor: pointer;

        @media (max-width: 450px){
            font-size: 12px;
        }
    }

}

.fact-find-suggested-bundle {
	// border: solid 1px red;
    // padding: 20px;
    // border-radius: 15px;
    margin-bottom: 20px;
}
.fact-find-results {
	.answer-list-expand-button {
		width:50%;
		text-align: left;
		font-size: 1em;
		font-weight: bold;
	}
}

.product-bundle-selection-pannel {
	margin-bottom: 20px;
	.bundle-list-detail-wrapper {
		margin-bottom: 20px;
		border: solid 1px;
		.bundle-list-bundle-title {
			background-color: #4774B7;
			color:white;
			font-size: 1.5em;
		}
		.bundle-list-price-footer {
			// background-color: #e14e4e;
			// color: white;
			text-align: center;
			background-color: lightgrey;
			padding: 10px;
			border-top: solid 1px;
		}
		.bundle-list-select-button-wrapper {
			padding-top: 20px;
    		text-align: center;
			button {
				margin:10px;
			}
		}
	}

}

.rll-toggle-container{
    width: 60%;
    margin: auto;
    margin-bottom:5px;

    @media (max-width: 770px){
        width: 80%;
    }

    @media (max-width: 450px){
        width: 100%;
    }


    .rll-toggle{
        padding-top: 5px;
        border-radius: 10px;
        border:1px solid #444;
        box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
        cursor: pointer;

        &.active{
            background: green;
            border-color: black;
            .rlc-toggle-image{
                filter: brightness(0) invert(1);
            }

            i{
                color:white;
            }

            label {
                color: white;
                font-weight: bold;
            }
        }

    }

    .rll-toggle-image{
        max-width: 70%;
        margin: auto;
        -webkit-user-drag: none;
        -khtml-user-drag: none;
        -moz-user-drag: none;
        -o-user-drag: none;
        user-drag: none;
    }

    i{
        color:grey;
        font-size:20px;
    }

    label{
        display: block;
        color : #444;
        font-size: 14px;
        // margin-bottom:10px;
        -webkit-touch-callout: none; /* iOS Safari */
        -webkit-user-select: none; /* Safari */
        -khtml-user-select: none; /* Konqueror HTML */
        -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
        user-select: none;
        cursor: pointer;

        @media (max-width: 450px){
            font-size: 12px;
        }
    }
}

.bundle-calculator-container {
	padding: 10px 30px;
    border: solid 1px black;
    margin-bottom: 20px;
    border-radius: 20px;
	h5 {
		text-align:center;
		font-weight: bold;
	}
	select {
		width: 70%;
    	height: 26px;
	}

}


.payment-plan-payments-section {
	border:solid 1px black;
	border-radius: 15px;
	padding:10px;
	text-align: center;
}

.finance-agreement-payments-section, .aftersale-finance-agreement-payments-section {
	border:solid 1px red;
	border-radius: 15px;
	padding:10px;
	text-align: center;
}

.finance-dd-data-holder  {
	font-size: 15px;
	.item-label-holder {
		text-align: right;
	}
	.item-holder {
		text-align: left;
	}
}
