.taller-input-box {
	height: 30px;
}

.form-check-inline {
	display: inline;
}
.billing-warning {
	padding:20px;
	&.row {
		margin:0;
	}
}
.uw-address-selection-page {
	.energy-choice-group {
		// Consistent styling for group
		margin-bottom: 20px;

		label {
			display: block;
			margin-bottom: 10px;
		}

		.form-control {
			width: 100%;
			margin-bottom: 10px;
		}

		.btn {
			width: auto; // Button width as needed
			padding: 10px 15px;
		}

		.energy-choice {
			display: flex;
			align-items: center;

			input[type='radio'] {
				margin-right: 5px;
			}

			label {
				margin: 0;
			}
		}

		.uw-address-select-section,
		.postcode-lookup-section {
			display: flex;
			flex-direction: column;
		}

		#change_postcode_button {
			margin-top: 10px;
			color: #007bff; // Adjust as per your theme
			text-decoration: none;
		}
	}
}



.service-selection {
	h2 {
		color: #333;
		margin-bottom: 1rem;
	}

	.services-list {
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
		margin-bottom: 2rem;

		.service-item {
			background: #f9f9f9;
			border: 1px solid #ddd;
			border-radius: 4px;
			padding: 1rem;
			margin-bottom: 1rem;
			flex-basis: calc(33.333% - 1rem);
			text-align: center;
			display: flex;
			flex-direction: column;
			justify-content: space-between; // Ensures the button aligns to the bottom
			height: 240px; // Set a fixed height that works with your content
			cursor: pointer;

			&.disabled {
				opacity: 0.5;
				pointer-events: none;

				.select-service {
					cursor: not-allowed;
				}
			}

			&:hover,
			&.service-selected {
				border: 2px solid #007bff; // Highlight the border on hover or when selected
			}

			.service-icon {
				margin-bottom: 1rem;

				img {
					max-width: 50px; // Or set to the size of your icons
				}
			}

			.service-details {
				// Ensures the content scales within the remaining space
				flex-grow: 1;
				display: flex;
				flex-direction: column;
				justify-content: flex-start; // Aligns content to the top
				overflow: hidden; // In case the description is too long

				p {
					margin-bottom: auto; // Pushes everything else down
				}
			}

			.service-select {
				button {
					width: 50%;
					border-radius: 25px;
				}
			}

			h3 {
				color: #007bff; // Bootstrap primary color for consistency
				font-size: 1.25rem;
				margin-bottom: 0.5rem;
			}

			.service-price {
				font-weight: bold;
				margin-bottom: 1rem;
			}

			.select-service {
				background-color: #007bff; // Bootstrap primary color
				color: white;
				border: none;
				padding: 0.5rem 1rem;
				border-radius: 15px;
				width: 60;
				margin: auto;
				cursor: pointer;

				&:hover {
					background-color: darken(#007bff, 5%);
				}

				&.service-selected {
					background-color: #28a745; // Bootstrap success color for selected state

					&:hover {
						background-color: darken(#28a745, 5%);
					}
				}
			}
		}
	}

	.savings-section {
		h3 {
			margin-bottom: 1rem;
		}

		.savings-bar {
			// Placeholder for the savings bar styles
		}

		.savings-details {
			// Placeholder for the savings details styles
		}
	}
}

.broadband-and-phone-options {
	width: 80%;
	margin: auto;
}


.customise-eero.visible {
    display: block; /* Shown when the class 'visible' is added */
}

.landline-warning {
	background-color: #efefb3;
	font-weight: bold;
	margin:10px;
}

.energy-tariff-included-products-section {
	margin-top: 16px;
	border-top: solid 1px $main-brand-colour;
	&.row {
		margin-left:0;
		margin-right:0;
	}
	.included-products-section {
		background-color: #fff;
		padding: 10px;
		padding-left: 58px;
		// border-right: solid 1px;
		min-height: 100px;
		h4 {
			margin-bottom: 10px;
		}
		.included-item {
			font-weight: normal;
		}
		.included-item-monthly-price {
			color:green;
			padding-left:0;
		}
		.disclaimer {
			font-size: 10px;
			color:black;
		}
		.strike-through {
			text-decoration: line-through;
			color:red;
		}
		.tariff-results-product-icons{
			img {
				height:50px;
			}
		}
	}
	.included-products-section:first-of-type {
		border-radius: 0 0 0 25px;
	}
	.included-products-section:nth-of-type(3){
		border-right:none;
		border-radius: 0 0 25px 0;
	}
}
.energy-tariff-included-products-section.selected {
	border-color: $training-green;
}


.broadband-selection, .mobile-selection {
	display: flex;
	flex-wrap: wrap;
	justify-content: space-around;
	margin-bottom: 2rem;

	.tariff-option {
		background-color: #f4f4f9;
		border: 1px solid #dcdce6;
		border-radius: 8px;
		padding: 20px;
		margin: 10px;
		width: calc(25% - 20px); // Adjust the width as needed
		box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
		display: flex;
		flex-direction: column;
		justify-content: space-between;

		&:hover {
			background-color: #e9e9f3;
			cursor: pointer;
			box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
		}

		.tariff-header {
			background-color: #550091;
			color: white;
			padding: 10px;
			border-radius: 4px;
			font-size: 1.8em;
			text-align: center;
			margin: -20px -20px 20px -20px; // To extend the purple background edge to edge
		}

		.tariff-detail {
			flex-grow: 1;
			text-align: center;

			p {
				margin: 10px 0;
				font-size: 14px;

				strong {
					font-size: 25px;
				}
			}

			p.price {
				color: #333;
				font-size: 30px;
				font-weight: bold;

				.weak {
					color: #333;
					font-size: 18px;
				}
			}

			p.disclaimer {
				font-size: 10px;

				strong {
					font-weight: bold;
					font-size: 12px;
				}
			}

			p.strong {
				color: #550091;
				font-weight: bold;
				font-size: 25px;
			}
		}

		.select-tariff {
			background-color: #007bff; // Bootstrap primary color
			color: white;
			border: none;
			padding: 0.5rem 1rem;
			border-radius: 15px;
			width: 60%;
			margin: auto;
			cursor: pointer;

			&:hover {
				background-color: darken(#007bff, 5%);
			}

			&.tariff-selected {
				background-color: #28a745; // Bootstrap success color for selected state

				&:hover {
					background-color: darken(#28a745, 5%);
				}
			}
		}
	}
}


.equipment-selection {
	&.hidden {
		display: none;
	}

	.equipment-options {
		display: flex;
		flex-wrap: wrap;
		justify-content: center;
		margin-bottom: 2rem;

		.equipment-option {
			background-color: #f4f4f9;
			border: 1px solid #dcdce6;
			border-radius: 8px;
			padding: 20px;
			margin: 10px;
			width: calc(33.333% - 20px); // Adjust the width as needed
			box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
			display: flex;
			flex-direction: column;
			justify-content: space-between;

			&:hover {
				background-color: #e9e9f3;
				cursor: pointer;
				box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
			}

			.equipment-header {
				background-color: #550091;
				color: white;
				padding: 10px;
				border-radius: 4px;
				margin: -20px -20px 20px -20px; // To extend the purple background edge to edge
				font-size: 1.8em;
				text-align: center; // Center-align the title
				margin-bottom: 1rem; // Add some space below the header
			}

			.equipment-detail {
				flex-grow: 1;
				text-align: left;

				p {
					color: #333;
					margin: 10px 0;
				}

				p.price {
					color: #333;
					font-weight: bold;
					font-size: 1.4em;
				}

				p.description {
					font-size: 0.9em;
					color: #666;
				}
			}

			.select-equipment {
				background-color: #007bff; // Bootstrap primary color
				color: white;
				border: none;
				padding: 0.5rem 1rem;
				border-radius: 15px;
				width: 60%;
				margin: auto;
				cursor: pointer;

				&:hover {
					background-color: darken(#007bff, 5%);
				}

				&.equipment-selected {
					background-color: #28a745; // Bootstrap success color for selected state

					&:hover {
						background-color: darken(#28a745, 5%);
					}
				}
			}
		}
	}
}

#customise-eero {
  background-color: #fff;
  border: 1px solid #dcdce6;
  border-radius: 8px;
  padding: 20px;
  margin-top: 20px; // Space from the previous section
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);

  h2 {
    color: #4b4b8d; // Purple text for the header
    font-size: 1.5em;
    margin-bottom: 1rem;
  }

  p {
    color: #333;
    margin-bottom: 1rem;
  }

  ul {
    list-style-type: none;
    padding: 0;
    margin-left: 20px;

    li {
      position: relative;
      padding-left: 30px;
      margin-bottom: 0.5rem;
      &:before {
        content: '•';
        position: absolute;
        left: 0;
        color: #4b4b8d; // Purple bullet points
      }
    }
  }

  .learn-more {
    color: #4b4b8d;
    text-decoration: underline;
    margin-bottom: 2rem;
    display: inline-block;
  }

  .eero-devices-selection {
    h3 {
      font-size: 1.2em;
      margin-bottom: 1rem;
    }

    .number-select {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 2rem;

      button {
        background-color: #4b4b8d; // Purple buttons
        color: #fff;
        border: none;
        border-radius: 4px;
        padding: 10px 15px;
        margin: 0 5px;
        font-size: 1em;

        &:hover {
          background-color: #3a3a6d; // Darker purple on hover
        }

        &.minus, &.plus {
          font-weight: bold;
        }
      }

      .number {
        display: inline-block;
        width: 50px;
        text-align: center;
        font-size: 1.2em;
        margin: 0 10px;
      }
    }
  }
}

.landline-options {
  background-color: #fff;
  border: 1px solid #dcdce6;
  border-radius: 8px;
  padding: 20px;
  margin-top: 20px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);

  h2, h3 {
    color: #4b4b8d; // Purple text for the headers
    margin-bottom: 1rem;
  }
	.virgin-media-customer {
		margin-top: 1rem;

		label {
			display: block;
			margin-bottom: .5rem;
		}

		.radio-group {
			display: flex;
			align-items: center;
			
			input[type="radio"] {
				margin-right: .5rem;
			}

			label {
				margin-right: 2rem; // Spacing between radio options
			}
		}
	}


  p {
    color: #333;
    margin-bottom: 1rem;
  }

  label {
    display: block;
    margin-bottom: .5rem;
    color: #333;
  }

  input[type="text"] {
    width: 100%;
    padding: 10px;
    margin-bottom: 1rem;
    border: 1px solid #ccc;
    border-radius: 4px;
  }

  .call-packages {
    margin-bottom: 2rem;

    .call-package {
      background-color: #f4f4f9;
      padding: 10px;
      border-radius: 4px;
      margin-bottom: 10px;

      input[type="radio"] {
        margin-right: 10px;
      }

      label {
        font-weight: bold;
        color: #4b4b8d;
        cursor: pointer;
		display: inline;
      }

      .description {
        color: #666;
        font-size: .9em;
      }
    }
  }

}

.mobile-tariffs {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    margin-bottom: 2rem;
	.mobile-tariff-select-wrapper {
		width: 100%;
		margin: auto;
		display: flex;
		flex-wrap: wrap;
		justify-content: space-around;
		margin-bottom: 2rem;
		padding: 0px 30%;
		.mobile-tariff-option {
			background-color: #f4f4f9;
			border: 1px solid #dcdce6;
			border-radius: 15px;
			padding: 20px;
			margin: 10px;
			width: calc(50% - 20px); // Two items per row instead of three
			box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
			display: flex;
			flex-direction: column;
			justify-content: space-between;

			&:hover {
				background-color: #e9e9f3;
				cursor: pointer;
				box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
			}

			.tariff-header {
				background-color: #550091;
				color: white;
				padding: 10px;
				border-radius: 15px 15px 0px 0;
				font-size: 1.8em;
				text-align: center;
				margin: -20px -20px 20px -20px;
			}

			.tariff-detail {
				flex-grow: 1;
				text-align: center;

				p {
					margin: 10px 0;
					font-size: 14px;

					strong {
						font-size: 25px;
					}
				}

				p.price {
					color: #333;
					font-size: 30px;
					font-weight: bold;

					.weak {
						color: #333;
						font-size: 18px;
					}
				}

				p.discount {
					color: #007bff; // A different color to highlight the discount
					font-size: 14px;
					font-weight: bold;
					margin-top: 1rem; // Space above the discount message
				}
			}

			.select-mobile-tariff {
				background-color: #007bff;
				color: white;
				border: none;
				padding: 0.5rem 1rem;
				border-radius: 15px;
				width: 60%;
				margin: auto;
				cursor: pointer;

				&:hover {
					background-color: darken(#007bff, 5%);
				}

				&.tariff-selected {
					background-color: #28a745;

					&:hover {
						background-color: darken(#28a745, 5%);
					}
				}
			}
		}
	}
	.selected-sims {
		margin-top: 2rem;
		border-radius: 8px;
		background-color: #f4f4f9;
		padding: 20px;
		box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
		width: 78%;
    	margin: auto;
	}

	.selected-sim-option {
		border-bottom: 1px solid #dcdce6;
		padding-bottom: 20px;
		margin-bottom: 20px;

		&:last-child {
			border-bottom: none;
		}
	}

	.sim-option-header {
		color: #333;
		padding: 10px;
		font-size: 1.5em;
		text-align: left;
		margin-bottom: 1rem;
		width: 100%;
		font-weight: bold;
		i {
			padding-right: 12px;
			color: #d95d73;
    		font-size: 2em;
		}
	}

	.added-sims {
		display: flex;
		align-items: center;
		margin-bottom: 10px;
	}

	.add-extra-sim,
	.sim-detail {
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		justify-content: center;
		width:25%;
		margin: 20px;
		border: solid 1px;
		border-radius: 15px;
		background-color: white;
		padding: 20px;
		min-height: 225px;
		min-height: 300px;
	}
	.add-extra-sim {
		border-style: dashed;
		padding: 40px;
		.sim-option-header {
			width: 100%;
			background-color: white;
			text-align: center;
			font-size: 1.4em;
			font-weight: 600;
			color: #333;
			margin-top:12%;
		}
		.add-sim-button-wrapper {
			width:100%;
		}
		.price {
			color: #333;
			font-weight: bold;
			font-size: 1.4em;
			width:100%;
			text-align: center;
			border-top: none;
		}
	}

	.sim-wrapper {
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		width: 100%;
	}

	.price {
		color: #333;
		font-weight: bold;
		font-size: 1.4em;
		border-top: 1px #333 solid;
		padding-top: 5px;
		width: 100%;
	}

	.weak {
		font-weight: normal;
		font-size: 1em;
	}

	.add-additional-sim-button-container{
		width: 100%;
		text-align: center;
		margin-top: 20px;
	}
	.btn-add-sim, 
	.add-additional-sim-button {
		background-color: #007bff;
		color: white;
		border: none;
		border-radius: 15px;
		cursor: pointer;
		text-align: center;
		width:60%;
		margin:auto;
		transition: background-color 0.3s ease;
		padding: 3px;
		&:hover {
			background-color: darken(#007bff, 5%);
		}
	}

	.remove-sim-button {
		text-align: center;
    	width: 100%;
		a {
			color: #ff0000;
			text-decoration: none;
			cursor: pointer;
			font-size: 0.9em;
		}

		a:hover {
			text-decoration: underline;
		}

		i {
			margin-right: 5px;
		}
	}

	.mobile-summary {
		background-color: #fff;
		padding: 20px;
		width: 100%;
		float: right;
		margin-top: 10px;
	}
	.summary-container {
		background-color: #fff;
		border-radius: 8px;
		box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
		padding: 20px;
		width: 40%;
		float:right;
		margin-right: 10%;
		.summary-title {
			color: #333;
			margin-bottom: 20px;
		}
		.summary-items {
			.summary-item {
			display: flex;
			justify-content: space-between;
			align-items: center;
			margin-bottom: 10px;
			.item-name {
				color: #333;
				font-size: 1em;
			}
			.item-price {
				.original-price {
					color: green;
				}
				.discounted-price {
					color: green;
					font-weight: bold;
				}
				.strike-through {
					color:#333;
					text-decoration: line-through;
					margin-right: 5px;
				}
			}
			}
		}
		.summary-total {
			display: flex;
			justify-content: space-between;
			align-items: center;
			border-top: 2px solid #eee;
			padding-top: 20px;
			margin-top: 20px;
			.total-title {
			color: #333;
			font-size: 1.2em;
			}
			.total-price {
			color: #333;
			font-size: 1.5em;
			font-weight: bold;
			}
		}
	}
}

.mobile-tariffs-modal-container {
    .modal {
        display: none;
        position: fixed;
        z-index: 1;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        overflow: auto;
        background-color: rgba(0,0,0,0.4);
    }

    .modal-content {
        background-color: #fefefe;
        margin: 5% auto;
        padding: 20px;
        border: 1px solid #888;
        width: 30%;
        border-radius: 10px;

        .close {
            color: #aaa;
            float: right;
            font-size: 28px;
            font-weight: bold;

            &:hover, &:focus {
                color: black;
                text-decoration: none;
                cursor: pointer;
            }
        }

        h2 {
            margin-bottom: 1rem;
        }

        p {
            margin-bottom: 1rem;
            &:last-of-type {
                margin-bottom: 2rem;
            }

            strong {
                font-weight: bold;
            }
        }
		.spend-cap-radio-buttons {
			width: 60%;
			margin: auto;
			text-align: center;	
		}

        .custom-radio {
			display: inline-block;
			position: relative;
			padding-left: 35px;
			margin: 10px;
			cursor: pointer;
			user-select: none;

			input {
				position: absolute;
				opacity: 0;
				cursor: pointer;
			}

			.radio-button {
				position: absolute;
				top: 0;
				left: 0;
				height: 25px;
				width: 25px;
				background-color: #eee;
				border-radius: 50%;
				border: 2px solid #dcdcdc;

				&:after {
					content: "";
					position: absolute;
					display: none;
				    top: 1px;
					left: 1px;
					width: 20px;
					height: 20px;
					border-radius: 50%;
					background: #007bff;
				}
			}

			// Show the button's inner circle when checked
			input:checked ~ .radio-button:after {
				display: block;
			}

			// Hover state for the custom radio buttons
			&:hover input ~ .radio-button {
				background-color: #ccc;
			}
		}

		// Adjust the label text to align with the custom radio button
		.custom-radio {
			line-height: 25px; // This should match the height of the custom radio buttons
			font-size: 16px; // Set the font size as needed
		}
		.save-spend-cap-button-wrapper {
			width:100%;
			text-align: right;
		}
        #save-spend-cap {
            background-color: #007bff;
            color: white;
            padding: 10px 20px;
            border: none;
            border-radius: 5px;
            cursor: pointer;

            &:hover {
                background-color: darken(#007bff, 10%);
            }
        }
		.number-options-details {
            
            .keep-number-details,
			.cancel-number-details,
            .cancel-contract-details {
            
                
                input[type="text"] {
                    width: 100%;
                    padding: 10px;
                    margin-bottom: 10px;
                    border: 1px solid #ccc;
                    border-radius: 4px;

                    &:focus {
                        border-color: #550091;
                        box-shadow: 0 0 8px rgba(85, 0, 145, 0.2);
                    }
                }

                label {
                    display: block;
                    margin-bottom: 5px;
                }
            }
        }

		#save-number-options {
            background-color: #007bff;
            color: white;
            padding: 10px 20px;
            border: none;
            border-radius: 5px;
            cursor: pointer;
            display: block;
            width: 100%;
            margin-top: 20px;

            &:hover {
                background-color: darken(#007bff, 10%);
            }
        }
    }
}

/* The additional SIMs and summary section will have their own styles, 
   which you can add below this point */


.summary {
    background: #fff;
    border-radius: 6px;
    box-shadow: 2px 2px 4px rgba(1, 0, 0, 1.1);
	padding: 20px;
    width: 50%; // Set the width to 50%
    float: right; // Float to the right
	margin-top:10px;


    h3 {
        color: #4b4b8d;
        margin-bottom: 15px;
		text-align: right;
    }

    .summary-item {
        display: flex;
        justify-content: space-between;
        margin-bottom: 10px;

        span {
            &:first-child {
                font-weight: normal;
            }
            &:last-child {
                font-weight: bold;
            }
        }
    }

    .summary-total {
        border-top: 1px solid #eee;
        padding-top: 10px;
        margin-top: 10px;
		font-size: 25px;
		margin-bottom: 0;
    }

    .promo-text {
        font-style: italic;
        color: #666;
        text-align: right;
		padding-right: 0;

    }
womp-energy-intro-panel
    .summary-notice {
        font-size: 0.9em;
        color: #666;
        margin-top: 0px;
        &.delivery-cost-notice {
            border-top: 1px solid #eee;
            padding-top: 10px;
			margin-top: 10px;
        }
    }
}

.womp-energy-intro-panel {
	font-size: 18px;
}

.energy-usage-page {
	width: 90%;
    margin: auto;
    background-color: #E3EAF4;
    padding: 10px 5%;
    border-radius: 15px;
	&.womp-energy-intro-panel{
		background-color: transparent;
	}
	h2 {
		font-size: 20px;
	}
	.energy-supplier-and-spend-section,.broadband-supplier-and-spend-section {
		font-size: 1.2em;
		font-weight: bold;
		.energy-spend-input span{
			font-size:1.8em;
			padding-right:5px;
			vertical-align: middle;
		}
		input,select {
			width:88%;
			border: solid 2px #007bff;
			height:40px;
			border-radius: 5px;
		}
	}

	.energy-choice {
		border: 1px solid #ddd; // Replace with the actual color from the design.
		border-radius: 10px; // This gives the rounded corners.
		padding: 10px; // Adjust as necessary.
		text-align: center;
		width: 33%;
		background-color: #fff;

		// Hover effect
		&:hover {
			box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2); // A subtle shadow.
		}
		&.option-selected {
			border:solid 2px #007bff;
		}
		.icon-wrapper {
			margin-bottom:15px;
		}

		label {
			cursor: pointer;
			display: block;
			position: relative;

			// Icon styling
			i {
				font-size: 2em; // Adjust size as needed.
				margin-right: 5px;

				&.fa-fire-alt {
					color: #75A7FD; // Replace with the actual color from the design.
				}
				
				&.fa-bolt {
					color: #58C487; // Replace with the actual color from the design.
				}
				&.fa-pound-sign {
					color: red; // Replace with the actual color from the design.
				}
			}
			.radio-option-label {
				font-size: 1em;
				font-weight: bold;
			}
		}
		input[type="radio"] {
			display:none;
		}
	}
	.simple-usage-choice {
		border: 1px solid #ddd; // Replace with the actual color from the design.
		border-radius: 10px; // This gives the rounded corners.
		padding: 10px; // Adjust as necessary.
		text-align: center;
		width: 33%;
		background-color: #fff;

		// Hover effect
		&:hover {
			box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2); // A subtle shadow.
		}
		&.option-selected {
			border:solid 2px #007bff;
		}
		.icon-wrapper {
			margin-bottom:15px;
		}

		label {
			cursor: pointer;
			display: block;
			position: relative;
			font-size: 14px;

			// Icon styling
			i {
				font-size: 2em; // Adjust size as needed.
				margin-right: 5px;

				&.fa-fire-alt {
					color: #75A7FD; // Replace with the actual color from the design.
				}
				
				&.fa-bolt {
					color: #58C487; // Replace with the actual color from the design.
				}
			}
			.radio-option-label {
				font-size: 1em;
				font-weight: bold;
			}
		}
		input[type="radio"] {
			display:none;
		}
	}
	.energy-supply-type-section {
		display: flex;
		justify-content: space-between;		
	}
	.meter-type-section,
	.payment-options-section,
	.payment-method-section {
		display: flex;
		justify-content: space-between;
		.energy-choice {
			width: 48%;
		}
	}
	.payment-options-section {
		.energy-choice {
			text-align: left;
		}
	}
	.option-disabled {
		background-color: #dfd5d5;
	}
	.energy-usage-type-section {
			display: flex;
			justify-content: space-between;
			.energy-choice {
				width: 19%;
			}
		}
	.energy-usage-type-section-wrapper {
		text-align: center;
		background-color: white;
		padding: 10px;
		border-radius: 15px;
		h3 {
			margin-top:10px;
		}
		.energy-usage-type-sub-section {
			display: flex;
			justify-content: space-between;
			.energy-choice {
				width: 19%;
			}
		}
	}
	.simple-energy-usage-wrapper, .spend-energy-usage-wrapper {
		margin-top:20px;
		.simple-energy-usage-option-wrapper {
			display: flex;
			justify-content: space-between;
			.energy-usage-type-additional-information {
				width:77%;
				background-color: #fff;
				border: 1px solid #ddd; // Replace with the actual color from the design.
				border-radius: 15px; // This gives the rounded corners.
				padding: 10px;
				margin:10px 0;

				display: flex;
				justify-content: space-between;
				&.energy-usage-type-additional-information-wide {
					width:100%;
					.usage-list-item { 
						margin-top: 10px;
					}
				}
				h3 {
					font-size:17px;
					margin:0px;
				}
				.usage-list-item {
					display: flex;
					flex-direction:row;
					margin-top: 25px;
					font-size: 14px;
					i {
						margin-right: 5px;
						color: rgb(228, 93, 116);
						width: 25px;
						height: 14px;

						&.fa-fire-alt {
							color: #75A7FD; // Replace with the actual color from the design.
						}
						
						&.fa-bolt {
							color: #58C487; // Replace with the actual color from the design.
						}
					}
				}
				.simple-usage-property-summary {
					width:50%;
				}
				.simple-usage-consumption-summary {
					width:50%;
				}
			}
		}
		.simple-usage-warning {
			background-color: #3a3a6d;
			color:white;
			width: 20%;
			min-height: 200px;
			padding: 20px;
			border-radius: 15px;
    		margin: 10px 0;
		}
		.spend-as-usage-warning {
			background-color: #3a3a6d;
			color:white;
			min-height: 200px;
			padding: 20px;
			border-radius: 15px;
    		margin: 10px 0;
		}
	}
	.actual-energy-usage-wrapper {
		display: flex;
		flex-direction: column; // Changed for better vertical alignment
		align-items: center; // Center align items
		background-color: #fff;
		border: 1px solid #ddd;
		border-radius: 15px;
		padding: 20px; // Increased padding for better spacing
		margin: 10px 0;

		.energy-usage-type-additional-information {
			width: 100%;

			h3 {
				font-size: 20px; // Slightly larger for better visibility
				margin-bottom: 15px; // Added margin for spacing
				display: flex;
				align-items: center; // Align icon and text

				i {
					font-size: 1.8em; // Larger icon size
					margin-right: 10px; // Adjusted margin
					width: auto; // Auto width for better icon display
					height: auto; // Auto height for better icon display
				}
			}
		}

		.usage-type-input-wrapper {
			width: 100%; // Full width for consistency
			margin-top: 15px; // Adjusted margin for spacing

			.usage-input-wrapper {
				display: flex;
				flex-direction: column; // Column layout for better organization
				align-items: flex-start; // Align items to the start
				width: 100%; // Full width

				label {
					font-size: 16px; // Label font size
					margin-bottom: 5px; // Margin for spacing
				}

				input {
					width: 48%; // Half width for input
					padding: 10px; // Padding for better touch area
					margin-bottom: 10px; // Margin for spacing
					border: 1px solid #ccc; // Border for input
					border-radius: 5px; // Border radius for aesthetics
				}
				&.electricity-eco7-inputs {
					display: flex;
					flex-direction: unset;
					justify-content: space-between; // Layout the day and night containers side by side

					.day-container, .night-container {
						display: flex;
						flex-direction: column; // Stack label and input vertically
						width: 48%; // Adjust width to fit side by side

						label {
							margin-bottom: 5px; // Space between label and input
							text-align: left; // Align labels to left
						}

						input {
							width: 100%; // Full width within the container
						}
					}
				}
			}
		}
	}
	.estimated-energy-usage-wrapper {
		display: flex;
		flex-direction: column; // Changed for better vertical alignment
		align-items: center; // Center align items
		background-color: #fff;
		border: 1px solid #ddd;
		border-radius: 15px;
		padding: 20px; // Increased padding for better spacing
		margin: 10px 0;
		#estimated_usage_inputs {
			width:100%;
		}
		.usage-selector {
			h3 {
				margin-top: 0;
    			text-align: center;
			}
			.property-type-section,
			.number-of-bedrooms-section,
			.number-of-people-section {
				margin-bottom: 20px; // Spacing between each section

				h4 {
					margin-bottom: 10px; // Spacing between the header and the options
				}

				.energy-choice-group {
					display: flex;
					flex-wrap: wrap; // Allows items to wrap to next line on smaller screens
					gap: 10px; // Spacing between each option

					.energy-choice {
						display: flex;
						flex-direction: column; // Align label and input vertically
						align-items: center; // Center-align items within each choice
						padding: 5px 10px;
						border: 1px solid #ddd;
						border-radius: 10px;
						background-color: #fff;
						cursor: pointer;
						width:19%;

						&:hover {
							background-color: #f5f5f5; // Hover effect
						}
						&.option-selected {
							border:solid 2px #007bff;
						}

						label {
							cursor: pointer;
						}

						input[type="radio"] {
							display: none; // Hide radio button, style label instead
						}
					}
				}
			}
		}
	}
}
.upgrade-options-wrapper {
	.upgrade-option-item {
		padding: 13px;
		border: solid 2px red;
		border-radius: 25px;
		width: 80%;
		margin: auto;
		min-height: 420px;
		.icon-holder {
			text-align: center;
			padding-top:5px;
			padding-bottom: 5px;
			border-bottom: solid 1px red;
			// margin-bottom: 20px;
		}
		&.option-selected {
			border: solid 2px #28a745;
			.icon-holder { 
				border-bottom: solid 1px #28a745;
			}
		}
		&.upgraded-option {
			border: solid 2px purple;
			.icon-holder { 
				border-bottom: solid 1px purple;
			}
		}
		h3 {
			text-align: center;
			margin-top:2px;
		}
		ul {
			font-size: 16px;
		}
		.option-price-holder {
			text-align: center;
			color:#007bff;
			font-size:14px;
			.strike-through-price {
				font-size: 20px;
				color:red;
				text-decoration: line-through;
				margin-right:10px;
			}
			.option-price {
				font-size: 20px;
				font-weight: bold;
			}
		}
		.disclaimer {
			font-size: 10px;
			color:black;
		}
		.strike-through {
			text-decoration: line-through;
			color:red;
		}
		.upgrade-option-select-button-wrapper {
			width:100%;
			text-align: center;
		    position: absolute;
			left: 0;
			bottom: 10px;
			.upgrade-option-select-button-holder {
				width:50%;
				margin:auto;
				.btn {
					width:80%;
				}
			}
		}
	}
}

.utility-warehouse-energy-basket-product-section {
	.product_group_row {
		border-radius: 15px;
		padding: 10px;
		border: solid 1px;
		margin-bottom: 10px;
		&.disabled {
			border: solid 1px red;
			background-color: #dbdbdb;
			h4 {
				text-decoration: line-through;
			}
		}
	}
}

.required_products{
	padding:10px;
	ul {
		display: inline;
		li {
			display: inline;
			border: solid 1px;
			padding: 9px;
			border-radius: 15px;
			i {
				margin-right: 10px;
			}
		}
		.red {
			color:red;
		}
		.green {
			color:green;
		}
	}
}


// Optional: Responsive adjustments
@media (max-width: 768px) {
  .landline-options {
    padding: 15px;

    .summary-item, .summary-total {
      flex-direction: column;
      align-items: flex-start;
    }
  }
}


// Optional: Responsive adjustments
@media (max-width: 768px) {
  #customise-eero {
    padding: 15px;
    margin-top: 10px;

    ul {
      margin-left: 10px;

      li {
        padding-left: 25px;
      }
    }

    .learn-more {
      margin-bottom: 1rem;
    }

    .eero-devices-selection {
      .number-select {
        margin-bottom: 1rem;
      }
    }
  }
}



// Media query for smaller screens
@media (max-width: 768px) {
	.service-selection .services-list .service-item {
		flex-basis: 100%;
	}
}