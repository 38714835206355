#insurance_product_selection_form {
	margin-left: 20px;
	h4 {
		margin-bottom: 10px;
	}
	select {
		height: 27px;
		min-width: 200px;
	}
	input {
		min-width: 200px;
	}
	.input_label {
		font-size: 1.2em;
		text-align: right;
	}
	.row {
		margin:0px;
	}
	.input-section-wrapper {
		padding-left:20px;
	}
}