#reports, 
#report-field-links, 
#report-fields, 
#edit-report-field-links, 
#action-group-active {
	.action-group-heading {
		margin-bottom: 0px;
		background: $navbar-bg;
	}
}

#edit-report-field-links {
	.trigger-pass {
		background: $navbar-bg;
	}
}

.report-field-link-container {
	border-bottom: 0px!important;
}

#report-field-container {
	.delete-trigger {
		display: none;
	}
}

.trigger {
	float: left;
    padding: 10px;
	padding-top: 6px;
}

.field-list {
	width: 96.4%;
	margin: 10px;
	margin-top: 0px;
	border-right: 1px solid #ddd;
	border-top: 1px solid #ddd;
}

#report-field-link-container ul li,
#report-field-container ul li {
	&:hover {
		background: $light-blue;
	}
	@include transition(background-color 0.5s ease);
}

#report-field-link-container {
	.unit-wrapper {
		padding: 0px;
		min-height: 80px;
		margin: 0px;
	}
	.team-container {
		.trigger-icon-small {
			@include trigger-icon;
		}	
		background: $white;
		list-style-type: none;
		width: 100%;
		height: 40px;
		border-bottom: 1px solid $light-grey;
	}
	.trigger-icon {
		&:hover {
			cursor: auto;
		}
		border-bottom: 1px solid #ddd;
		@include trigger-icon-main($hoverbackground : $navbar-bg, $background : $navbar-bg);
	}
	
	#teamsort_, #user-container, #usersort {
		margin-bottom: 0px;
	}
	
	.col-md-10 {
		margin: 0px;
	}
	.team  {
		float: left;
		padding: $standard-padding;
	}
	min-height: 80px;
	position: relative;
	cursor: move;
/*	border-bottom: 10px solid $light-grey;*/
	display: -webkit-box;
    display: flex;
    z-index: 0;
    background: #f5f5f5;
}

#report-field-container {
	button {
		&:hover {
			background: $hover-button;
		}
		padding: 5px;
		background: #4774b7;
		border: none;
		border-radius: 4px;
		color: #fff;
		height: 30px;
		font-size: 14px;
		transition: background-color 0.5s ease;
		width: 96.4%;
		margin: 10px;
		text-align: center;
		margin-bottom: 10px;
	}
	.unit-wrapper {
		padding: 0px;
		min-height: 80px;
		margin: 0px;
	}
	.team-container {
		.trigger-icon-small {
			@include trigger-icon;
		}	
		background: $white;
		list-style-type: none;
		width: 100%;
		height: 40px;
		border-bottom: 1px solid $light-grey;
	}
	.trigger-icon {
		&:hover {
			cursor: auto;
		}
		border-bottom: 1px solid #ddd;
		@include trigger-icon-main($hoverbackground : $navbar-bg, $background : $navbar-bg);
	}
	
	#teamsort_, #user-container, #usersort {
		margin-bottom: 0px;
	}
	
	.col-md-10 {
		margin: 0px;
	}
	.team  {
		float: left;
		padding: $standard-padding;
	}
	min-height: 80px;
	position: relative;
	cursor: move;
/*	border-bottom: 10px solid $light-grey;*/
/*	display: -webkit-box;*/
/*  display: flex;*/
    z-index: 0;
}

/*#report-field-links {
	.edit-trigger {
		display: none;
	}
}*/

#teams-container li.team-container{
	height:50px;
}


.report {
	.name-of-the-thing {
		color: $main-brand-colour; 
	}
	margin-top: 4px;
	width: 60%;
    white-space: nowrap;
    overflow: hidden ;
    text-overflow: ellipsis;
    padding-left: 5px;
    font-size: 11px;
	display: inline-block;
	cursor: auto;
}

.print-report{
	float: right;
    padding: 10px;
	transition: all 0.5s ease;
	cursor: pointer;
}

.upload-static-report-data {
	float: right;
    padding: 10px;
	transition: all 0.5s ease;
	cursor: pointer;
}

#report_upload_panel {
	#static_report_file_upload_dropzone {
		padding:20px;
		margin:20px;
		border: solid 1px black;
		border-radius: 15px;
		min-height: 250px;
		text-align: center;
		overflow: hidden;
		.instruction-to-drop {
			width: 100%;
			margin: auto;
			text-align: center;
			padding-top: 10%;
			font-size: 1.5em;
			color: grey;
		}
		.file-icon-container {
			width:80%;
			padding:10px;
			margin:auto;
			text-align:center;
			.large-icons {
				font-size:5em;
			}
		}
		.dz-preview {
			width:30%;
			float:left;
			.dz-filename {
			    word-wrap: break-word;
				width: 60%;
				text-align: center;
				margin: auto;
			}
		}
	}
}

.attributes-trigger {
	cursor: pointer;
	float: right;
	padding: 10px;
	display: inline-block;
	transition: all 0.2s ease;
	color: #4774b7;
}

.attributes-trigger:hover {
	color: #00ae33;
}

.clone-report-trigger {
	cursor: pointer;
	float: right;
	padding: 10px;
	display: inline-block;
	transition: all 0.2s ease;
	color: #4774b7;
}

.clone-report-trigger:hover {
	color: #00ae33;
}

.delete-report-trigger {
	cursor: pointer;
	float: right;
	padding: 10px;
	display: inline-block;
	transition: all 0.2s ease;
	color: #4774b7;
}

.delete-report-trigger:hover {
	color: #00ae33;
}

.restore-report-trigger {
	cursor: pointer;
	float: right;
	padding: 10px;
	display: inline-block;
	transition: all 0.2s ease;
	color: #4774b7;
}

.restore-report-trigger:hover {
	color: #00ae33;
}

.report-type-header {
	padding: 5px;
	color: white;
	background-color: #58677b;
	text-align: center;
}

.preview-report-datatable {
	border: 1px solid #d0d0d0;
	padding: 6px;
}

.dropdown-container-reports {
	position: relative;
	margin-top: 6px;
	cursor: pointer;
	float: right;
	padding: 10px;
	display: inline-block;
	transition: all 0.5s ease;
}

.show-deleted-reports {
	cursor: pointer;
}

.deleted-reports-container {
	transition: all 0.3s ease;
}

.team-icon-attribute {
	min-height: 40px;
}

.add-report-attribute-landing {
	background-color: #f2f2f2;
	//height: 60px;
	margin: 4px;
	min-height:60px;
	text-align: center;
	border: 2px dashed #c1c1c1;
	transition: all 0.3s ease;
	line-height: 52px;
	cursor: pointer;

	// position: absolute;
	height: 100%;
	top: 0px;
	left: 0px;
	right: 0px;
}

.add-report-attribute-landing:hover {
border-color: #3b485c;
background-color: #eeeeee;
}

.remove-report-attribute {
	cursor: pointer;
}

.shared-users-container-row {
	border: 1px solid #d8d8d8;
	padding: 4px;
}

.shared-users-row-header {
	font-weight: bold;
	border-bottom: 1px solid #717171;
}

.shared-users-container {
	padding:20px;
}

.report-container-margin-bottom {
	margin-bottom:10px;
}

.report-count-icon {
	float: left;
	display: inline;
	font-size: 11px;
	line-height: 15px;
	background: #ef7d24;
	margin-top: 0px;
	padding: 0px 4px 0px 3px;
	border-radius: 15px;
	position: relative;
	top: 2px;
	left: 2px;
}

#reports .btn-contact:hover {
	margin-right:0px !important;
}

#report-field-links .btn-contact:hover {
	margin-right:0px !important;
}

#report-fields .btn-contact:hover {
	margin-right:0px !important;
}

#report-table_wrapper {
	overflow-x: scroll;
	border: 1px solid #e8e8e8;
	margin-top: 10px;
	padding: 10px;
}

#report-table_wrapper::-webkit-scrollbar {
	height: 0.5em;
}

#report-table_wrapper::-webkit-scrollbar-track {
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
}

#report-table_wrapper::-webkit-scrollbar-thumb {
	background-color: $main-brand-colour;
	outline: 1px solid slategrey;
}

.report-heading-name {
	margin-top:0px;
}

.report-new-icon {
	color:white;
	float: left;
	display: inline;
	font-size: 11px;
	line-height: 15px;
	background: #ef7d24;
	margin-top: 0px;
	padding: 0px 4px 0px 3px;
	border-radius: 15px;
	position: relative;
	top: 2px;
	left: 2px;
	margin-right:4px;
}


.parameters-title-row {
	background-color: #3A485C;
	border:1px solid #ddd;
	border-top-left-radius: 5px;
	border-top-right-radius: 5px;
	padding:5px;
	color: white;
	text-align: center;
}

.parameters-lead-row {
	background-color: #e6e6e6;
	border-left:1px solid #ddd;
	border-right: 1px solid #ddd;
	cursor:pointer;
	//border-top-left-radius: 5px;
	//border-top-right-radius: 5px;
	color: #000000;
	text-align: center;

	.active {
		background-color:#eeaa2c;
	}

	.left-col {
		padding-top: 5px;
		padding-bottom: 5px;
		border-right: 1px solid #cdcdcd;
	}

	.center-col {
		padding-top: 5px;
		padding-bottom: 5px;
	}

	.right-col {
		padding-top: 5px;
		padding-bottom: 5px;
		border-left: 1px solid #cdcdcd;
	}

	.param-button {
		font-weight:bold;
	}
}

.report-parameters-body {
  	padding-top:10px;
	border:1px solid #ddd;
}

.attribute-single-container{
	height: 50px;
}