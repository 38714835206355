div.data-table-action{
	color: $main-brand-colour;
    cursor: pointer;
}

div.booked-absence-container{
	table{
		margin-left :0px;
	}

	.fc-button{
		height:auto;
	}

	.fc-day-grid-event .fc-content{
		font-size: 14px;
		font-weight: bold;
	}
}

div.advanced-form-group{
	margin-bottom: 12px;
	label{
		font-weight: bold;
		margin-bottom: 0px;
	}
}

#user-task-notification-content {
	.todo-tab-content {
		padding-bottom:10px;
	}
}

.notifications-display-wrapper {
	.user-task-notification-content {
		margin-left:20px;
		margin-bottom:20px;
		a {
			color:black;
			text-decoration: underline;
		}
	}
}

#todo-container, #user-task-notification-content {
	color:black;
	
	.todo-tab-content-title {
		margin-left:1px;
		background-color: #4774b7;
		padding: 5px 10px 5px 10px;
		color:white;
		height: 30px;
	}
	.user-tasks-tab-container {
		.add-user-task-container {
			padding:10px;
		}
	}
	.user-tasks-container {
		padding: 5px;
		background: #f3f3f3;
		border-radius: 8px;
		margin-top: 8px;
		.user-task-red {
			border: 1px solid #f64853;
			background: #fff0f0;
		}

		.user-task-blue {
			border: 1px solid #0068af;
			background: #e8f3ff;
			div.user-task-check .fa.fa-square-o {
				color: #565fd6;
			}
		}
		
		.user-task-green {
			border: 1px solid #00af00;
			background: #e8ffe8;
		}
		div.user-task-info {
			padding-right: 15px;
		}
		
		.user-task {
			margin: 7px 0px;
			padding: 5px;
			line-height: 18px;
			border-radius: 5px;					
			
			.user-task-check {
				font-size: 15px;
				float: left;
				margin-right: 5px;
				line-height: 16px;
				.fa.fa-square-o {
					color: #d65656;
					font-weight: bold;
				}
				.fa.fa-check-square-o {
					color: #5ead19;
				}
			}
			.user-task-user {
				//display: inline-block;
				font-size: 13px;
			}
		}
	}
}
.user_files_section {
	min-height: 50%;
	.breadcrumbs-container {
		width: 50%;
		margin: auto;
		border: solid 1px grey;
		margin-bottom: 10px;
		padding: 5px;
		.folder-breadcrumb-item{
			text-decoration: underline;
			color:#4774b7;
			cursor: pointer;
		}
	}
	
	.folder-action-buttons-wrapper {
		width: 50%;
		margin: auto;
		padding: 10px;
	}
	.user-folders-list, .file-view-wrapper {
		text-align:center;
		.large-icons {
			font-size: 4em;
			color:#4774b7;
		}
		.large-icons.fa-folder {
			color:black
		}
		.large-icon-overlay {
			position: absolute;
			top: 18%;
			right: 41%;
			// color: #4774b7;
			font-size: 2em;
		}
		.shared-folder-icon-wrapper {
			position: absolute;
			top: 28%;
			right: 4%;
			color: red;
			.folder-icon-overlay {	
				font-size: 2em;
			}
			.folder-icon-sub-text {
				font-size:12px;
			}
		}
		.folder-name {
			text-align:center;
		}
	}
	.context_menu {
		display: none;
		position: absolute;
		margin: 10px 0px;
		left: 0;
		top: 0;
		background-color: white;
		border: solid 1px grey;
		border-radius: 10px;
		padding:10px;
		.context-menu-items {
			margin-top:10px;
			.row {
				margin:0;
			}
			.btn {
				margin: 5px 0px;
				width: 100%;
			}
			.btn.btn-warning {
				background-color: #f0ad4e;
    			border-color: #eea236;
			}
			.btn.btn-warning:hover {
				background: #4774b7;
				border: 1px solid transparent;
			}
		}
		.context-menu-dismiss {
			position:absolute;
			right: 6px;
			top: 3px;
			padding:5px;
		}
	}
	
}
.folder-explorder {
	.user_files_section {
		.user-folders-list, .file-view-wrapper {
			.large-icon-overlay {
				position: absolute;
				top: 18%;
				right: 44%;
				// color: #4774b7;
				font-size: 2em;
			}
			.shared-folder-icon-wrapper {
				position: absolute;
				top: 28%;
				right: 4%;
				color: red;
				.folder-icon-overlay {	
					font-size: 2em;
				}
				.folder-icon-sub-text {
					font-size:12px;
				}
			}
		}
	}

}
#createUserFolderModal, #deleteUserFolderModal, #shareUserFolderModal {
	h4 {
		text-align: center;
		margin-top:10px;
	}
	#create-user-folder-form {
		margin-top:20px;
	}
	.row {
		margin:0;
	}
}

#userDocumentUploadModal {
	#user-documents-dropzone {
		padding:20px;
		margin:20px;
		border: solid 1px black;
		border-radius: 15px;
		min-height: 250px;
		text-align: center;
		overflow: hidden;
		.instruction-to-drop {
			width: 50%;
			margin: auto;
			text-align: center;
			padding-top: 10%;
			font-size: 1.5em;
			color: grey;
		}
		.file-icon-container {
			width:80%;
			padding:10px;
			margin:auto;
			text-align:center;
			.large-icons {
				font-size:5em;
			}
		}
		.dz-preview {
			width:30%;
			float:left;
			.dz-filename {
			    word-wrap: break-word;
				width: 60%;
				text-align: center;
				margin: auto;
			}
		}
	}
}