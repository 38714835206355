.question {
	.name-of-the-thing {
		color: $main-brand-colour; 
	}
	margin-top: 4px;
	width: 73%;
    white-space: nowrap;
    overflow: hidden !important;
    text-overflow: ellipsis;
    padding-left: 5px;
    font-size: 11px;
    display: inline-block;
}

.compliance-header {
	background: #e3eaf4;
	padding: 10px;
	text-align: center;
}

#script-steps, #questions, #edit-questions {
	.action-group-heading {
		margin-bottom: 0px;
		background: $navbar-bg;
	}
}
.affix {
    width: 579px;
	top:0;
}


.compliance-edit-question {
	label {
		&:last-of-type {
			margin-left: 50px;
		}
	}
	a {
		&:hover {
			@include button-standard($hover-button, $white, 10px, 0px);
		}
		white-space: nowrap;
		cursor: pointer;
		@include button-standard($main-brand-colour, $white, 10px, 0px);
		@include transition(background-color 0.5s ease);
	}
	display: flex;
	padding: $standard-padding;
	border-bottom: 1px solid $light-grey;
}

.questions-container {
    max-height: 420px; // 571px is standard size for unfixed modules
    overflow-y: auto;
}

/* ----------------------------- COMPLIANCE ------------------------  */

.compliance-label {
	font-size: 11px;
	color: $main-brand-colour;
	margin-bottom: 0px;
}

.compliance-details {
	font-size: 11px;
	margin-left: 5px;
	display: inline;
}

#compliance_questions, #compliance_feedback, #compliance_feedback_notes {
	margin-left: 11px;
}

.compliance-margin-bottom {
	margin-bottom: 10px;
}

.tickboxes {
	width: 14%;
}

.compliance-error {
	padding-left: 0px;
}

#compliance_question {
	margin-bottom: 10px;
}

#compliance_events {
	margin-top: 10px;
}

.passed-question {
	color: #78bb0b;
}

.failed-question {
	color: #bb0b0b;
}

/* ----------------------------- COMPLIANCE PRINTABLE PAGE ------------------------  */

page[size="A4"] {
	background: white;
	width: 21cm;
	height: 29.7cm;
	display: block;
	margin: 0 auto;
	margin-bottom: 0.5cm;
	box-shadow: 0 0 0.5cm rgba(0,0,0,0.5);
}

/*

	ARGHH - If you are here trying to style a special Willy special PDF look here -- resources/views/components/utils/pdf-download.blade.php

*/


@media print {
	body, page[size="A4"] {
		margin: 0;
		box-shadow: 0;
	}

	.pdf-hide-button {
		display:none;
	}
	
	.pdf-hide {
		display:none !important;
	}

	thead { display: table-header-group }
	tfoot { display: table-row-group }
	tr { page-break-inside: avoid }
	.table-striped{
		overflow: visible !important;

		tfoot{
			display: table-row-group;
		}
		tr{
			page-break-inside: avoid;
		}
	}
}

div.plyr__menu__container {
	bottom: unset;
	top: 100%;
}