#ticket-tasks-list {
	.ticket-task-edit-section {
		label {
			margin-bottom: 0px;
			margin-top: 10px;
		}
	}
}

#ticket_actions {
	.ticket-tasks-container {
		label {
			margin-bottom: 0px;
			margin-top: 10px;
		}
	}
}


div.contact-ticket-list {
	margin-bottom: 10px;
	
	div.closed-tickets-list {
		border: 1px solid #545454;
		background: #313131;
		
		div.closed-tickets-btn {
			cursor: pointer;
			text-indent: 8px;
			background: #545454;
			color: white;
			font-size: 12px;
			padding: 5px 0px;
		}
		
		div.closed-tickets-container {
			margin: 5px;
			.ticket-list-item-block {
				margin: 2px;
			}	
		}
	}
		
	.ticket-list-item-block {
		margin: 5px;
		border: 1px solid #ef9230;
		border-radius: 11px;
		background: #fff;
		
		.ticket-item-button {
			padding: 8px;
			background: #ef9230;
			border-radius: 8px;
			border: 1px solid #ef9230;
			color: white;
			cursor: pointer;
			
			span.ticket-item-name {
				display: inline-block;
				width: 70%;
				line-height: 18px;
			}
			
			span.ticket-list-item-status {
				float: right;
				background: white;
				border-radius: 10px;
				color: #355a92;
				padding: 2px 10px;
				font-size: 11px;
				font-weight: bold;
				
				.snoozed-ticket-status {
					color: #ff4800;
				}
			}
		}
		
		.ticket-item-description {
			padding: 10px;
			font-size: 12px;
			background: #f3f3f3;
			margin: 7px 0px;
			border-radius: 8px;
			
			.hide-description-btn {
				float: right;
				background: #888888;//#ca451b;
				color: white;
				padding: 1px 8px;
				border-radius: 10px;
				cursor: pointer;
			}
			
		}
		
		.tab-content {
			border-top: none;
			margin-top: 8px;
			
			.quick-ticket-actions {
				margin: 10px;
				padding: 5px 11px;
				text-align: center;
				background: #eee;
				border-radius: 8px;
				margin-bottom: 0px;
				.quick-action-buttons-container {
					text-align: right;
					display:inline-block;
					.quick-actions-triggers {
						display: inline;
					}
				}
			}
			
			
			.quick-actions-tasks {
				input {
					height: 30px;
					margin: 2px 0px;
					background-color: #fff;
					border: 1px solid #aaa;
					border-radius: 4px;
				}
				button {
					width: 100%;
					margin: 0px;
				}
				.ticket-tasks-container {
					padding: 5px;
					background: #f3f3f3;
					border-radius: 8px;
					margin-top: 8px;
					
					.ticket-task-red {
						border: 1px solid #f64853;
						background: #fff0f0;
					}
					
					.ticket-task-blue {
						border: 1px solid #0068af;
						background: #e8f3ff;
					}

					.ticket-task-green {
						border: 1px solid #00af00;
						background: #e8ffe8;
					}
					
					.ticket-task {
						margin: 7px 0px;
						padding: 5px;
						line-height: 18px;
						border-radius: 5px;
						
						.ticket-task-check {
							font-size: 15px;
							float: left;
							margin-right: 5px;
							line-height: 16px;
							
							.fa.fa-square-o {
								color: #d65656;
								font-weight: bold;
							}
							.fa.fa-check-square-o {
								color: #5ead19;
							}
						}
						.ticket-task-user {
							//display: inline-block;
							font-size: 13px;
						}
					}
				}
			}
		}
		
		.ticket-list-item-tabs {
			margin: 5px;
			ul.nav-tabs li {
				margin: 1px;
				a {
					border-radius: 10px;
				}
			}
			
			.ticket-snoozed-banner {
				text-align: center;
				font-size: 14px;
				padding: 5px;
				color: white;
				border-radius: 5px;
				background: #ff4800;
			}
		}
		
		.ticket-items-content {
			.ticket-item {
				margin: 8px 2px;
				border: 1px solid lightgray;
				border-radius: 8px;
				background: #fff;
				
				.ticket-item-email-addresses {
					padding: 3px 10px;
					background: #d5eaea;
					font-size: 12px;
				}

				.ticket-item-type {
					background: #3A485C;
					color: white;
					font-weight: bold;
					padding-left: 10px;
					border-top-left-radius: 5px;
					border-top-right-radius: 5px;
					padding-bottom: 3px;
					padding-top: 3px;
					
					.toggle-ticket-item-content {
						float: right;
						padding: 2px 10px;
						font-size: 12px;
						font-weight: normal;
						text-decoration: underline;
						cursor: pointer;
					}	
				}

				.ticket-item-type.note {
					background: #7575b1;
				}
				.ticket-item-type.correspondence {
					background: #419cb9;
				}
				.ticket-item-type.event {
					background: grey;
				}

				.ticket-item-header {
					padding-left: 10px;
					padding-top: 3px;
					padding-bottom: 3px;
					border-bottom: 1px solid #eee;
				}

				.ticket-item-content {
					width:100%;
					overflow: auto;
					padding: 5px;
					white-space: pre-line;
					display: grid;
				}

				.ticket-item-content > * {
					white-space: normal;
				}

				.ticket-item-signature {
					text-align: right;
					padding-right: 5px;
				}
				
				p, p.ticket-long-date {
					font-size: 12px;
					margin-bottom:2px;
				}
				
			}

			.ticket-item.transaction {
				display: none;
			}
		}
	}
	
	div.ticket-side-bar {
		h4 {
			padding: 4px 8px;
			font-size: 14px;
		}
		
		.ticket-info-section {
			padding: 4px;
		}
		
		.ticket-info-block {
			margin-top: 5px;
			padding: 0px;
			border-radius: 0px;
			label {
				font-size: 13px;
				margin: 0px;
			}
			.ticket-info {
				font-size: 14px;
			}
			p, p.ticket-long-date {
				margin: 0px;
			}
		}
	}
	
	div.ticket-links,div.ticket-actions {
		h4 {
			padding: 4px 8px;
			font-size: 14px;
		}
	}
	
}

.add-all-border-radius.remove-bottom-border-radius {
	border-top-left-radius: 8px;
	border-top-right-radius: 8px;
	border-bottom-left-radius: 0px;
	border-bottom-right-radius: 0px;
}

.add-all-border-radius {
	border-radius: 8px;
}

div.queue-summary {
	.panel-body {
		.ticket-managing{
			float:right;
		}
	}
}

.nav-tabs.queue-names > li.active > a, 
.nav-tabs.queue-names > li.active > a:hover, 
.nav-tabs.queue-names > li.active > a:focus {
	font-size: 14px;
}

div.ticket-filters {
    width: 25%;
    text-align: right;
	float: right;
		
	select {
		font-size:12px;
		border-radius: 8px;
		padding-left: 1px;
		height: 30px;
		outline: none;
		width:25%;
	}
	input {
		outline: none;
		width: 72%;
		border-radius:8px;
		border: 1px solid #ddd;
		//margin: 5px 0px;
		padding-left: 8px;
		height: 30px;
	}
}

ul.queue-names {
	display: inline-block;
    width: 73%;
	font-size: 14px;
	.queue-count {
		margin-left: 5px;
		padding: 2px 6px;
		font-weight: bold;
		border: 1px solid #fff;
		border-radius: 10px;
		background: white;
		color: #0f76bc;
		font-size: 12px;
	}
}

div.queue-tab-content {
	
	.panel-group {
		.panel-default {
			border: 1px solid #4774b7;
			margin: 10px 0px;
			border-radius: 9px;
			.panel-heading.collapsed {
				border-radius: 8px;
			}
			.panel-heading {
				color: #ffffff;
				background-color: #4774b7;
				padding: 5px 15px;
				border-bottom-left-radius: 0px;
				border-bottom-right-radius: 0px;
			}			
			.ticket-footer-span {
				margin-right: 25px;
			}
			
			.ticket-assignee-select, .ticket-priority-select {
				select {
					border-radius: 5px;
				}
			}
			
/*			.ticket-priority-select select {
				width: 10%;
			}
			
			.ticket-assignee-select select {
				width: 22%;
			}*/
		}

		.panel-body {
			white-space: pre-wrap;
			padding: 5px 15px;
			border: none;
			background: white;
		}
		.panel-footer {
			background: rgba(71, 116, 183, 0.15);
			padding: 5px 15px;
			color: #337ab7;
			border-top: 1px solid darkgrey;
		}
	}
	
	.panel-title {
		float:none;
		display:inline-block;
		width:92%;
	}
	
	.snooze-ticket-trigger, .edit-ticket-trigger {
		color:white;
		width: 2%;
		font-size: 20px;
		display: inline-block;
		text-align: center;
	}
	
	.expand-panel  {
		width: 2%;
		font-size: 20px;
		display: inline-block;
		text-align: center;
		i.fa.fa-caret-down {
			width: 100%;
		}
	}
}

.quick-actions-tasks{
	padding: 10px;
}

div.ticket-side-bar {
	h4 {
		background: #3A485C;
		color: white;
		padding: 5px 10px;
	}
	
	.ticket-info-section {
		padding: 10px;
		h3 {
			margin-top: 10px;
			font-size:10px;
		}
	}
	
	div.ticket-info-block {
		margin-top: 5px;
		
		border-radius: 5px;
		
		padding: 3px 5px;
		.ticket-info {
			font-size: 14px;
			font-weight: bold;
		}
		.ticket-edit {
			width:75%;
			display:inline-block;
		}
		p {
			display:inline-block;
		}
		
		.ticket-block-label {
			width: 23%;
		}
		.requester-input {
			width: 60%;
			border-radius: 3px;
    		font-weight: normal;
		}
	}
	
	div.ticket-sidebar-save-button {
		text-align: right;
		margin: 10px;
	}
}

div.ticket-links,div.ticket-content,div.ticket-actions {
	h4 {
		background: #3A485C;
		color: white;
		padding: 5px 10px;
	}
	
	.ticket-info-section {
		padding: 10px;
	}
}


div.ticket-links {
	.linked-contacts-list {
		.linked-contacts-list-item {
			padding: 3px 10px;
			.contact-item {
				width:100%;
				border: 1px solid #aaa;
				padding: 5px;
				border-radius: 5px;
				background: #e4e4e4;
				.remove,.expand {
					width: 6%;
					display:inline-block;
					cursor: pointer;
					text-align: center;
				}
				.name {
					width:85%;
					display:inline-block;
				}
				
				.linked-contacts-list-item-detail {
					font-size: 12px;
					background: #f5f5f5;
					border-radius: 5px;
					margin-top: 2px;
					padding: 5px;
					ul {
						padding: 0 18px;
						
					}
				}
			}
		}
	}
	padding-bottom: 8px;
}

p.ticket-long-date,span.ticket-long-date {
	font-size: 12px;
}

div.ticket-sidebar-ticket-actions {
	.quick-ticket-actions {
		margin: 10px;
		padding: 5px 11px;
		text-align: center;
		background: #eee;
		border-radius: 8px;
		margin-bottom: 0px;
		.quick-action-buttons-container {
			text-align: right;
			display:inline-block;
			.quick-actions-triggers {
				display: inline;
			}
		}
	}
	.ticket-actions {
		.quick-actions-triggers {
			display:inline-block;
		}
		.quick-actions-tasks {
			margin-top: 10px;

			input {
				margin: 2px 0px;
				height: 27px;
				background-color: #fff;
				border: 1px solid #aaa;
				border-radius: 4px;
				color: #333;
				font-size: 14px;
			}
			
			.ticket-tasks-container {
				padding: 5px;
				background: #f3f3f3;
				border-radius: 8px;
				margin-top: 8px;
				.ticket-task-red {
					border: 1px solid #f64853;
					background: #fff0f0;
				}
				
				.ticket-task-blue {
					border: 1px solid #0068af;
					background: #e8f3ff;
					div.ticket-task-check .fa.fa-square-o {
						color: #565fd6;
					}
				}
				
				.ticket-task-green {
					border: 1px solid #00af00;
					background: #e8ffe8;
				}
				
				.ticket-task {
					margin: 7px 0px;
					padding: 5px;
					line-height: 18px;
					border-radius: 5px;					
					
					.ticket-task-check {
						font-size: 15px;
						float: left;
						margin-right: 5px;
						line-height: 16px;
						.fa.fa-square-o {
							color: #d65656;
							font-weight: bold;
						}
						.fa.fa-check-square-o {
							color: #5ead19;
						}
					}
					.ticket-task-user {
						//display: inline-block;
						font-size: 13px;
					}
				}
			}

		}
	}
}


div.trigger-group-update.queue{
	
	display:block;
	
	div.queue-edit {
		width: 100%;
		padding: 5px 0px 5px 0px;
		display: block;
	}
}

div.tag-team-selector{
	padding: 10px 5px 0px 5px;
	
	button[aria-expanded="true"]{
		background: #4774b7;
	}
	button[aria-expanded="false"]{
		background: #3A485C;
	}
}
	
div.ticket-content {
	
	.ticket-snoozed-banner {
		background: #ff4800;
		padding: 10px;
		color: white;
		font-size: 15px;
	}
	
	.note-editable{
		min-height: 200px;
	}
	
	input.response-tab-header{
		margin: 10px;
		margin-left: 0px;
		border-radius: 5px;
		border: 1px solid #aaa;
		width: 50%;
		padding: 5px;
	}
	
	button.btn.send-button{
		background-color: #4774b7;
	}
	
	.ticket-scheduled-banner {
		background: #4774b7;
		padding: 10px;
		color: white;
		font-size: 15px;
	}
	
	textarea {
		min-width: 100%;
		max-width: 100%;
		min-height: 200px;
	}
	
	.fuel-select li a {
		padding : 8px;
	}
	
	div.ticket-top-content {
		border: 1px solid lightgray;
		border-radius: 5px;
		background: #f1f1f1;
		padding: 3px 5px;
		
		.ticket-content-controls {
			text-align: right;
			.edit-ticket-description {
				display: inline-block;
				padding: 4px 10px;
				color: white;
				background: #a8ca5c;
				font-size: 15px;
				border-radius: 4px;
			}
		}
		
		textarea[readonly] {
			background: #f1f1f1;//#4774b7;
			border: 2px solid lightgray;
			border-radius: 5px;
			padding: 5px;
		}
	}
	
	div.ticket-bottom-content {
		.tab-content {
			border-top: none;
			
			.note-tab-container, .response-tab-container {
				margin-top:5px;
			}
			
			.response-tab-container {
				textarea {
					border-radius: 5px;
					border: 1px solid #aaa;
					margin-bottom: 5px;
				}
				textarea:focus {
					outline: none;
				}
			}
			
			.note-tab-container {
				.note-tab-header {
					text-indent: 5px;
					width: 30%;
				}
				.note-tab-header, .note-tab-textarea {
					margin-bottom: 5px;
					border-radius: 5px;
					border: 1px solid #aaa;
				}
				.note-tab-header:focus,.note-tab-textarea:focus {
					outline: none;
				}
			}
			
		}
		.fuel-select {
			margin-bottom: 5px;
			li {
				a {
					background: #e8e8e8;
					color: black;
					border-bottom-left-radius: 8px;
					border-bottom-right-radius: 8px;
					cursor: pointer;
				}

				a.response {
					background: #419cb9;
					color: white;
					border: 1px solid #419cb9;
				}

				a.note {
					background:#7575b1;
					color: white;
					border: 1px solid #7575b1;
				}

				a.event {
					background: grey;
					color: white;
					border: 1px solid grey;
				}

				a:hover, a.response:hover, a.note:hover, a.event:hover {
					border-color: #3A485C;
					background: #3A485C;
					color: white;
				}
			}
		}
		
		.ticket-tags-li{
			width: 70%;
			float: right;
			.ticket-tag-links {
				display: inline-block;
				width: 90%;
			}
		}
	}
}

div.ticket-timeline {
	.ticket-items-content {
		padding: 0 10px;
		
		.ticket-item-content {
			white-space: pre-line;
			display: grid;
		}
		
		.ticket-item-content > * {
			white-space: normal;
		}
		
		.ticket-item {
			margin-top: 10px;
			border: 1px solid lightgray;
			border-radius: 8px;
			
			.ticket-item-email-addresses {
				padding: 3px 10px;
				background: #d5eaea;
			}
			
			.ticket-item-type {
				background: #3A485C;
				color: white;
				font-weight: bold;
				padding-left: 10px;
				border-top-left-radius: 5px;
				border-top-right-radius: 5px;
				padding-bottom: 3px;
				padding-top: 3px;
				
				.toggle-ticket-item-content {
					float: right;
					padding: 2px 10px;
					font-size: 12px;
					font-weight: normal;
					text-decoration: underline;
					cursor: pointer;
				}		
			}
			
			.ticket-item-type.note {
				background: #7575b1;
			}
			.ticket-item-type.correspondence {
				background: #419cb9;
			}
			.ticket-item-type.event {
				background: grey;
			}
			
			.ticket-item-header {
				padding-left: 10px;
				padding-top: 3px;
				padding-bottom: 3px;
				border-bottom: 1px solid #eee;
			}
			
			.ticket-item-content {
				padding: 10px;
			}
			
			.ticket-item-signature {
				text-align: right;
				padding-right: 10px;
				padding-bottom: 5px;
				padding-top: 5px;
				border-bottom-left-radius: 7px;
				border-bottom-right-radius: 7px;
				p {
					margin-bottom: 0px;
				}
			}
		}
		
		.ticket-item.transaction {
			display: none;
		}
		
		
	}
	p.search-timeline {
		text-align: right;
		padding-right: 10px;
		padding-top: 7px;
		input.search-timeline-input {
			outline: none;
			border-radius: 5px;
			border: 1px solid #d4d4d4;
			text-indent: 5px;
			width: 40%;
		}
		input.search-timeline-input:hover {
			border: 1px solid grey ;
		}
	}
}

div.ticket-timeline-tabs {
	ul.nav.nav-tabs.fuel-select {
		li {
			a {
				background: #e8e8e8;
				border: 1px solid #e8e8e8;
				padding: 3px 10px;
				cursor: pointer;
			}
			
			a:hover, a.response:hover, a.correspondence:hover, a.note:hover, a.event:hover {
				border-color: #3A485C;
				background: #3A485C;
				color:white;
			}
			
			a.response, a.correspondence {
				background: #419cb9;
				color:white;
				border: 1px solid #419cb9;
			}
			
			a.note {
				background:#7575b1;
				color: white;
				border: 1px solid #7575b1;
			}
			
			a.event {
				background: grey;
				color: white;
				border: 1px solid grey;
			}
		}		
	}
}


div div.ticket-timeline-tabs ul.nav.nav-tabs.fuel-select li.tab-active {
	a {
		border-color: #3A485C;
		background:#3A485C;
		color:white;
	}
}

div.ticket-timeline.module-inner {
	border-top-left-radius: 0px;
	padding-bottom: 10px;
}

div.ticket-description{
	white-space: pre-line;
}

div.select2-container.small{
	width:15%
}

button.btn-danger{
	background: #a50618;
}

div.ticket-modal {
	h5.modal-title {
		display: inline;
		font-size: 22px;
	}
	
	div.modal-body {
		input:focus, textarea:focus {
			outline: none;
		}
		
		div.ticket-edit {
			width: 100%;
		}
		
		input.ticket-edit, select.ticket-edit {
			width: 100%;
			text-indent: 5px;
		}
		
		input.ticket-edit, select.ticket-edit {
			border-radius: 8px;
			border: 1px solid #aaa;
			padding: 5px 0px;
		}

		label.ticket-block-label {
			margin-top: 7px;
			margin-bottom: 2px;
		}
		
		.ticket-top-content {
			margin-top: 15px;
			textarea {
				width: 100%;
				border-radius: 8px;
				padding: 6px;
			}
		}
	}
}

div.ticket-make-event-button-container{
	text-align: center;
	padding:10px;
}


div.email-template-picker-modal, div.create-email-wysiwyg-modal, div.add-contacts-modal  {
	input, select {
		height: 30px;
		font-size: 14px;
	}
}

div.create-email-wysiwyg-modal {
		input.subject-input, form.attachments-form {
		display: inline-block;
		width: 80%;
	}
	
	input.subject-input {
		margin-bottom: 10px;
	}
	
	form.attachments-form {
		input {
			border: none;
		}
	}
}

div.add-contacts-modal {
	
	input[type="checkbox"] {
		height: unset;
	}
	
	select.field-type {
		// width: 6%;
		display: inline;
		margin-right: 5px;
	}
	
	div.add-new-recipient-container {
		display: block;
		margin-bottom: 20px;
	}
	
	div.add-contact-container { 
		input.recipient-email-input {
			// width: 50%;
			display: inline;
		}
		
		button {
			margin: 0px;
			padding: 5px 15px;
			display: inline;
			vertical-align: top;
		}
	}
	
	div.email-contact-container {
		margin-bottom: 5px;
	}
}

div.create-email-preview-modal {
	.modal-body {
		padding: 0px 15px;
		max-height: 600px;
		overflow: auto;
	}
}

div.ticket-task-info {
	padding-right: 15px;
}




div.priority-block{
	
	margin: 5px;
    padding: 10px;
    border-radius: 10px;
	
	border: 2px solid #4774b7;
	span.priority-block-heading {
		color: white;
	}
}

div.priority-block.Very.High {
    background-color: rgb(58, 72, 92);
}

div.priority-block.High {
    background-color: rgba(58, 72, 92, 0.8);
}

div.priority-block.Medium {
    background-color: rgba(58, 72, 92, 0.6);
}

div.priority-block.Low {
    background-color: rgba(58, 72, 92, 0.4);
}

div.priority-block.Low.Very {
    background-color: rgba(58, 72, 92, 0.2);
}





.ticket-queue-edit-input-group{
	display: grid;
	grid-template-columns: 1fr 20%;
}