

.three-column-create-section, .three-column-search-section{
	display: grid;
	grid-template-columns: 6fr 1fr;
	grid-template-rows: 1fr;
	gap: 1px 1px;
	grid-template-areas: "three-column-create-input three-column-create-button";
	padding:10px;
	.three-column-create-input, .three-column-search-input{
		grid-area: three-column-create-input;
		height:100%;
		min-height: 30px;
		&.full-width{
			grid-column: three-column-create-input / three-column-create-button;
		}
	}	
	.three-column-create-button{
		grid-area: three-column-create-button;
		width: 100%;
		margin:0;
	}
}

.three-column-list{
	padding:0px;
	min-height: 100px;
	background-color: #f5f5f5;

	.three-column-item{
		display: grid;
		grid-template-columns: 50px 2fr 1fr ;
		grid-template-rows: 1fr;
		gap: 1px 1px;
		grid-template-areas: "three-column-item-icon-holder three-column-item-content three-column-item-actions";
		border-bottom: 1px solid #cacaca;
		background-color: white;
		height:auto;
		position: relative;

		&.small-actions{
			grid-template-columns: 50px 3fr 1fr ;
		}

		&:first-of-type{
			border-top: 1px solid #cacaca;
		}

		.three-column-icon-holder{
			grid-area: three-column-item-icon-holder;
			.three-column-icon{
				color:white;
			}
		}

		.three-column-content{
			grid-area: three-column-item-content;
			padding: 10px;

		}

		.three-column-actions{
			grid-area: three-column-item-actions;
			.three-column-action{
				cursor: pointer;
				float: right;
				padding: 10px;
				transition: all 0.5s ease;
			}
		}

	}

}


.three-column-list{
	.material-switch{
		padding: 15px 10px;
	}
}


