.eagle-broadband-options, .eagle-mobile-options {
	.broadband-selection, .mobile-selection {
		justify-content: center;
	}
	.remove-warning-circle {
		font-size: 50px;
		margin-top: 25px;
		color: red;
	}
}
.upfront-payment-option-buttons-wrapper {
	margin-top:20px;
	border-top:solid 1px;
	padding-top:10px;
}

.broadband-deposit-input-wrapper, .broadband-payment-day-wrapper {
    width: 50%;
    margin: auto;
    padding: 20px;
    // background-color: #f5f5f5; // A light, modern background
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0,0,0,0.1); // A subtle shadow for depth

    .row {
        margin-bottom: 15px; // Spacing out the rows

        
		label {
			display: block;
			margin-bottom: 5px;
			color: #333; // Darker color for better readability
			font-weight: bold;
			text-align: right;
		}

		select, input[type="text"], input[type="number"] {
			width: 50%;
			padding: 8px 12px;
			border: 1px solid #ccc; // A subtle border
			border-radius: 4px;
			box-sizing: border-box; // Helps with padding issues

			&:focus {
				border-color: #007bff; // Highlight color when focused
				box-shadow: 0 0 0 2px rgba(0,123,255,0.25);
				outline: none;
			}

            // input[disabled] {
            //     background-color: #e9ecef; // Different background for disabled inputs
            //     color: #495057;
            // }
        }
    }
	.add-payment-button-wrapper {
		width:50%;
		margin:auto;
		button#add-deposit-payment-to-broadband-order {
			width: 100%;
			padding: 10px 20px;
			background-color: #007bff; // A nice, bold color
			color: white;
			border: none;
			border-radius: 4px;
			font-size: 16px;
			cursor: pointer;
			transition: background-color 0.3s ease;

			&:hover {
				background-color: #0056b3; // Slightly darker on hover
			}
		}
	}
}
iframe#4g-coverage-checker {
	width:100%;
	height:1000px;
	min-height: 1000px;
}
