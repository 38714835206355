.action-group-heading {
	@include module-headings($background: $action-group-green, $padding: 5px, $radius: 8px, $color: #fff, $margin-bottom: null);
}

.hidden {
	display: none;
}

.show {
	display: unset;
}

.action-group-ul {
	.action-icon {
		@include flex-center;
		width: 50px;
		height: 100%;
		margin-right: 5px;
		float: left;
		color: $white;
		background: #a8c95c;
	}
	.group-li {
/*		&:hover:after {
			cursor: pointer;
			content: "\A";
			width: 88%;
			height: 100%;
			background: rgba(72, 116, 182, 0.18);
			@include absolute(0, 0, 0, 0);
		}*/
		position: relative;
		border-bottom: 1px solid $light-grey;
		height: 40px;
	} 
	.group-name {
		padding: 0.7% 0;
		font-size: $standard-paragraph;
		height: 50%;
		display: inline-block;
	}
	.group-stats {
		height: 50%;
		font-size: 11px;
		padding: 0.7%;
		background: #f1f1f1;
	}
	padding: 0px;
	margin: 0px;
    list-style-type: none;
}

.active-trigger {
	background: $main-brand-colour!important;
}

.rule-icon-small {
	@include trigger-icon($background: $rules-blue);	
}

.action-icon-small {
	@include trigger-icon($background: $action-group-green);	
}

.email-icon-small {
	@include trigger-icon($background: $templating-yellow);
}

.schedule-icon-small {
	@include trigger-icon($background: $navbar-bg);
}

.trigger-pass {
	@include trigger-icon-main($background : #349f09);
	pointer-events: none!important;
}
.trigger-fail {
	@include trigger-icon-main($background : $red-warning);
	pointer-events: none!important;
}

#action-triggers, #saved-info-groups,#saved-info-category-templates, #saved-assets-list, #saved-info-templates,#saved-triggers, #saved-sms-templates, #saved-email-templates, #saved-rules, #saved-actions, 
#pass-rules, #fail-rules, #action-actions, #action-drop, #schedule-actions, #saved-events, #saved-tickers, #saved-profiles, #saved-rss-providers, #saved-rss-feeds, #saved-rss-feed-items {
	.trigger-wrapper, .action-wrapper, .saved-trigger-wrapper, .saved-action
	s-wrapper, .saved-assets-wrapper, .saved-info-wrapper, .saved-sms-wrapper, .saved-email-wrapper, 
	.saved-rules-wrapper, .saved-schedules-wrapper, .schedule-wrapper, .saved-events-wrapper {
		padding: 0px;
		min-height: 80px;
		margin: 0px;
	}
	.trigger-container, .rule-container, .action-container, .schedule-container, .event-container {
		.trigger-icon-small {
			@include trigger-icon;
		}	
		background: $white;
		list-style-type: none;
		width: 100%;
		height: 40px;
		border-bottom: 1px solid $light-grey;
	}
	.trigger-icon {
		@include trigger-icon-main;
	}
	.col-md-10 {
		margin: 0px;
	}
	.trigger, .rule, .action, .schedule {
		float: left;
		padding: $standard-padding;
	}
	.action{
		max-width: 65%;
		max-height: 100%;
		overflow: hidden;
		padding-right:0px;
	}
	position: relative;
	cursor: move;
	border-bottom: 10px solid $light-grey;
}


.delete-rule, .group-stats-nav {
	@include position(absolute, $top: 0, $right: 0);
}

.edit-rule {
	@include position(absolute, $top: 0, $right: 32px);
}

.delete-trigger, .delete-rule, .delete-action, .delete-schedule {
	i {
		&:hover {
			color: $main-brand-colour;
		}
		color: $red-warning;
	}
	cursor: pointer;
	float: right;
	padding: $standard-padding;
	display: inline-block;
	@include transition(all 0.5s ease);
}

.edit-trigger, .edit-rule, .edit-action, .edit-schedule, .edit-event {
	i {
		&:hover {
			color: $hover-button;
		}
		color: $main-brand-colour;
	}
	cursor: pointer;
	float: right;
	padding: $standard-padding;
	display: inline-block;
	@include transition(all 0.5s ease);
}

.add-trigger{
	i {
		&:hover {
			color: $hover-button;
		}
		color: green;
	}
	cursor: pointer;
	text-align: center;
}

.trigger-container, .rule-container, .schedule-container {
	position: relative;
	border-bottom: 1px solid $light-grey;
	height: 50px;
}

#action-triggers, #pass-rules, #fail-rules, #action-actions, #schedule-actions {
	display: flex;
	z-index: 0;
	background: #f5f5f5;	
}

#pass-rules:after {
	@include dropzone-after-content;
}

#fail-rules:after  {
	@include dropzone-after-content("Drop Fail Rules Here");
}

#action-triggers:after  {
	@include dropzone-after-content("Drop Triggers Here");
}

#action-drop:after  {
	@include dropzone-after-content("Drop Core Actions Here");	
}

#action-actions:after  {
	@include dropzone-after-content("Drop Actions Here");
}

#schedule-actions:after  {
	@include dropzone-after-content("Drop Schedules Here");
}

.action-group-create, .actions-create {
	input {
		@include inputs(100%, 30px);
	}
	a {
		&:hover {
			background-color: $hover-button;
		}
		white-space: nowrap;
		cursor: pointer;
		@include button-standard($main-brand-colour, $white, 10px, 0px);
		@include transition(background-color 0.5s ease);
	}
	display: flex;
	padding: $standard-padding;
	border-bottom: 1px solid $light-grey;
}

.rules-create, .trigger-group-create, .action-trigger-create, .trigger-group-update, .schedule-group-update {
	@extend .action-group-create;
}

#rules-edit-container, #rules-saved-container {
	.sales-heading {
		margin-bottom: 0px;
	}
}

.call-event-drop {
	&:hover {
		border: 1px solid $main-brand-colour;
	}
	@include inputs(100%, 30px);
}

#discard-action-options {
	&:hover {
		background: $main-brand-colour;
	}
	background: $red-warning;
	margin-left: 0px;
}

#trigger-associated-actions {
	p {
		margin-bottom: 0px;
	}
	h3 {
		color: $red-warning;
		margin-top: 5px;
		font-size: 16px;
	}
	border-top: 10px solid #ddd;
	padding: $standard-padding;
	background: $white;
	color: $red-warning;
	@include border-radius-bottom($border-radius-modules);
}

#saved-triggers ul li, #saved-rules ul li, #action-triggers ul li, #saved-actions ul li,
#saved-email-templates ul li, #saved-sms-templates ul li,#saved-info-groups ul li, #saved-info-category-templates ul li, #saved-assets-list ul li, #saved-info-templates ul li, #saved-schedules ul li, #saved-events ul li, #saved-tickers ul li {
	&:hover {
		background: $light-blue;
	}
	@include transition(background-color 0.5s ease);
}

.config-rule-form {
	input {
		&:last-of-type {
			margin-bottom: 0px;
		}
		margin-bottom: 10px;
	}
	width: 100%;
}

#saved-triggers, #saved-rules, #saved-actions, #saved-email-templates, #saved-sms-templates, #saved-info-groups, #saved-info-category-templates, #saved-assets-list, #saved-info-templates {
	&:last-of-type {
		border-bottom: none;
	}
}

.trigger-footer {
	height: 10px;
    background: $trigger-orange;
	@include border-radius-bottom($border-radius-modules);
}

.rules-footer {
	background: $rules-blue;
	@extend .trigger-footer;
}

.actions-footer {
	background: $action-group-green;
	@extend .trigger-footer;
}

#fail-rules {
	border-bottom: none;
	border-bottom-right-radius: $border-radius-modules;
}

.action-instructions {
	h3 {
		@include margin(0px 0px 5px 0px);
		font-size: $heading;
	}
	p {
		color: $grey-text;
		margin-bottom: 0px;
		font-size: $standard-paragraph;
	}
	display: block;
	@extend .rules-create;
}

.help {
	i {
		&:hover {
			color: $light-grey;
		}
		font-size: $help-tooltips-size;
		color: $main-brand-colour;
	}
	width: 30px;
    height: 30px;
    margin-left: 10px;
	@extend %flex-x-y-align;
}

.time-selectors {
	.btn {
		&:hover,
		&:active {
			@include button-standard($hover-button, $white, 0px, 0px);
			@include margin(5px);
		}
		width: 33%;
		@include button-standard($main-brand-colour, $white, 0px, 0px);
		@include margin(5px);
	}
	border-radius: 4px;
	background: $light-grey;
	@include padding(5px);
	@include margin(10px);
	@extend %flex-x-y-align;
}
.tab-content {
	border-top: 1px solid #ddd;
}

.inactive {
	&:hover {
		background: $hover-button!important;
	}
	background: #ddd!important;
}

.active-tab {
	&:hover {
		background: $hover-button!important
	}
	background: $hover-button!important
}

.disabled {
	&:hover {
		background: #ddd!important;
	}
	background: #ddd!important;
}

.time-inputs {
	.predefined-times {
		.time-selectors-1 {
			.btn {
				&:hover,
				&:active {
					@include button-standard($hover-button, $white, 0px, 0px);
				}
				width: 100%;
				@include button-standard($main-brand-colour, $white, 0px, 0px);
				@include margin(0px 0px 5px 0px);
			}
			flex-wrap: wrap;
			@include margin(5px 5px 0px 5px);
		}
		@extend %flex-x-y-align;
	}
	.from-to-time {
		p {
			margin-bottom: 5px;
		}
		.input-append {
			input {
				@include inputs(100%, 30px);
				@include padding(5px 5px 5px 30px);	
			}
			span {
				@include absolute(5px, none, none, 10px);
				color: $main-brand-colour;
			}
			position: relative;
		}
		select {
			@include inputs(100%, 30px);
			margin-top: 10px;
		}
		&:first-of-type {
			border-right: 1px solid $light-grey;
		}
		width: 49.6%;
		display: inline-block;
		@include padding(10px);
		
	}
/*	margin-top: 5px;*/
	border-bottom: 1px solid #ddd;
/*	@include padding(0px 5px 10px);*/
	@extend %flex-x-y-align;
}

#specific-date {
	.from-to-time {
		p {
			@include margin(0px 0px 5px 0px);
		}
		input {
			@include margin(0px);
			@include inputs(48%, 30px);
			@include padding(5px);
		}
		select {
			@include margin(0px);
			@include inputs(50%, 30px);
		}
		&:first-of-type {
			border-right: 1px solid $light-grey;
		}
		@include padding(10px);
	}
}

.action-edit {
	&__heading {
		@include module-headings($background: $action-group-green!important, $padding: 5px, $radius: 8px, $color: #fff, $margin-bottom: null);
	}
}

// this is the 1st table in 1st column of the rule edit page
.rule-edit, .action-edit {
	&__heading {
		@include module-headings($background: $training-blue, $padding: 5px, $radius: 8px, $color: #fff, $margin-bottom: null);
	}
	button {
		&:hover {
			background: $hover-button;
		}
		@include button-standard($main-brand-colour, #fff, null, null);
	}
	table {
		thead {
			th {
				&:first-of-type {
					text-align: left;
					padding-left: 10px;
					border-radius: 0px;
				}
				&:last-of-type {
					border-radius: 0px;
				}
				color: #333;
				text-align: center;
				@include padding(10px 0px);
			}
		}
		tbody {
			tr {
				td {
					&:first-of-type {
						padding-left: 10px;
						text-align: left;
					}
					text-align: center;
				}
				border-top: 1px solid #ddd;
				height: 30px;
				@include padding(5px 0px 5px 0px);
			}
		}
		margin: 0px;
		font-size: $standard-paragraph;
	}
}

// this is the 2nd table in 2nd column of the rule edit page
.attribute-table {
	label {
		width: 100px;
	}
	input, 
	select {
		@include inputs(100%, 30px);
		margin-left: 0px;
	}
}

.table-edit-container {
	border-bottom: 1px solid #ddd;
}

.action-group-edit {
	label {
		white-space: nowrap;
		margin-right: 10px;
		margin-bottom: 0px;
		font-size: $standard-paragraph;
	}
	select {
		margin-left: 10px;
		width: 50%;
	}
	@extend %flex-x-y-align;
	@extend .action-group-create;
}

.rule-group-edit {
	input {
		margin-right: 10px;
	}
	label {
		white-space: nowrap;
		margin-right: 10px;
		margin-bottom: 0px;
		font-size: $standard-paragraph;
	}
	select {
		margin-left: 0px;
		width: 100%;
	}
	@extend %flex-x-y-align;
	@extend .action-group-create;
}

.master-edit-container {
	@include padding(0px);
}

#customrange-to, #customrange-from {
    background: #fff;
    cursor: pointer;
    padding: 5px 10px;
    border: 1px solid #ccc;
    min-width: 15%;
    line-height: 1.5;
    border-radius: 4px;
}

#actions-edit-container {
	.actions-create {
		@include padding(0px);
	}
}

.trigger-test-run-cont {
	.simulation-start {
		&:hover {
			@include button-standard($main-brand-colour, $white, 0px, 0px);
			float: left;
		}
		@include button-standard($hover-button, $white, 0px, 0px);
		float: left;
	}
	.save-button {
		&:hover {
			@include button-standard($hover-button, $white, 0px, 10px);
			float: left;
		}
		@include button-standard($main-brand-colour, $white, 0px, 10px);
		float: left;
	}
	.cancel-button {
		&:hover {
			@include button-standard($hover-button, $white, 0px, 0px);
			float: right;
		}
		@include button-standard($red-warning, $white, 0px, 0px);
		float: right;
	}
	padding: 10px;
	height: 50px;
	border-top: 1px solid #ddd;
}

a {
	white-space: nowrap;
	cursor: pointer;

	@include transition(background-color 0.5s ease);
}

#trigger-edit-container {
	.group-information {
		.group-name {
			height: 50%;
			font-size: 12px;
			@include padding(0.7% 0.7% 0.7% 13.3%);
		}
		.group-stats {
			height: 50%;
			display: block;
			font-size: 11px;
			@include padding(0.7% 0.7% 0.7% 13.3%);
			border-top: 1px solid #ddd;
			background: $light-blue;
		}
		width: 86%;
		height: 100%;
		border-right: 1px solid #ddd;
	}
}

#rules-saved-container {
	.group-information {
		.group-name {
			height: 100%;
			font-size: 14px;
			@include padding(1.7% 0.7% 1.7% 11%);
		}
		.group-stats {
			display: none;
			height: 50%;
			font-size: 11px;
			@include padding(0.7% 0.7% 0.7% 11%);
			border-top: 1px solid #ddd;
			background: $light-blue;
		}
		width: 88%;
		height: 100%;
		border-right: 1px solid #ddd;
	}
}

.config-action-form {
	width: 100%;
}

.back-to-previous {
	margin-left: 10px;
}

.update-success {
	@include notifications;
	background: success(0.8); // this is a function which can be found in _mixins.scss
}

.update-error {
	@include notifications;
	background: error(0.8); // this is a function which can be found in _mixins.scss
}

.module-error {
	@include padding(5px 10px 5px 10px);
	color: #fff;
	font-size: 12px;
	background: error(0.8); // this is a function which can be found in _mixins.scss
}

.cancel-button {
	@include button-standard();
	float: right;
}

.col-footer {
	.cancel-button {
		&:hover {
			@include button-standard($hover-button, $white, 0px, 0px);
			float: right;
		}
		@include button-standard($red-warning, $white, 0px, 0px);
		float: right;
	}
	.save-button {
		&:hover {
			@include button-standard($hover-button, $white, 0px, 0px);
			float: left;
		}
		@include button-standard($main-brand-colour, $white, 0px, 0px);
		float: left;
	}
	padding: $standard-padding;
	height: 50px;
}

.dropdown-container-action-groups {
	cursor: pointer;
	transition: all 0.5s ease;

	.dropdown-component-container {
		padding: 13px;
		margin-top: -5px;
		cursor: pointer;
		position: absolute;
		right: 53px;
		top: 1px;
	}
}