$nice_ish_green : #a8ca5c;

.toggle-pointer{
	cursor: pointer;
}
.status-summary-list-toggle {
	padding-left: 25px;
    font-size: 12px;
}

.small-profile-picture{
  height: 30px;
  width: 30px;
}
.dot-online:before {
    content: ' \25CF';
    font-size: 15px;
   	color:green;
}
.dot-offline:before {
    content: ' \25CF';
    font-size: 15px;
}

.bg-aqua {
    background-color: #00c0ef !important;
}
.bg-green {
    background-color: #00a65a !important;
}
.bg-yellow{
    background-color: #f39c12 !important;
    }
.bg-red{
    background-color: #dd4b39 !important;
}

.small-box {
	h3 {
		font-size: 38px;
		font-weight: bold;
		white-space: nowrap;
		@include margin(0 0 10px 0);
		@include padding(0);
		color: $white;
	}
	p {
		z-index: 5;
		color: $white;
	}
	.icon {
		@include transition(all .3s linear);
		@include absolute(-10px, 10px, 0, 0);
		@include padding(10px 0 0 0);
		z-index: 0;
		font-size: 90px;
		color: rgba(0,0,0,0.15);
	}
	.inner {
		padding: 10px;
		background-color: $module-bg;
		color: $main-brand-colour;
	}
	.small-box-footer {
		position: relative;
		text-align: center;
		padding: 3px 0;
		color: #fff;
		display: block;
		z-index: 7;
		background: rgba(0,0,0,0.1);
		text-decoration: none;
	}
	border-radius: 2px;
    position: relative;
    display: block;
    margin-bottom: 20px;
    box-shadow: 0 1px 1px rgba(0,0,0,0.1);
}


.box {
    .box-primary {
		border-top-color: #3c8dbc;
    }
    .with-border {
		border-bottom: 1px solid #f4f4f4;
    }
	position: relative;
    border-radius: 3px;
    background: #F1F1F1;
    border-top: 3px solid #d2d6de;
    margin-bottom: 20px;
    width: 100%;
    box-shadow: 0 1px 1px rgba(0,0,0,0.1);
}

.box-header {
    .box-title {
		display: inline-block;
		font-size: 18px;
		margin: 0;
		line-height: 1;
    }
    .box-tools {
		@include absolute(5px, 10px, 0, 0);
    }
	color: #444;
    display: block;
    padding: 10px;
	@include padding(10px);
    position: relative;
}

.box-header, .box-body, .box-footer{
	&:before {
		content: " ";
		display: table;
	}
}

.btn {
    border-radius: 3px;
    -webkit-box-shadow: none;
    box-shadow: none;
    border: 1px solid transparent;
}

.box-body {
	@include border-radius-top(0);
	@include border-radius-bottom(3px);
    @include padding(10px);
}

.info-box {
    .progress {
		background: rgba(0,0,0,0.2);
		@include margin(5px -10px 5px -10px);
		height: 2px;
		border-radius: 0;
    }
	display: block;
    min-height: 90px;
    background: #F1F1F1;
    width: 100%;
    box-shadow: 0 1px 1px rgba(0,0,0,0.55);
    border-radius: 2px;
    margin-bottom: 15px;
}

.info-box-icon {
    border-top-left-radius: 2px;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 2px;
    display: block;
    float: left;
    height: 90px;
    width: 90px;
    text-align: center;
    font-size: 45px;
    line-height: 90px;
    background: rgba(0,0,0,0.2);
    color: $white;
}

.info-box-content {
    @include padding(5px 10px);
    margin-left: 90px;
}

.info-box-text {
    text-transform: uppercase;
}

.info-box-number {
    display: block;
    font-weight: bold;
    font-size: 18px;
}

.chart {
    width: 100%;
    height: 150px;
}

.module-inner {
    border: 1px solid $bordergrey;
    margin-bottom: 20px;
	border-radius: 8px;
	min-height: 100px;
	overflow: hidden;
}

.dashboard-toolbar {
	p {
		display: inline;
		line-height: 2;
	}
	background: $light-blue;
	@include padding(10px);
	border-radius: 8px;
	margin-bottom: 20px;
	height: 50px;
}

#reportrange {
	background: #fff; 
	cursor: pointer; 
	@include padding(5px 10px); 
	border: 1px solid #ccc; 
	min-width: 15%;
	line-height: 1.5;
    border-radius: 4px;
}

.comission-heading {
	background: $training-green;
	@include padding(5px 0 0 10px);
	@include border-radius-top(8px);
	color: #fff;
	margin-bottom: 5px;
}

.team-table {
    font-size: 11px;
}

.team-heading {
	@include dashboard-modules($training-orange, 0px);
}

.sales-heading {
	@include dashboard-modules($training-blue, 5px);
}

.breaks-heading {
	@include dashboard-modules($dashboard-pink, 5px);
}

.call-heading {
	@include dashboard-modules($training-green, 5px);
}

div.col-lg-5, div.col-lg-4, div.col-lg-3{

	.sales-result {
		font-size: 1.8vw;
		margin-top: 0px;
		margin-bottom: 0px;
		color: $grey;
		text-align: center;
	}
	
	.dashboard-icons {
		margin-top: 5px;
		width: 80%;
	}
	
	.hide-small{
		display:none;
	}
}

div.col-lg-6{

	.sales-result {
		font-size: 40px;
		margin-top: 0px;
		margin-bottom: 0px;
		color: $grey;
		text-align: right;
	}
	
	.dashboard-icons {
		margin-top: 5px;
		width: 80%;
	}
}

.sales-detail {
	text-align: right;
}

.statistic-result {
	font-size: 75px;
    margin-top: 0px;
	color: $training-green;
	text-align: right;
}

.sales-icon {
	color: $training-blue;
}

.comission-icon {
	color: $training-green;
}

.call-stat-icons {
	margin-top: 5px;
	width: 50%;
}

.you-clever {
	width: 20px;
	margin-left: 10px;
}

table.dataTable {
	border-radius: 8px;
	margin: 0px!important;
    width: 100%;
}

.table > thead > tr > th, .table > thead > tr > td, .table > tbody > tr > th, .table > tbody > tr > td, .table > tfoot > tr > th, .table > tfoot > tr > td {
	vertical-align: middle;
}

.ranges { 
	li {
		&.active, &:hover {
			background: $main-brand-colour!important;
			color: #fff!important;
		}
		color: $main-brand-colour!important;
	}
}

.bg-aqua {
  background-color: $main-brand-colour;
}

.product-type-drop {
	width: auto!important;
	@include margin(0px 5px 0px 5px);
}

div.date-picker-holder{
	text-align : right;
}

input.date-filter.dash-comparison{
	width : 260px;
}

.group-filter {
	color: #00911f;
	font-size:26px;
	padding-left:20px;
	padding-top:2px;
}

.group-filter-container {
	float: right;
	border-bottom: 1px solid #ddd;
	width: 10.5%;
	height:31px;
}

.group-filter-placeholder {
  background-color:#ececec;
  border-bottom:1px solid #ddd;
  margin:0px;
  display: inline-block;
}


.remove-dashboard-tab{
	display: inline-block;
    margin-left: 8px;
	cursor: pointer;
	
	&:hover{
		color: $nice_ish_green;
	}
}

.dashboard-config-drop-section{
	.ui-sortable-handle{
		width: auto;
		border-bottom: none;
	}
}

.dashboard-grid-stack, .dashboard-component-grid-stack{
	background-color: whitesmoke;
	min-height: 80px;

	.dashboard-grid-item{
		div.grid-stack-item-content{
			background-color: white;
			border-radius: 5px;
			border: 2px solid #4774b7;
			margin-bottom: 5px;
			cursor: grab;
			transition-duration: 0.1s;
			&:hover{
				box-shadow : 0 0 1.5rem #a0a0a0
			}

			.item-header{
				background-color: #4774b7;
				color: white;
				text-align: center;
				padding: 5px;

				div.component-name{
					text-overflow: ellipsis;
					width: 80%;
					margin: auto;
					overflow: hidden;
					white-space: nowrap;
				}

				.edit-dash-component{
					display: inline-block;
					margin-top: -5px;
					cursor: pointer;
					padding: 5px;
				}

				.dashboard-component-dropdown{
					padding: 5px;
					margin-top: -5px;
					cursor: pointer;
					position: relative;
					position: absolute;
					right: 5px;
					top: 5px;
					background-color: #4774b7;

					&:hover{
						.dashboard-component-menu{
							display: block;
							background-color: white;
							position: absolute;
							right: 0px;
							color: #4774b7;
							padding: 5px;
							border: 1px solid #4774b7;
							border-radius: 5px;
							width: max-content;
							div{
								display: inline-block;
								margin-top: -5px;
								cursor: pointer;
								padding: 5px;
								i{
									&:hover{
										color: $nice_ish_green;
									}
								}
							}
						}
					}
					.dashboard-component-menu{
						display: none;
					}
				}
			}

			.item-body{
				.attribute-label{
					width: 100%;
					text-overflow: ellipsis;
					overflow-x: hidden;
					text-align: left;
					padding-left: 5px;
					font-size: 12px;
					line-height: 13px;
					color: #a2a2a2;
				}
				.attribute-value{
					width: 100%;
					text-overflow: ellipsis;
					overflow-x: hidden;
					margin-top: -5px;
					text-align: left;
					padding-left: 20px;
					margin-bottom: 4px;
				}
			}

		}
		div.ui-resizable-se{
			bottom:5px;
		}
	}
}

div.dashboard-component-grid-stack{
	.dashboard-grid-item{
		div.grid-stack-item-content{
			&:hover{
				div.item-header{
					&:hover{
						div.remove-dash-component{
							display: none;

							&:hover{
								display: none;
							}
						}
					}
				}
			}
		}
	}
}

div.dashboard-component-grid-stack{
	// .grid-stack-item[data-gs-width="6"]  { width: 100% }
	// .grid-stack-item[data-gs-width="5"]  { width: 83.33333333% }
	// .grid-stack-item[data-gs-width="4"]  { width: 66.66666667% }
	.grid-stack-item[data-gs-width="3"]  { width: 100% }
	.grid-stack-item[data-gs-width="2"]  { width: 66.66666667% }
	.grid-stack-item[data-gs-width="1"]  { width: 33.33333333% }

	// .grid-stack-item[data-gs-x="5"]  { left: 83.33333333% }
	// .grid-stack-item[data-gs-x="4"]  { left: 66.66666667% }
	// .grid-stack-item[data-gs-x="3"]  { left: 50% }
	.grid-stack-item[data-gs-x="2"]  { left: 66.66666667% }
	.grid-stack-item[data-gs-x="1"]  { left: 33.33333333% }
}

ul.dashboard-tab-config{
	li{
		a{
			background-color: white;
			border: 1px solid #4774b7;
			border-radius: 5px;
			margin-right:1px;
		}
	}
}

div.dash-component-create{
	display: flex;
    padding: 10px;
    border-bottom: 1px solid #ddd;
	input{
		width: 100%;
		height: 30px;
		border: 1px solid #ccc;
		font-size: 12px;
		border-top-right-radius: 0px;
    	border-bottom-right-radius: 0px;
	}
	button{
		border-top-left-radius: 0px;
    	border-bottom-left-radius: 0px;
	}
}


.not-too-big-please{
	max-height: 600px;
	overflow-y: auto;;
}

.dashboard-attribute-edit-table{
	border: 1px solid #4774b7;
	thead{
		color: #4774b7;
		font-size: 12px;
		background: whitesmoke;
		border: 1px solid #4774b7;
		th{
			padding: 5px;
			text-align: center;
		}
	}
	td{
		text-align: center;
	}
}

.grid-stack-item-content::-webkit-scrollbar, .dashboard-grid-item::-webkit-scrollbar {
    width: 0px;  /* Remove scrollbar space */
    background: transparent;  /* Optional: just make scrollbar invisible */
}



.dashboard-render-grid-stack{

	.dashboard-loader{
		transform: translate(-50%, -50%);
		position: absolute;
		left: 50%;
		top: 50%;

		i{
			color:white;
			font-size: 30px;
		}
	}

}

.dashboard-container{
	.nav-tabs {
		li {
			a {
				background-color: white;
				border: 1px solid #4774b7;
			}

			&.active{
				a{
					background-color: #4774b7;
					border: 1px solid #4774b7;
				}
			}
		}
	}

	.dashboard-grid-item{

		.grid-stack-item-content{
			background-color: #4774b7;
			border-radius: 10px;
			color:white;
			text-align: center;
			overflow: scroll;
			display: flex;
			flex-flow: column;
			height: 100%;
			position: relative;

			.dashboard-component-heading{
				font-size: 14px;
				padding: 5px;
				border-top-left-radius: 10px;
				border-top-right-radius: 10px;
				background-color: #1e0354;

				.reloader{
					float: right;
					margin-top: 5px;
					margin-right: 5px;
				}
			}
			.dashboard-component-body{
				font-size: 30px;
				flex: 1 1 auto;

				.dashboard-table-search, .dashboard-comparison-date-picker{
					width: 100%;
					font-size: 12px;
					display: block;
					text-align: center;
					height: 10%;
					border: none;
					max-height: 25px;
				}


				table.table{
					margin: 0px;
					border: 1px solid #3a485c;
					table-layout: auto;
					height: 99.9%;
					border-collapse: separate;
					border-bottom-right-radius: 10px;
					overflow: hidden;
					border-bottom-left-radius: 10px;
					

					&.has-search{
						height :89.9%;
					}


					th{
						border-width: 1px;
						text-align: center;
						font-size: 14px;
						border-radius: 0px;
						cursor: pointer;
						border: none;
    					border-left: 1px solid #3a485c;
						&:first-child{
							border-left: none;
						}
					}

					tbody{
						tr{
							&:first-of-type{
								td{
									border-top: none;
								}
							}
						}
					}

					td{
						font-size: 14px;
						padding: 5px;
						border-left: 1px solid #3a485c;
						border-top: 1px solid #3a485c;
						&:first-child{
							border-left: none;
						}
						
						span.font-size-55{
							font-size: 14px;
						}
					}
				}
			}
		}
	}
}

.dashboard-date-picker{
	background: #fff;
    cursor: pointer;
    padding: 5px 10px;
    border: 1px solid #ccc;
    min-width: 15%;
    line-height: 1.5;
    border-radius: 4px;
}

.toggle_my_children_please {
	cursor: pointer;
	.particles-js-canvas-el{
		position: absolute;
		top: 0px;
		z-index: 0;
		pointer-events: none;
	}
}

.snowing{
	background: linear-gradient(to bottom, rgba(117, 114, 113, 0.8) 10%, rgba(40, 49, 77, 0.8) 30%, rgba(29, 35, 71, 0.8) 50%, rgba(19, 25, 28, 0.8) 80%, rgba(15, 14, 14, .8) 100%), url(https://38.media.tumblr.com/tumblr_m00c3czJkM1qbukryo1_500.gif);
	background-repeat: no-repeat;
    background-size: cover;
}

.particles-js-canvas-el{
	position: absolute;
    top: 0px;
    left: 0px;
    pointer-events: none;
}


.calendar-container{
	background-color: white;
    color: #4774b7;
    height: 100%;
    border: 2px solid grey;
    border-radius: 10px;

	.fc-header-toolbar{
		padding: 5px;

		.fc-button-group{
			.fc-button{
				line-height: 14px;
				padding: 8px;
				height: auto;
			}
		}
	}


	table{
		margin: 0px;
	}
}