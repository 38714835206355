.moveup
{
    margin-top: 0px;
}

.contactleft {
    width: 50%;
    float: left;
}
.contactright
{
    float: right;
    width: 50%;
}

.profilepic
{
    float: left;
    margin-right: 20px;
}

.profilepicsize
{
    width: 135px;
    height: 160px;
}

.popovertext{
    float:left; 
    margin-left:5px;
}