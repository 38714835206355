/* ----------------------------- SCRIPT STYLING ------------------------  */

.toolbar {
	background: #e3eaf4;
	height: 62px;
	width: 46.25%;
	float: left;
	margin-right: 2px;
	margin-top: 10px;
	@include padding(15px 0px 15px 0px);
	@include border-radius-top(8px);
}

.toolbar-full-width {
	 width: 100%; 
	 margin-bottom: 20px;
}

.script-minheight {
	min-height: 320px;
	padding-bottom: 70px;
}

.script-footer {
	@include absolute(none, none, 20px, 15px);
	width: 97%;
}

.verbatim-script-content{
	.script-footer{
		position: relative;
		bottom: 0px;
	}
}

.script-container{
	margin-bottom: 60px;
}

.next-button {
	float: right;
}

.back-button {
	float: left;
}

.script {
	p {
		padding-left: 10px;
		padding-right: 10px;
	}
	margin: 10px;
}

.apiView {
	h3 {
		color: #fff;
		margin-top: 10px;
		@include padding(10px);
		background-color: $main-brand-colour;
		font-size: 15px;
	}
	padding-top: 0px;
}

.formInputs label, #postcodeform label, label, .formLabel {
	color: $label;
	font-weight: normal;
}

.formInputs {
	width: 100%!important;
}

.script-inputs {
	padding: 3px;
	border-radius: 4px;
	margin-left: 5px;
	border: 1px solid #ccc;
}

.script-field {
	width: 75%;
	float: right;
}

.sort-code {
	width: 47.5px;
	@include padding(3px);
	border-radius: 4px;
	margin-left: 5px;
	border: 1px solid #ccc;
}

#api_postcode {
	border-radius: 4px;
	border: 1px solid #ccc;
	padding: 5px;
}

#postcodeform, .compareOptions, .mutliSupplier {
	margin-top: 20px;
	margin-bottom: 10px;
	padding-left: 10px;
	padding-right: 10px;
}

.compareOptions {
	margin-bottom: 10px;
}

#compare-type-dual label {
	margin-right: 20px;
}

.compare-type {
	margin-right: 20px;
}

#paymentOptions-dual {
	margin-top: 20px;
		
}

.payment-types {
	width: 20%;
	float: left;
	padding-top: 10px;
}

.paymentselect.formLabel {
	margin-top:20px;
}

.indent {
	padding-left: 10px;
}

span#compareTypeAppendText, span#currentSupplierAppendText-dual, span#currentSupplierAppendText-electricity, span#currentSupplierAppendText-gas {
	color: $highlight-icons;
	font-style: italic;
}

#currentSupplier-dual {
	h3 {
		padding-left: 10px;
		padding-right: 10px;
	}
}

#currentTariff-dual, #eco7option-dual {
	margin-top: 20px;
}

#currentTariff-dual {
	margin-bottom: 20px;
}

.edit {
	float: right;
	color: $highlight-icons;
}

.dropdown {
	@include padding(3px);
	border-radius: 4px;
	margin-left: 5px;
	border: 1px solid #ccc;
}

.dropdown-payment {
	padding: 7px;
	border-radius: 4px;
	margin-left: 5px;
	font-size: $standard-paragraph;
	border: 1px solid #ccc;
}

.spend-gas, .usage-gas, .spend-electricity, .usage-electricity {
	@include padding(3px);
	border-radius: 4px;
	margin-left: 5px;
	border: 1px solid #ccc;
	max-width: 40%;
}

.diff-supplier {
	margin-top: 15px;
}

.usage-box {
	width: 40%!important;
	border-radius: 4px;
	border: 1px solid rgb(169, 169, 169);
}

.current-usage {
	width: 19%;
	float: left;
}

#usageActual {
	padding-top: 20px;
}

.left-indent {
	padding-left: 10px;
}

.left-indent-5 {
	padding-left: 5px;
}

.left-indent-15 {
	padding-left: 15px;
}

.left-indent-25 {
	padding-left: 25px;
}

.no-padding {
	padding: 0px;
	margin-left: 0px;
	margin-bottom: 10px;
	background: none;
}

.top-margin-20 {
	margin-top: 20px;
}

.right-margin-20 {
	margin-right: 20px;
}


.top-margin-0 {
	margin-top: 0px!important;
}

.bottom-margin-10 {
	margin-bottom: 10px;
}

.eco7select {
	margin-top: 10px;
}

button.all-step-filter{
	margin : 2px 0px;
}

.breadcrumb > li.breadcrumb-item {
	cursor: pointer;
}

div.basket {
	width:100%;
	display: inline-block;
	border : 2px solid #498e49;
	padding :10px;
	border-radius:10px;
	margin-bottom:10px;
	
	div.basket-item{
		//display: inline-block;
		float: left;
	}
	
	button.btn-danger{
		background-color : #d9534f;
		border-color: #d9534f;
	}
}

div.basket.removed {
	border-color: #d9534f;
	background-color: #d9534f17;
}

div.boiler-cover-address{
	div#meter_numbers{
		display:none;
	}
	
}

div[name^="compliance"]{
	ol.breadcrumb {
		display:none;
	}
}

div[name^="coaching"]{
	ol.breadcrumb {
		display:none;
	}
}

// div[name^="price-alert-energy"]{
// 	ol.breadcrumb {
// 		display:none;
// 	}
// }

button.address-select-button{
	min-width: 20%;
	margin: 5px;
	padding: 8px;
	background-color: #b5b5b5;
}

button.address-select-button.active{
	background-color: #e8873b
}

div.insurance-found-addresses{
	display: inline-block;
	div {
		display:inline;
	}
}


li.address-result-item.active {
	font-weight: bold;
	color: red;
	font-size: 1.2em;
}

.wysiwyg-variable-list{
	max-height: 471px;
	overflow-y: scroll;
}

.links-module{
	.edit-trigger{
		display:none;
	}
}

.all-scripts-module{
	.delete-trigger{
		display:none;
	}
}

div.script-form{
	margin-bottom:70px;
}

.repeatable-table-report {
	margin: 0 auto;
	border:1px solid #dfdfdf;
	width: 95%;
	padding:0;

	td {
		border:1px solid #ebebeb;
	}

	tr {
		border:1px solid #ebebeb;
	}

	thead tr{
		font-weight:bold;
	}
}

.summary-item-clickable {
	cursor: pointer;
	//background-color: #f2f5fa;
	//color: #1f1f1f;
	//padding-top: 5px;
	//height: 35px;
	//
	//span {
	//	padding: 10px;
	//}
}

.summary-page-items {
	margin-top: -5px;
	padding-left: 10px;
	padding-right: 10px;
	margin-bottom: 10px;
	font-size: 16px;

	.summary-flow {
		transition: 0.5s;
	}

	ol.breadcrumb {
		margin-bottom: 0;
	}
}

.summary-group-name-header {
	background-color: #f1cc7f;
	padding: 8px;
	font-weight: bold;
	font-size: 20px;
}

.summary-footer {
	padding: 13px;
	height: 50px;
}

.blue-ish-bg {
	background-color: #99a9c1;
}

.blue-ish-border {
	border: 2px solid #99a9c1;
}


div.row div#script-groups div#script-group-list.module-inner div#teams-container {
	max-height: 79vh;
	overflow-y: scroll;
}

#script-group-steps {
	#pass-rules {
		max-height: 79vh;
		overflow-y:scroll;
	}
}

div#all-script-steps.module-inner div#pass-rules.script-group-link-drop-zone {
	div#script-group-steps-container {
		max-height: 78vh;
		overflow-y:scroll;
	}
}

#script-all-steps #all-script-steps #pass-rules div #script-group-steps-container #script_group_steps_sort {
	.field-list {
		margin:0;
	}
}

.summary-item-description {
	height:20px;
}

.linked-scripts{
	min-height: 200px;
}

div#all_script_container {
	ul#all_script_sort.saved-trigger-wrapper.reportSortable.ui-sortable.sort-list {
		height: 78vh;
		overflow-y: scroll;
		overflow-x: hidden;
	}

	.name {
		float: left;
		padding: 10px;
	}

	.team-container {
		background: #fff;
		list-style-type: none;
		width: 100%;
		height: 40px;
		border-bottom: 1px solid #ddd;
		transition: background-color 0.5s ease;
	}

	.team-container:hover {
		background-color:#E3EAF4;
	}
}


.estimation-icon-holder{
	.font-color-green{
		//I know, I know but I want it to match the nice images that Si Whitmore made.
		color: #9acb47;
	}
}

h3.estimation-confirmation-text{
	font-size:30px;
}

.mid-script-summary-container {
	text-align:center;
	margin:0 auto;
}

div.aftersales-dd-details{
	.lead-label{
		width: 25%;
		margin: 0;
		padding-right: 10px;
	}
}

div.current-supply-errors{
	float: left;
    color: white;
    font-weight: bold;
    width: 80%;
    background-color: darkred;
    padding: 5px 25px;
}

.type-mismatch{
	background-color: darkred;
    font-size: 24px;
    padding: 8px;
    height: auto;
    font-weight: bold;
}

.dpa_checkbox {
	margin:10px;
}

.dpa-item {
	.dpa-span {
		padding-left: 40px;
	}

	height: 40px;
	.check {
		width: 30px;
		height: 30px;
		position: absolute;
		margin: auto;

		input {
			display: none;

			&:checked + .box {
				background-color: #b3ffb7;

				&:after {
					top: 0;
				}
			}
		}

		.box {
			width: 100%;
			height: 100%;
			transition: all 1.1s cubic-bezier(.19, 1, .22, 1);
			border: 1px solid black;
			background-color: white;
			position: relative;
			overflow: hidden;
			cursor: pointer;
			box-shadow: 0 5px rgba(0, 0, 0, .2);
			margin-bottom:0px;

			&:after {
				width: 60%;
				height: 30%;
				content: '';
				position: absolute;
				border-left: 3.5px solid;
				border-bottom: 3.5px solid;
				border-color: #40c540;
				transform: rotate(-45deg) translate3d(0, 0, 0);
				transform-origin: center center;
				transition: all 1.1s cubic-bezier(.19, 1, .22, 1);
				left: 0;
				right: 0;
				top: 200%;
				bottom: 5%;
				margin: auto;
			}
		}
	}
}


.customer-preview-container {
	padding-right: 0px;
    padding-left: 5px;

	.customer-view-heading{
		font-size: 20px;
		text-align: center;
	}
}

.renewal-policy-selection {
	cursor:pointer;
	padding: 10px;
	border-radius: 5px;
	background-color: #f5f5f5;
	border: 1px solid #b3b3b3;
	margin: 10px;
	text-align: center;

	.row {
		background-color: #d4d4d4;
		margin: -10px;
		border-top-left-radius: 5px;
		border-top-right-radius: 5px;
		padding: 5px;
	}

	.row.policy-block-content {
		background-color: #ffffff;
		padding:10px;
	}

	.selected-span {
		display:none;
	}

	.pad-me-please-25 {
		padding:25px !important;
	}
}

.renewal-policy-selection.active {
	border: 1px solid #8cc119;
	box-shadow: 0px 0px 3px #6da10b;

	.row {
		background-color:#a8e228;
	}

	.row.policy-block-content {
		background-color: #ffffff;
		padding:10px;
	}

	.selected-span {
		display:inline;
	}
}

.selection-error {
	display:none;
	padding:10px;
	font-weight:bold;
	color: #b40000;
}

.renewal-policy-content {
	text-align: center;
	border: 1px solid #b7b7b7;
	padding: 10px;
	margin: 10px;
	border-radius: 4px;
	color: #e60000;
	font-size:15px;
}

.renewal-policy-content-header {
	font-weight:bold;
}

table.existing-renewal-policy-table {
	margin: 0 auto;
	max-width: 98%;
	width: 98%;
	border: 1px solid #adadad;

	td {
		text-align: center;

	}
	td.material-switch-td {
		text-align: left;
		.material-switch {
			margin: 0 auto;
			display: table;
		}
	}

	thead {
		color: #000000;
		font-size: 11px;
		font-weight: bold;
		background: #9edefd;

		td {
			padding: 7px;
			font-size: 12px;
		}
	}

	tbody {
		tr {
			background-color:#d3f1fc;
		}
		tr:nth-child(2) {
			background-color: #c3eaf8;
		}

	}
}

.existing-renewal-policy-wrapper {
	margin: 0;
	padding: 10px;
	border: 1px solid #c7c7c7;
	background-color: #f1faff;
	border-radius: 5px;
}

.session-header-row {
	margin: 0;
	z-index: -75;
	background-color: #243144;
	padding: 10px;
	font-size: 15px;
	font-weight: bold;
	padding-left: 60px;
	padding-right: 60px;
	color: white;
	border-bottom: 2px solid #ff6767;
}

.session-fade-element {
	color: #ffffff;
	animation: fader 2s linear infinite;
}

@keyframes fader {
	50% {
		color: rgb(137, 137, 137);
		color: rgba(128, 128, 128, 0.5);
	}
}

.searchable-list {
	margin-left: 0px;
	margin-block-start: 0;
	padding-inline-start: 0;
}


.renwal-date-selection{
	margin-bottom: 20px;

	ul{
		list-style: none;
		margin: auto;
		padding: 0;
		width: 80%;
		overflow: auto;
	}

	ul li{
		color: #AAAAAA;
		display: block;
		position: relative;
		float: left;
		width: 100%;
		height: 45px;
		border-bottom: 1px solid #dcdcdc;
	}

	ul li input[type=radio]{
		position: absolute;
		visibility: hidden;
		display:none;
	}

	ul li label{
		display: block;
		position: relative;
		font-weight: 300;
		font-size: 16px;
		padding: 15px 10px 30px 60px;
		margin: 0px;
		height: 10px;
		z-index: 9;
		color:#AAAAAA;
		cursor: pointer;
		-webkit-transition: all 0.25s linear;
		transition: all 0.25s linear;
	}

	ul li:hover label{
		color: #3A485C;
	}

	ul li .check{
		display: block;
		position: absolute;
		border: 5px solid #AAAAAA;
		border-radius: 100%;
		height: 25px;
		width: 25px;
		top: 13px;
		left: 20px;
		z-index: 5;
		transition: border .25s linear;
		-webkit-transition: border .25s linear;
	}

	ul li:hover .check {
		border: 5px solid #3A485C;
	}

	ul li .check::before {
		display: block;
		position: absolute;
		content: '';
		border-radius: 100%;
		height: 11px;
		width: 11px;
		top: 2px;
		left: 2px;
		margin: auto;
		transition: background 0.25s linear;
		-webkit-transition: background 0.25s linear;
	}

	input[type=radio]:checked ~ .check {
		border: 5px solid #4774b7;
	}

	input[type=radio]:checked ~ .check::before{
		background: #4774b7;
	}

	input[type=radio]:checked ~ label{
		color: #4774b7;
	}
}

.tick-section{
	text-align: center;
	position: relative;

	.tick-box{
		position: absolute;
		left: 50%;
		transform: translateX(-50%);
	}

	input[type=checkbox]{
		display: none;
	}

	.check{
		display: block;
		border: 3px solid #AAAAAA;
		border-radius: 5px;
		height: 25px;
		width: 25px;
		position: relative;
		z-index: 5;
		transition: border .25s linear;
		-webkit-transition: border .25s linear;
		cursor: pointer;

		&:hover{
			border: 3px solid #3A485C;
		}

		&.has-error{
			border-color: darkred;
		}

		i{
			opacity: 0;
			transition-duration: 0.1s;
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
		}
	}
	
	input[type=checkbox]:checked ~ .check {
		border: 3px solid #4774b7;
		color: #4774b7;
	
		i{
			opacity: 1;
		}
	}

	
}

.custom-renewal-date-selector-holder{
	width: 80%;
    margin: auto;
}


.simple-usage-selector, .not-simple-usage-selector{
	.btn-group{
		display: block;
		width: 80%;
		margin: auto;
		margin-bottom: 70px;

		.btn{
			width: 33%;
			background: none;
			color: grey;
			border: 3px solid #4774b7;
			padding: 10px;
			font-size: 16px;
			border-right: none;

			&:last-of-type{
				border-right: 3px solid #4774b7;
			}

			&.active{
				background: #4774b7;
				color: white;
				font-weight: bold;
			}

			&:hover{
				font-weight: bold;
			}
		}
	}
}


.not-simple-usage-selector {
	.btn-group{
		width: 100%;
		margin-bottom: 20px;

		.btn{
			width: 20%;
			font-size: 14px;
		}
	}
}

.broadband-section{
	padding: 0px 15px;

	.broadband-section-heading{
		background-color: #4774b7;
		color: white;
		padding: 10px;
		font-size: 16px;
	}

	.btn-group{
		text-align: center;
	}

	.provider-selector{
		margin: 2px 0px;
		float: none;
		background: none;
		border: 2px solid #4774b7;
		color: #4774b7;
		font-size: 14px;
		
		&:hover{
			background: none;
			font-weight: bold;
			border: 2px solid #4774b7;
			&.active{
				background: #4774b7;
			}
			.broadband-package-provider-logo{
				transform: scale(1.05);
			}
		}
		
		&.active{
			background: #4774b7;
			border: 2px solid #4774b7;
			color: white;
			
			.broadband-package-provider-logo{
				filter: brightness(0) invert(1);
			}
		}
		
		.broadband-package-provider-logo{
			transition-duration: 0.2s;
			display: block;
			margin: auto;
			max-height: 50px;
		}

	}

}
.date-picker-wrapper{
	position: relative;

	.date-picker{
		width: 100%;
		cursor: pointer;
	}

	&:after{
		font-family: 'FontAwesome';
		content: '\f073';
		position: absolute;
		right: 6px;
		top: 50%;
		transform: translateY(-50%);
		pointer-events: none;
	}
}


.external-button-holder{
	text-align: center;

	.btn-unmissable{
		font-size: 24px;
		padding: 20px;
		color: white;
		background-color: #4774b7;
	}
}


.broadband-data-capture-form{
	padding:10px;
	.form-control{
		height: 30px;
    	font-size: 14px;
	}
}

div.customer-data-to-enter-into-external-portal{
	margin: 20px;
    background-color: #eaeaea;
    border: 1px solid grey;
    padding: 20px;
    padding-top: 0px;

	div {
		background: none;
	}

	.item{
		padding: 7px;

		.item-value{
			display: inline-block;
    		font-weight: bold;
		}

		.copy-text-from-node{
			cursor: pointer;
		}
	}
}

.broadband-referral-notes{
	margin-top:20px;
	width:100%;
	max-width:100%;
	resize: none;
}

.broadband-dd-data-loader{
	text-align: center;
    font-size: 24px;
}


.carbon-offset-skip-button{
	text-align: center;
}


.quick-change-modal-input-container {
    margin-bottom: 10px;
	padding:5px;

	&:last-of-type{
		margin-bottom: 0px;
	}

	.quick-change-modal-input-label{
		label{
			font-size: 14px;
			font-weight: bold;
		}
	}
}

.quick-change-modal-input-container.loading{
	position: relative;
	&::after{
		content: 'Loading...';
		font-size: 24px;
		font-weight: bold;
		color: #4774b7;
		position: absolute;
		top: 0px;
		left: 0px;
		width: 100%;
		height: 100%;
		text-align: center;
		display: flex;
		justify-content: center;
		align-items: center;
		background-color: #243a5c3d;
		border-radius: 5px;
	}
}

.address-postcode{
	border: 1px solid #aaa;
    border-radius: 4px;
    height: 28px;
    padding-left: 8px;
    font-size: 14px;
	width: 80%;
}


.address-select-loading-icon{
	color: #4774b7;
    position: absolute;
    right: 20px;
    top: 5px;
}

.boiler-care-follow-up-questions{
	padding:15px;

	.follow-up-question-section{
		margin-bottom: 10px;
	}
}

.boiler-age.form-control, .insurance-renewal-date-picker.form-control, .boiler-care-input{
	height: 28px;
    border-color: #aaa;
	font-size: 14px;
}

.shake {
    animation-name: shake;
    animation-duration: .5s;
    animation-delay: 0.05s;
  }

  @keyframes shake {
    0% {
      transform: translateX(0px);
      timing-function: ease-in;
    }
    37% {
      transform: translateX(5px);
      timing-function: ease-out;
    }
    55% {
      transform: translateX(-5px);
      timing-function: ease-in;
    }
    73% {
      transform: translateX(4px);
      timing-function: ease-out;
    }
    82% {
      transform: translateX(-4px);
      timing-function: ease-in;
    }
    91% {
      transform: translateX(2px);
      timing-function: ease-out;
    }
    96% {
      transform: translateX(-2px);
      timing-function: ease-in;
    }
    100% {
      transform: translateX(0px);
      timing-function: ease-in;
    }
  }

.boiler-care-products, .landlord-bc-products{
	.btn.product-button{ 
		width: 80%;
		margin-bottom: 10px;
	}

	.fa-li{
		top:0px;
	}

	.number-item{
		font-size: 24px;
		font-weight: bold;
		margin-top: 25px;
	}

	.provider-logo{
		margin-top: 20px;
	}

	.more-info-title{
		font-size: 18px;
		font-weight: bold;
		text-align: center;
		padding-top: 10px;
		margin-bottom: 20px;
	}
}

.toggle-holder{
	padding: 10px 20px;
    background-color: #4774b7;
    border-radius: 10px;
    color: white;
}

.feature-list {
	.fa-li-script {
		position: absolute;
		left: 0.857143em;
		width: 2.1428571429em;
		text-align: center;
	}	
}

.products-sort-toggle{
	display: inline-block;
	
	label{
		margin-left: 10px;
		margin-right: 5px;

		&.label-success{
			background-color: white;
		}
	}

	.material-switch > input[type="checkbox"]:checked + label::before{
		background-color: #112f60;
	}
}

.bc-reset-filter-toggle{
	float:right;
	text-decoration: underline;
	cursor:pointer;
	padding:10px;
	font-weight: bold;
}


.bc-landlords-toggle, .hide-inactive-plans-toggle{
	display: inline-block;
	margin-left: 40px;

	label{
		margin-left: 10px;
		margin-right: 5px;

		&.label-success{
			background-color: white;
		}
	}

	.material-switch > input[type="checkbox"]:checked + label::before{
		background-color: #112f60;
	}
}

.update-script-link{
	float: right;
	text-decoration: underline;
	cursor: pointer;
}


.boiler-care-product{
	text-align: center;
    padding: 15px 20px;
    background: white;
    color: #1E0354;
    position: relative;
    box-shadow: 0 0 30px rgba(0, 0, 0, 0.06);
    border-radius: 10px;
    height: 100%;
	// display: grid; 
	// grid-template-columns: 200px 4fr 2fr; 
	// grid-template-rows: 1fr; 
	// gap: 1px 0px; 
	// grid-template-areas: 
	//   ". . ."; 
	// width: 100%; 
	// height: 100%;
	margin-bottom: 10px; 
	
	.logo-container{
		position: relative;

		.provider-logo{
			max-width: 100%;
			position: absolute;
			width: 100%;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
		}
	}

	.feature-list{
		font-size: 15px;
	}
	

	.more-info-container{
		grid-column: 1/-1;
		text-align: initial;

	}

	.buy-product-container{
		display: grid; 
		grid-template-columns: 1fr; 
		grid-template-rows: 1fr 1fr 1fr; 
		gap: 1px 0px; 
		grid-template-areas: 
			"."
			"."
			"."; 
		width: 100%; 
		height: 100%; 

		.more-info-link-container{
			position: relative;

			a{
				color: #c637a1;
				position: absolute;
				top: 50%;
				left: 50%;
				transform: translate(-50%, -50%);

				&:hover{
					color: #1E0354;
				}
			}
		}
	}

	h3{
		line-height: 1.4;
		font-size: 20px;
		font-weight: 700;
		color: #1E0354;
		padding: 0 0 17px;
		margin: 0px;
		position: relative;
		transition-duration: 0.5s;
	}

	.boiler-care-product-info{
		p{
			display: inline-block;
			text-transform: capitalize;
			font-weight: 600;
			color: #c637a0;
			position: relative;
			padding: 5px 10px;
			min-width: 130px;
			text-align: center;
			background-color: rgba(198, 55, 160, 0.1);
			border-radius: 5px;
			font-size: 18px;
		}
	}

	.boilercare-icons{
		font-size: 16px;

		.icon-container{
			height:23px;
		}

		i{
			color: #c637a0;
		}
	}

	.submit-boiler-care-pitch{
		padding: 10px 20px;
		background: #c637a0;
		color: white;
		font-weight: bold;
		display: inline-block;
		min-width: 170px;
		text-align: center;
		border-radius: 60px;
		border: none;
		position: relative;
		overflow: hidden;
		z-index: 1;
		text-transform: capitalize;
		transition-duration: 0.4s;
		margin-top: 10px;

		&:hover{
			background: #1E0354;
			border:none;
		}
	}

}

.emotional-button-container {
    padding: 20px;
    margin-bottom: 15px;

	.emotional-button {
		text-align: center;
		padding: 10px;
		color: white;
		font-size: 50px;
		border-radius: 25px;
		box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
		cursor: pointer;
 
		&.sad{
			background-color: darkred;
		}
		&.happy{
			background-color: darkgreen;
		}
		&.money{
			background-color: goldenrod;
		}

		.button-text {
			font-size: 18px;
			padding: 10px;
		}
	}
}

.single-page-flow-wrapper {
	.row {
		margin:0;
	}
	.concertina-section-wrapper {
		padding:10px;
	}
	.row.top-margin-20 {
		margin-top:20px;
	}
	.concertina-heading {
		border: solid 2px white;
		background-color: #112f60;
		color:white;
		font-size: 2em;
		font-weight: bold;
	}
	.concertina-body-section{
		padding: 10px;
    	font-size: 1.2em;
	}
	.dynamic-auto-pitch-text-section {
		padding:10px;
	}
	.customer-details-dont-ask-if-possible, .inline-direct-debit-input-wrapper {
		overflow: hidden;
		border: solid 1px;
		border-radius: 25px;
		width: 60%;
		padding:20px;
		margin:auto;
		#customer-details-email {
			width:75%;
		}
	}
	.go-to-checkout-information-dynamic-text {
		width: 40%;
   		margin: auto;
    	text-align: center;
		.inline-checkout-go-to-backet-button {
			padding: 10px;
			font-size: 1.2em;
			font-weight: bold;
		}
	}
}

