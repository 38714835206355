div.no-margin-please{
    margin : 5px;

    div.phone{
        height:750px;
        div.screen{
            height:80%;
        }
    }
}


.form-horizontal div.no-margin-please{
    margin : 5px;
}


div.zoopla-image{
    img{
        width:100%;
    }
}

.contact-details-edit {
    margin-bottom:10px;
}

.event-type-selector {
    font-weight: bold;
    margin-top: 4px;
    background-color: #c4d2e6;
    display: inline-block;
    height: 30px;
    cursor: pointer;
    line-height: 28px;
    border: 1px solid #a4a4a4;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
}

.event-types-row {
    margin:0 auto;
    text-align: center;
    display: flex;
}

.contact-events-sub-table {
    border-bottom: 1px solid #a4a4a4;
}

.active-event-type {
    color:white;
    background-color: #4774b7;
}

.notification-events-white {
    display: inline;
    font-size: 9px;
    background: #ef7d24;
    /* animation: pulsate 2s infinite; */
    padding: 2px 4px;
    border-radius: 15px;
    position: relative;
    top: -2px;
    color:white;
}

.contact-dialler-instance-row {
    margin: 0px;
    background-color: whitesmoke;
    padding-top: 5px;
    padding-bottom: 6px;
    border: 1px solid #dedede;
    margin-bottom: -4px;

    .first-info-col {
        padding-left: 3px;

        b {
            background-color: #e3eaf4; padding: 4px;border-radius: 3px;border: 1px solid #aabedc;
        }
    }

    .second-info-col {
        text-align: center;
    }

    .third-info-col {
        text-align: right;
    }
}

.contact-warning-box{
	position: absolute;
    top: 50px;
    background-color: darkred;
    padding: 20px;
    left: 50%;
    transform: translateX(-50%);
    color: white;
    font-size: 20px;
    border: 5px solid #70635f;
    border-top: none;
}


.show-dialler-instance-profile{
	margin-left: 10px;
}

.current-profile-table{
	.profile-table-row{
		padding-bottom: 5px;
		border-bottom: 1px solid grey;
		margin-bottom: 10px;
	}
}