.scheduler-heading {
	@include module-headings($background: $navbar-bg, $padding: 5px, $radius: 8px, $color: #fff, $margin-bottom: null);
}

.scheduler-container {
	label {
		white-space: nowrap;
		margin-right: 5px;
		margin-left: 5px;
		margin-bottom: 0px;
		font-size: 12px;
	}
	input {
		@include inputs(100%, 30px);
	}
	padding: 10px;
	@include flex-center;
	border-bottom: 1px solid #ddd;
}

#actions-schedule-container {
	.action-group-create {
		#save_schedule {
			margin-left: 0px;
		}
	}
}

.day-checkboxes {
	label {
		&:first-of-type {
			font-size: 12px;
		}
	}
	padding: 10px;
	border-top: 1px solid #ddd;
}

.start-dates {
	label {
		&:first-of-type {
			font-size: 12px;
			margin: 0px;
		}
	}
	input {
		@include inputs(50%, 30px);
	}
	padding: 10px;
	border-top: 1px solid #ddd;
	@include flex-center($display : flex, $align : center, $justify : left);
}

.end-dates {
	label {
		&:first-of-type {
			font-size: 12px;
		}
	}
	padding: 10px;
	border-top: 1px solid #ddd;
	@include flex-center($display : flex, $align : center, $justify : left);
}

.after-runs, .finish-on-date {
	.after-runs-input  {
		@include inputs(20%, 30px);
		margin-left: 10px;
	}
	@include padding(0px 10px 10px 10px);
	@include flex-center($display : flex, $align : center, $justify : left);
}

.save-schedule-container {
	a {
		margin-left: 0px;
	}
	padding: 10px;
}

#saved-schedules {
	.saved-schedules-wrapper {
		padding: 0px;
		min-height: 80px;
		margin: 0px;
	}
	.schedule-container {	
		.schedule-icon-small {
			color: $white;
			@extend %flex-x-y-align;
			width: 50px;
			height: 100%;
			background: $navbar-bg;
			float: left;
		}
		background: $white;
		list-style-type: none;
		width: 100%;
		height: 40px;
		border-bottom: 1px solid $light-grey;
	}
	.schedule-icon {
		&:hover {
			background: $action-group-green!important;
		}
		p {
			font-size: $standard-paragraph;
			text-align: center;
			margin: 0px;
		}
		i {
			font-size: 20px;
		}
		cursor: pointer;
		@include transition(background-color 0.5s ease);
		color: $white;
		margin: 0px;
		@extend %flex-x-y-align;
		flex-wrap: wrap;
		font-size: 30px;
		background: $navbar-bg;
	}
	.trigger, .rule, .action, .schedule {
		float: left;
		padding: $standard-padding;
	}
	position: relative;
	cursor: move;
}

#saved-schedules {
	.group-information {
		.group-name {
			@include padding(0.5% 0 0 1.1%);
			font-size: 12px;
			height: 50%;
			display: inline-block;
		}
		.group-stats {
			height: 50%;
			font-size: 11px;
			@include padding(0.3% 0.7% 0.7% 11%);
			border-top: 1px solid #ddd;
			background: $light-blue;
		}
		width: 88%;
		height: 100%;
		border-right: 1px solid #ddd;
	}
	.group-stats-nav {
		i {
			&:hover {
				color: $main-brand-colour;
			}
			color: $action-group-green;
		}
		cursor: pointer;
		float: right;
		padding: $standard-padding;
		display: inline-block;
	}
}

.schedules-footer {
	height: 10px;
    background: $navbar-bg;
	@include border-radius-bottom($border-radius-modules);
}

.day-selector {
	width: 170%;
	margin-right: 15px;
}

.time-selector {
	width: 70%;
}

.schedule-toolbar {
	a {
		&:first-of-type {
			margin-left: 0px;
			margin-right: 10px;
		}
	}
}

.adhoc-selector {
	margin-left: 10px;
	width: 40%;
}

/*.time-slider {
	width: 60%;
}

.time-range-input {
	width: 20%;
	border: 0;
	text-align: center;
	margin-bottom: 0px; 
	color: #333; 
	font-weight: bold;
}

.time-ranges-container {
	label:first-of-type {
		width: 10%;
	}
	@extend %flex-x-y-align;
	padding: $standard-padding;
	border-top: 1px solid #ddd;
}*/


.bank-holiday-control-section{

	padding: 10px;
    padding-bottom: 0px;

	.material-switch{
		display: inline;
	}
}