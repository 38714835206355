.profile-panel {
  margin: 0 auto;
  height: 56px;
  text-align: center;
  .profile-panel-body {
    border-radius: 4px;
    margin: 0 auto;
    background-color: #fbfbfb;
    border: 1px solid #cacaca;
    //border-right: 4px solid #a4e4ff;
    line-height: 38px;
    height: 40px;
    width: 95%;
    font-size: 15px;
    -webkit-box-shadow: 0px 0px 3px 0px rgba(148,148,148,0.56);
    -moz-box-shadow: 0px 0px 3px 0px rgba(148,148,148,0.56);
    box-shadow: 0px 0px 3px 0px rgba(148,148,148,0.56);
    cursor:pointer;
  }
  .panel-body-clicked {
    background-color: #edf5ff;
    border: 1px solid #a5a4a4;
  }

  .profile-panel-body:hover {
    background-color: #f9f9f9;
  }
}

.profile-panel-row {
  margin: 0 auto;
  width: 100%;
}

.roles-row {
  width: 94%;
  margin: 0 auto;
  font-size: 16px;
  line-height: 24px;
  min-height:220px;
}

.select-all-options {
  margin-top:10px;
}
