
div.coaching-details-item{

	// margin-bottom: 5px;



	input.date-filter.coaching {
		width: 100%;
		height: 20px;
		border: 1px solid #aaa;
		padding-left: 15px;
		border-bottom-left-radius: 4px;
		border-top-left-radius: 4px;
	}

	input.text-input{

		width: 100%;
		height: 20px;
		border: 1px solid #aaa;
		padding-left: 15px;
		font-size: 14px;

	}

	.select2-selection__rendered {
		line-height: 20px !important;
	}
	.select2-container .select2-selection--single {
		height: 20px !important;
	}
	.select2-selection__arrow {
		height: 20px !important;
	}

	span.input-group-addon {
		padding: 0px 7px;
		font-size: 12px;
	}

	.btn-info{
		color: #fff;
		background-color: #5bc0de;
		border-color: #46b8da;
	}

	.coaching-details-item.save-button{
		text-align: center;
	}

	textarea{
		width: 100%;
		height: 90px;
		border-radius: 4px;
	}

	div.giant-stars-make-simple-people-happy{
		width: 100%;
		text-align: center;

		i.fa-star, i.fa-star-o{
			font-size: 50px;
			color:#e2bf00;
		} 
	}
	
	
}

.coaching-table{

	i.fa-star, i.fa-star-o{
		color:#e2bf00;
		font-size:25px;
	} 

}

.twinkle-twinkle{
	i{
		cursor: pointer;
	}
}

.coaching-feedback-modal{
	label{
		font-weight: bold;
	}
	p{
		font-size: 12px;
	}
}


div.restore-coaching-inputs-alert{
	i{
		font-size: 18px;
	}
	button.restore-button-outline{
		float: right;
		margin-top: -10px;
		background-color: transparent;
		border: 1px solid #31708f;
		color: #31708f;
		&:hover{
			background-color : #31708f;
			color: white;
		}
	}
}