/**
 *  The following is not really needed in this case
 *  Only to demonstrate the usage of @media for large screens
 */ 
/*
 @media screen and (max-width: 768px) {
    #sidebar {
        min-width: 55px;
    }
    
    #main {
        width: 1%;
        left: 0;
    }
    
    #sidebar .visible-xs {
      display: none!important;
    }
    
    .row-offcanvas {
       position: relative;
       -webkit-transition: all 0.4s ease-in-out;
       -moz-transition: all 0.4s ease-in-out;
       transition: all 0.4s ease-in-out;
    }
    
    .row-offcanvas-left.active {
       left: 45%;
    }
    
    .row-offcanvas-left.active .sidebar-offcanvas {
       left: -45%;
       position: absolute;
       top: 0;
       width: 45%;
    }
}

@media (min-width: 768px){
  .seven-cols .col-md-1,
  .seven-cols .col-sm-1,
  .seven-cols .col-lg-1  {
    width: 100%;
    *width: 100%;
    border-left: 1px solid #e3eaf4;
    text-align: center;
	height: 20px;
  }
}

@media (min-width: 992px) {
  .seven-cols .col-md-1,
  .seven-cols .col-sm-1,
  .seven-cols .col-lg-1 {
    width: 12.285714285714285714285714285714%;
    *width: 12.285714285714285714285714285714%;
    border-left: 1px solid #e3eaf4;
    text-align: center;
  }
}
*/

@media screen and (min-width: 1366px) and (max-width: 1366px) {
/*  .seven-cols .col-md-1, .seven-cols .col-sm-1, .seven-cols .col-lg-1 {
    width: 10.285714285714285714285714285714%;
    *width: 10.285714285714285714285714285714%;
    border-left: 1px solid #e3eaf4;
    text-align: center;
  }*/
  
  .col-md-1 > .quote-table {
	  width: 12.285%;
  }
  
  .toolbar {
       width: 58.25%;
    }
  .fuel-select li a {
        padding: 21px 25px;
		cursor: pointer;
    }
  .tariff-options {
        width: 33.26%;
    }
  .postcode-cell {
        width: 9%;
    }
  .payment-type-cell, .monthly-spend-cell, .date-modified-cell {
        width: 15%;
    }
    .tariff-5-pad {
        padding-left: 5px;
        padding-right: 5px;
    }
	
	.tariff-5-pad img {
		width: 80%;
	}
	
	.tariff-value img {
		width: 80%;
	}
	
	.term-column {
		width: 5%;
	}
	
	.postcode-field {
		width: 80%;
	}
	
	.button-text, .task-text {
		display: none;
	}

}
