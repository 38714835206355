:root {
	--fc-highlight-color : #ebcc125e;
}

#createAppointment {
	.modal-body {
		min-height:400px;
	}
    
	.appointment-user-key {
		border: solid 1px black;
		border-radius: 15px;
		margin-top: 10px;
    	padding: 10px;
		.larger-marker {
			padding-left:0px;
		}
		.larger-marker li {
			color: #FFFFFF;
			list-style: none; /* Hide default marker */
			position: relative; /* Set position relative to the list item */
			padding-left: 70px; /* Add left padding to make space for the larger marker */
		}

		.larger-marker li::before {
			content: '\2022'; /* Use '\2022' to display a bullet point */
			font-size: 60px; /* Adjust the font size to make the marker significantly bigger */
			position: absolute; /* Position the marker absolutely */
			left: 42px; /* Adjust the left position of the marker */
			top: 50%; /* Vertically center the marker */
			transform: translateY(-50%); /* Vertically center the marker */
		}
		span {
			font-weight: 500;
			font-size: 1.2em;
		}
	}
}

.appointment-events-history {
	.table {
		margin-left:0px;
	}
	.table-bordered {
		border-top: none;
	}
}
