#switch_site_config{
	
	div.trigger{
		width: 90%;
	}
	
	li.ui-sortable-handle{
		min-height:100px;
		height: auto;
	}
	
	button {
		margin: 10px;
		margin-left: 40%;
	}
	
	label {
		margin-right : 20px;
	}

	div.team-icon-small{
		min-height: 100px;
	}
	
	div.campaign-single{
		padding:5px;
	}

	.btn-info{
		color: #fff;
		background-color: #5bc0de;
		border-color: #46b8da;
	}

	.btn-danger{
		color: #fff;
		background-color: #d9534f;
		border-color: #d43f3a;
	}

	div.card {
		div.rule-group-edit{
			display:block;
		}
	}

	input{
		padding:5px;
	}

}

.campaign-single {
	border-bottom: 1px solid #ddd;
}

.stats-card-secret {
	background-color: #FFF;
	border: 1px solid #e6e6e6;
	-webkit-box-shadow: 0px 0px 12px -2px rgba(112,112,112,0.3);
	-moz-box-shadow: 0px 0px 12px -2px rgba(112,112,112,0.3);
	box-shadow: 0px 0px 12px -2px rgba(112,112,112,0.3);
	margin-bottom: 20px;
	border-radius: 10px;

	.card-header {
		padding: 4px;
		padding-top:0;
		border-bottom: 1px solid #eee;
	}

	.card-body {
		padding: 5px;
		padding-top:10px;
	}

	.card-footer {
		margin-top:6px;
		padding: 5px;
		border-top: 1px solid #eee;
	}
}

.campaign-config-container {
	margin-top:10px;
}

.campaign-config-container-add {
	margin-top:10px;
	border-top: 1px solid #EEE;
}

.campaign-config-container-add-single {
	padding-top:10px;
}

.switch {
	position: relative;
	display: inline-block;
	width: 60px;
	height: 30px;
}

.switch input {
	opacity: 0;
	width: 0;
	height: 0;
}

.slider {
	position: absolute;
	cursor: pointer;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background-color: #ccc;
	-webkit-transition: .4s;
	transition: .4s;
}

.slider:before {
	position: absolute;
	content: "";
	height: 22px;
	width: 22px;
	left: 4px;
	bottom: 4px;
	background-color: white;
	-webkit-transition: .4s;
	transition: .4s;
}

input:checked + .slider {
	background-color: #2196F3;
}

input:focus + .slider {
	box-shadow: 0 0 1px #2196F3;
}

input:checked + .slider:before {
	-webkit-transform: translateX(30px);
	-ms-transform: translateX(30px);
	transform: translateX(30px);
}

.slider.round {
	border-radius: 34px;
}

.slider.round:before {
	border-radius: 50%;
}

.add-campaign-config {
	margin-top: -10px;
}

.color-input-display {
	width: 80px;
	margin-top: 18px;
	height: 50px;
}

.url-container{
	min-width: 20%;
	display:inline-block;
}

.journey-container {
	display: inline-block;
    border-radius: 15px;
    box-shadow: 0px 0px 12px -2px rgba(112, 112, 112, 0.3);
    padding: 5px;
    margin-top: 10px;
    min-width: 140px;
}

.config-section{
	text-align: left;
    padding: 10px 20px;
    border: 1px solid #e2e2e2;
	margin: 5px 20px;
	
	.config-item{
		width: 37%;
		display: inline-block;
		input, select {
			width:70%;
		}
	}
}

.coversion-tracking-data{

	.header-row{
		width: 100%;
		display: inline-block;

		.header-item{
			display :inline-block;
			width:19%;
			font-weight: bold;
		}
	}

	.journey-row{
		.journey-item{
			display: inline-block;
			width: 19%;
		}

		.journey-break-down {
			border-top:1px solid #cccccc;
		}
	}


}