.switch-site-details-edit-form {
  padding:15px;
  width: 95%;
  margin: 0 auto;

  input {
    width:100%;
  }

  .switch-site-input-row {
    line-height: 30px;
    height: auto;
	border-bottom: 1px solid #e0e0e0;
	padding:5px;

    .btn-secondary {
      color: white;
      background-color: #cecece;
    }
  }
}

.switch-site-details-tabs {
  height: 50px;
  background-color: #efefef;
  border-bottom: 1px solid #d2d2d2;
  text-align: center;
  line-height: 43px;
  color: #4774b7;
  font-size: 16px;
  font-weight: bold;

  .switch-site-tab {
    height: 40px;
    margin-top: 10px;
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
    background-color: #e6e6e6;
    border: 1px solid gainsboro;
    cursor:pointer;
  }

  .switch-site-selected-tab {
    background-color: #d2d2d2;
    border: 1px solid #b3b3b3;
  }
}

.config-item-panel {
  width:100%;
}

.switch-site-details-container {
  input {
    height:30px;
	font-size: 14px;
	&.percent-width-60{
		width:60%;
	}
	&.display-inline{
		display:inline;
	}
  }

  input.slider-switch-config {
    height:20px;
    margin-top: 6px;
  }
}

.config-footer {
  background-color: #fbfbfb;
  height: 40px;
  border-top: 1px solid #f1f1f1;

  button {
    margin-top: 4px;
  }
}

.global-config-item {
  background-color: #f7d79f;
}

.config-loader-overlay {
  -webkit-transition: width 1s;
  transition: width 1s;
  position: absolute;
  z-index: 10;
  width: 97.4%;
  border-radius: 4px;
  /* padding: 10px; */
  /* margin-right: 86px; */
  text-align: center;
  /* top: 50%; */
  background-color: rgba(230, 230, 230, 0.6784313725490196);
  height: 85%;
  //line-height: 300px;

}

.config-loader-overlay-text {
  text-align: center;
  top: 45%;
  font-size: 28px;
  color: #616161;
  margin-top: 10%;
}

.config-loading-text {
  font-size:16px;
}

.edit-site-config-form-container {
  -webkit-transition: width 1s;
  transition: width 1s;
}

.switch-site-new-panel {
  -webkit-transition: width 1s;
  transition: width 1s;
}

.slider-switch-config {
  -webkit-appearance: none;  /* Override default CSS styles */
  appearance: none;
  width: 100%; /* Full-width */
  height: 20px;
  background: #d3d3d3; /* Grey background */
  outline: none; /* Remove outline */
  opacity: 0.7; /* Set transparency (for mouse-over effects on hover) */
  -webkit-transition: .2s; /* 0.2 seconds transition on hover */
  transition: opacity .2s;
}

.slider-switch-config::-webkit-slider-thumb {
  -webkit-appearance: none; /* Override default look */
  appearance: none;
  width: 25px; /* Set a specific slider handle width */
  height: 25px; /* Slider handle height */
  background: #4182af; /* Green background */
  cursor: pointer; /* Cursor on hover */
}

.slider-switch-config::-moz-range-thumb {
  width: 25px; /* Set a specific slider handle width */
  height: 25px; /* Slider handle height */
  background: #4182af; /* Green background */
  cursor: pointer; /* Cursor on hover */
}

#switch-site-details {
  input:checked + .slider {
    background-color: #4182af;
  }
}

.edit-campaign-trigger {
  cursor: pointer;
  float: right;
  padding: 10px;
//   display: inline-block;
  transition: all 0.5s ease;
}


.switch-site-image-holder:hover {
	cursor: pointer;
	opacity:0.8;
}
 
.verbose-logging-container{
	display: inline-block;
    width: 100%;
    padding: 10px;
    background-color: #efefef;
    border-top: 1px solid #d4d4d4;
}


.switch-site-item.selected{
	background-color: rgba(71, 116, 183, 0.17);
}

.slider-multi-range{
	height:20px;
	.ui-slider-range{
		background-color: #0e76bc;
	}
	.ui-slider-handle{
		height: 25px;
	}
}

.multi-range-options{
	i.key{
		&.color-from-index-0{
			color: rgb(58, 72, 92);
		}
		&.color-from-index-1{
			color: rgb(14, 118, 188);
		}
		&.color-from-index-2{
			color: rgb(135, 197, 214);
		}
	}
}

.linked-item{
	border-radius: 5px;
	overflow: hidden;
	line-height: 30px;
	margin-bottom: 5px;
	.linked-item-main-section{
		display: grid;
		grid-template-columns: 1fr 30px 30px 30px;
		grid-template-rows: 1fr;
		gap: 0px 0px 0px;
		grid-template-areas:
			". . . .";


		.linked-item-label{
			background-color: #e0e0e0;
			padding-left: 5px;
			text-overflow: ellipsis;
			overflow: hidden;
			white-space: nowrap;
		}
		.linked-item-grab-handle{
			text-align: center;
			background-color: #3a485c;
			cursor: grab;
			color:white;
			
			&:hover{
				color:#e0e0e0;
			}
		}
		.linked-item-edit-button{
			text-align: center;
			background-color: #4182af;
			color: white;
			cursor: pointer;

			&:hover{
				color:#e0e0e0;
			}
		}
		.linked-item-remove-button{
			text-align: center;
			background-color: darkred;
			color: white;
			cursor: pointer;

			&:hover{
				color:#e0e0e0;
			}
		}
	}
	.linked-item-edit-section{
		background-color: #4182af;
    	padding: 5px;

		.edit-section-header{
			text-align: center;
			color: white;
		}

		.edit-section-input-holder{
			display: grid;
			grid-template-columns: .5fr 1.5fr;
			grid-template-rows: 1fr;
			gap: 0px 0px;
			grid-template-areas:
				". .";

			label{
				text-align: center;
				color: white;
			}

		}
	}
}
.switch-site-config-multi-dynamic-container{
	.controls{
		margin-top: 10px;
		display: grid;
		grid-template-columns: 0.8fr 0.2fr;
		grid-template-rows: 1fr;
		gap: 0px;
		grid-template-areas:
			". .";
	}
}

.campaign-section-heading{
	text-align: center;
    font-weight: bold;
    font-size: 16px;
    padding: 10px;
}

.switch-site-config-search-bar{
	margin-top: 15px;
    margin-left: 30px;
    margin-right: 50px;
    margin-bottom: 0px;
    border-bottom: 1px solid #d0d0d0;
    padding-bottom: 10px;

	.col-md-3{
		padding-top:5px;
		margin-left: 0px;
	}
}

.config-update-item{
	border-bottom: 1px solid grey;

	.config-update-title{
		padding: 10px 50px;
		background-color: grey;
		color: white;
		cursor: pointer;

		.value{
			display: inline-block;
			font-weight: bold;
		}

		.date{
    		float: right;
		}
	}

	.config-update-body{
		background-color: lightgrey;
		margin-left: 0px;
		margin-right: 0px;
		text-align: center;

		.diff, .simple-diff, .user{
			padding: 20px;
			display: inline-block;

			.title{
				display: inline-block;
				font-weight: bold;
				border: none;
				padding: 0px;
			}

			.value{
				word-break: break-all;
			}
		}

	}

	
}