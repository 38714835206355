.thumbnail-holder {
	padding: 3px;
}

button.btn.btn-primary {
	&.add-asset-trigger, &.add-asset-thumb-trigger {
		padding: 0px;
		margin: 1px;
		font-size: 12px;
		width: 70%;
		height: 23px;
		margin: 1px;
	}
}

.asset-col-4 {
    padding: 15px;
}

#asset-editor {
	width: 100%;
}
.asset-col {
	width: 100%;
}

div .asset-jumbo {
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 10px;
    padding-bottom: 10px;
    border-radius: 13px;
    background-color: #eeeeee;
    margin-bottom: 2%;
}

.load-assets {
    max-height: 300px;
    overflow-y: scroll;
    margin: 2% 0;
    border: solid 1px #eee;
    border-top-left-radius: 15px;
    border-bottom-left-radius: 15px;
}

#assets-editor-container {
	text-align: center;
    display: inline-block;
}

div.asset-images-container {
	display: inline-block;
	margin: 15px 0;
    width: 115px;//150px;
}

img.asset-images {
	max-height: 100px;
	max-width: 100px;
	border: 1px solid #ddd;
    border-radius: 4px;
    padding: 5px;
}

.how-to-create {
	text-align: center;
}

.assets-fallback {
	margin-top: 2%;
    display: inline-block;
    width: 100%;    
}

input.asset-file-selector {
    width: 100%;
	padding: 10%;
	display: inline-block;
	border: solid 1px steelblue;
    margin-bottom: 2%;
    border-radius: 13px;
/*    display:none;*/
    
}

.upload-asset-trigger {
    border: 1px solid #ccc;
    display: inline-block;
    padding: 6px 12px;
    cursor: pointer;
}

.delete-asset-trigger {
	color: #b31a1a;
	font-size: 20px;
	margin-left: -10px;
	margin-top: -15px;
	vertical-align: top;
	display: inline-block;
}

.saved-asset-list-names {
    width: 100%;
    padding: 5px 10px;
}

.hidden-asset-editor {
    display: none;
    background: greenyellow;
    height: 50px;
    width: 100%;
    transition: 0.5s ease;
}

.asset-list-buttons {
    margin: 0px;
}

.asset-folder-btn {
    font-size: 15px;
    transition: 0.5s ease;
    margin: 0;
    width:100%;
}

#label-asset-img {
    width: 85%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 11px;
    color: grey;
}

div.note-editor.note-frame.panel.panel-default div.note-editing-area div.note-editable.panel-body p img#asset-img.asset-images,
div.note-editor.note-frame.panel.panel-default div.note-editing-area div.note-editable.panel-body blockquote img#asset-img.asset-images,
div.note-editor.note-frame.panel.panel-default div.note-editing-area div.note-editable.panel-body pre img#asset-img.asset-images,
div.note-editor.note-frame.panel.panel-default div.note-editing-area div.note-editable.panel-body h1 img#asset-img.asset-images,
div.note-editor.note-frame.panel.panel-default div.note-editing-area div.note-editable.panel-body h2 img#asset-img.asset-images,
div.note-editor.note-frame.panel.panel-default div.note-editing-area div.note-editable.panel-body h3 img#asset-img.asset-images,
div.note-editor.note-frame.panel.panel-default div.note-editing-area div.note-editable.panel-body h4 img#asset-img.asset-images,
div.note-editor.note-frame.panel.panel-default div.note-editing-area div.note-editable.panel-body h5 img#asset-img.asset-images,
div.note-editor.note-frame.panel.panel-default div.note-editing-area div.note-editable.panel-body h6 img#asset-img.asset-images
{
    max-height: unset;
	max-width: unset;
	border: unset;
    border-radius: unset;
    padding: unset;
}